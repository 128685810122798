import { Box, Chip, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { WishListitem } from "./WishListItem";
import NoDataFound from "../../../../components/NodataIndication/NoDataFound";

const WishList = ({
  availableCartItems,
  setAvailableCartItems,
  setTotalCourses,
  totalCourses,
}) => {
  const [wishListItems, setWishListItems] = useState([]);
  const [recentwishListItems, setRecentWishListItems] = useState([]);
  useEffect(() => {
    const filterData = availableCartItems.filter(
      (item) => item.wishList == true
    );

    setWishListItems(filterData);
  }, [availableCartItems]);
  useEffect(() => {
    const filterData = totalCourses.filter((item) => item.wishList == true);

    setRecentWishListItems(filterData);
  }, [totalCourses]);

  return (
    <Box pt={2}>
      <Paper elevation={3} sx={{ borderRadius: 3, padding: 1 }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Chip
            size="small"
            icon={
              <Icon icon="fluent:list-bar-20-regular" height={22} width={22} />
            }
            label={
              <Typography sx={{ fontSize: 11, fontWeight: "bolder" }}>
                Wishlist
              </Typography>
            }
            variant="outlined"
            color="primary"
          />
        </Box>

        <Box>
          {wishListItems.length != 0 || recentwishListItems.length != 0 ? (
            <>
              <WishListitem
                data={wishListItems}
                availableCartItems={availableCartItems}
                setAvailableCartItems={setAvailableCartItems}
              />
              <WishListitem
                recent={true}
                recentwishListItems={recentwishListItems}
                setTotalCourses={setTotalCourses}
                totalCourses={totalCourses}
              />
            </>
          ) : (
            <NoDataFound />
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default WishList;
