import React from 'react'
import { ApproveButton, RejectButton } from '../../../mui/Buttons/Buttons'

const TicketVerification = ({row}) => {
  return (
    <>
    {row.verification=='Not Verified' && (
        <ApproveButton>Verify Now</ApproveButton>
    )}
    {/* {row.verification=='Verified' && (
        <RejectButton>Unverify </RejectButton>
    )} */}
    </>
  )
}

export default TicketVerification