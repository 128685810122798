import React, { useEffect } from "react";
import useRoles from "../../Hooks/useRoles";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { DashboardRouter } from "../router";
import Page404 from "../../views/user/Auth/Page404";
import EmptyDashboard from "../../views/user/Dashboard/EmptyDashboard";

const DashboardRoute = ({ children }) => {
    const { navChild, permissions } = useRoles("dashboard");
    // return children;
 
    if (navChild.length > 0) {
        return children;
    } else {
        return <EmptyDashboard />;
    }
};

export default DashboardRoute;
