import React, { useEffect, useState } from "react";
import GlobalWrapper from "../../../components/Wrapper/GlobalWrapper";
import { Box, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CustomGridToolbar from "../../../components/datagrid/GridToolBar";

import { useDispatch, useSelector } from "react-redux";
import {
  getAllGrades,
  getAllObjectivePreData,
  getLevelPreData,
} from "../../../redux/features/assessmentToolSlice";
import {
  GRADES_TABLE_HEAD,
  getFilteredTableHead,
} from "../../../components/datagrid/myworkspace/speakpro/grade/GradeTableHead";
import CreateUpdateGrade from "../../../components/datagrid/myworkspace/speakpro/grade/CreateUpdateGrade";

const Grade = () => {
  const [gridSize, setGridSize] = useState(10);
  const dispatch = useDispatch();
  const { gradeData, loading } = useSelector((state) => state.assessmentTool);
  const profile = JSON.parse(localStorage.getItem("cred")).profile;

  useEffect(() => {
    dispatch(getAllGrades(profile?.departmentId));
    dispatch(getLevelPreData());
    dispatch(getAllObjectivePreData());
  }, []);
  const filteredTableHead = getFilteredTableHead(gradeData);
  return (
    <GlobalWrapper title={"Grades"} actions={<CreateUpdateGrade />}>
      <Paper elevation={3}>
        <Box sx={{ width: "100%", padding: 0 }}>
          <DataGrid
            disableVirtualization
            components={{ Toolbar: CustomGridToolbar }}
            loading={loading}
            autoHeight
            sx={{
              "& .MuiDataGrid-columnHeaderTitle": {
                textOverflow: "clip",
                whiteSpace: "break-spaces",
                lineHeight: 1,
              },
            }}
            // getRowHeight={() => "auto"}
            rows={gradeData}
            columns={filteredTableHead}
            getRowId={(row) => row.serialNumber}
            pageSize={gridSize}
            rowsPerPageOptions={[5, 10, 25, 50]}
            onPageSizeChange={(newGridSize) => setGridSize(newGridSize)}
          />
        </Box>
      </Paper>
    </GlobalWrapper>
  );
};

export default Grade;
