import React, { useEffect, useState } from 'react'
import GlobalWrapper from '../../../../components/Wrapper/GlobalWrapper'
import { Box, Paper } from '@mui/material'
import GlobalDatagrid from '../../../../components/mui/DataGrid/GlobalDatagrid'
import { ticketTableHeader } from '../../../../components/datagrid/studentPortal/ticketTableHeader'
import { useSelector } from 'react-redux'
import { getAllGrievancesTickets } from '../../../../redux/features/studentPortalSlice'
import dayjs from 'dayjs'
import { useDispatch } from 'react-redux'
import DatePickerCustom from '../../../../components/DatePicker/DatePickerCustom'
import GlobalMultiSelect from '../../../../components/mui/GlobalMultiSelect'
import { useSearchParams } from 'react-router-dom'
import ActionDetails from '../../../../components/mui/GlobalAnchor/ActionDetails'
import GlobalAnchorList from '../../../../components/mui/GlobalAnchor/GlobalAnchorList'
import TicketAction from '../../../../components/datagrid/studentPortal/Admin/TicketAction'
import GrievanceJourney from '../../GrievanceJourney'
import TicketVerification from '../../../../components/datagrid/studentPortal/Admin/TicketVerification'

const DATA = [
  {
    sl_no: 1,
    studentName: 'Mizafa',
    departmentId: 5,
    departmentName:'Technical Department',
    assignedToId: 1012,
    assignedToName: "Subhin",
    teamHeadName: 'Shahida',
    status: 'Assigned',
    reason: 'very bad',
    teamHeadRemarks: 'please fast',
    assigneeRemarks: '',
    verification:"Not Verified"
  },
  {
    sl_no: 2,
    studentName: 'Rahul',
    departmentId: 5,
    departmentName:'Technical Department',
    assignedToId: "",
    assignedToName: "",
    teamHeadName: '',
    status: 'Pending',
    reason: 'Not Accurate',
    teamHeadRemarks: '',
    assigneeRemarks: '',
    verification:"Verified"
  }
]

let statusJson = [
  { id: 1, value: 'Pending' },
  { id: 2, value: 'Assigned' },
  { id: 3, value: 'In Progress' },
  { id: 4, value: 'Resolved' },
  { id: 5, value: 'Rejected' },
]
const Tickets = ({ dashboard }) => {
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const { paginationData } = useSelector((state) => state.globalDataGrid)
  const { gridSize, pageNum, rowData, openAnchor, search } = paginationData
  const { resData, loading } = useSelector((store) => store.studentPortal)
  const { data, meta } = resData
  const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'))
  const [status, setStatus] = useState(searchParams.get('status') ? [searchParams.get('status')] : [])
  const dispatchParams = {
    selectedDate,
    paginationData,
    status,
  }

  useEffect(() => {
    dispatch(getAllGrievancesTickets(selectedDate))
  }, [gridSize, pageNum, search, selectedDate, status])

  const Content = () => {
    return (
      <>
        {!dashboard && (
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Box sx={{ flex: 1 }}>
              <DatePickerCustom
                value={dayjs(selectedDate)}
                setValue={(date) => setSelectedDate(date.format('YYYY-MM-DD'))}
                viewDetails={['year', 'month', 'day']}
                error={false}
                fullWidth
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <GlobalMultiSelect
                label={'Status Filter'}
                fullWidth
                arrayState={status}
                arraySetState={setStatus}
                data={statusJson}
                keys={{ id: 'id', value: 'value' }}
              />
            </Box>
          </Box>
        )}
        <Box sx={{ mt: 1 }}>
          <Paper elevation={dashboard ? 6 : 1}>
            <GlobalDatagrid
              tableHead={ticketTableHeader}
              rows={DATA || []}
              meta={meta?.totalRecords}
              rowUniqueId="sl_no"
              loading={loading}
              rowClick={true}
              actionComponent={
                <>
                  <ActionDetails val={rowData?.studentName} />
                  <GlobalAnchorList
                    label="Action"
                    component={
                      <TicketAction paginationData={paginationData} row={rowData} dispatchParams={dispatchParams} />
                    }
                  />
                  <GlobalAnchorList label="Journey" component={<GrievanceJourney grievanceId={2} />} />
                  {rowData?.verification=="Not Verified" && (<GlobalAnchorList label="Status" component={<TicketVerification row={rowData} />} />)}
                </>
              }
            />
          </Paper>
        </Box>
      </>
    )
  }
  if (dashboard) {
    return Content()
  } else {
    return <GlobalWrapper title={'Admin Tickets'}>{Content()}</GlobalWrapper>
  }
}

export default Tickets
