import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select } from '@mui/material'
import React from 'react'

const GlobalMultiSelect = ({label,sx,arrayState,arraySetState,data,keys,disabled,fullWidth}) => {

    const handleChange=(event)=>{
      const value = event.target.value;
      if(value.includes("all")){
        if(arrayState?.length==data?.length){
          arraySetState([])
        }else{
          arraySetState(data?.map((obj) => obj[keys?.value])); 
        }
      }else{
        arraySetState(value);
      }
       
    }
    const isAllSelected = arrayState?.length === data?.length;
  return (
    <FormControl fullWidth={fullWidth}  sx={!fullWidth ? { minWidth: '220px', maxWidth: '220px', ...sx } : null}>
    <InputLabel>{label || "Label Name"}</InputLabel>
    <Select
      disabled={disabled}
      label={label||"Label Name"}
      multiple
      value={arrayState || []}
      onChange={handleChange}
      renderValue={(selected) => selected.join(', ')}
      >
        <MenuItem value="all">
          <Checkbox checked={isAllSelected} />
          <ListItemText primary="Select All" />
        </MenuItem>
      {data?.map((obj) => (
        <MenuItem key={obj[keys?.id]} value={obj[keys?.value]} >
          <Checkbox checked={arrayState?.indexOf(obj[keys?.value]) > -1} />
          <ListItemText primary={obj[keys?.value]} />
        </MenuItem>
      ))}
    </Select>
  </FormControl>
  )
}

export default GlobalMultiSelect