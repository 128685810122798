import { Box, Chip, Typography } from '@mui/material';
import React from 'react';

const counts = {
  A: 0,
  WO: 2,
  CO: 1,
  HF: 1,
  HD: 2,
  'L-CL': 1,
  'L-SL': 2,
};

const LeaveTypeComponents = () => {
  return (
    <Box sx={{ padding: 2 }}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: 'repeat(3, 1fr)', sm: 'repeat(5, 1fr)',md:'repeat(8, 1fr)' },
          gap: 2,
        }}
      >
        {Object.entries(counts).map(([key, value]) => (
          <Box
            key={key}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 1,
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 1,
              backgroundColor: '#c4dcff' ,
            }}
          >
            <Chip
            //   sx={{ height: 32, fontSize: 14 }}
              variant="outlined"
              color={'info'}
              label={`${key} : ${value}`}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default LeaveTypeComponents;
