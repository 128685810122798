import ToolTip from "../../../../ToolTip/ToolTip";


export const PAYMENT_INVOICES_TABLE_HEAD = [
    {
        field: "sl_no",
        headerName: "Sl No",
        width: 80,
    },

    {
        field: "student_name",
        headerName: "Student Name",
        width: 250,
    },
    {
        field: "invoice_admission_fee",
        headerName: "Admission Fee",
        width: 150,
        renderCell:(params)=>params.row.invoice_admission_fee==0 ?"Not Included":params.row.invoice_admission_fee==1? "Included":""
    },
    {
        field: "invoice_discount",
        headerName: "Discount %",
        width: 100,
         renderCell:(params)=>`${params.row.invoice_discount}%`
    },
    {
        field: "invoice_advance_fee",
        headerName: "Advance (INR)",
        width: 100,
    },
    {
        field: "invoice_advance_fee_foreign_currency",
        headerName: "Advance (AED)",
        width: 100,
        renderCell:(params)=> params.row.invoice_advance_fee_foreign_currency==null || params.row.invoice_advance_fee_foreign_currency==0 ?"0":params.row.invoice_advance_fee_foreign_currency
    },
    {
        field: "invoice_timestamp",
        headerName: "Invoice TimeStamp",
        width: 180,
    },
    {
        field: "added_by",
        headerName: "Added By",
        width: 180,
    },
    {
        field: "cro_name",
        headerName: "Cro",
        width: 180,
    },
    

    {
        field: "invoice_remarks",
        headerName: "Remarks",
        width: 150,
        renderCell: (params) => {
            return (
              <ToolTip
                expand={params.row.invoice_remarks}
                content={params.row.invoice_remarks}
              />
            );
          },
    },
    {
        field: "invoice_accountant_verification",
        headerName: "Verification Status",
        width: 150,
          renderCell:(params)=>params.row.invoice_accountant_verification==0 ?"Not Verified": "Verified"
    },
    {
        field: "invoice_accountant_verified_on",
        headerName: "Verified On",
        width: 180,
    }
 
];
