import React, { useEffect, useState } from 'react'
import GlobalWrapper from '../../components/Wrapper/GlobalWrapper'
import { Paper, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { DataGrid } from '@mui/x-data-grid'
import CustomGridToolbar from '../../components/datagrid/GridToolBar'
import { evenigFoodApprovalTableHeader } from '../../components/datagrid/evenigFoodApproval/evenigFoodApprovalTableHeader'
import { useDispatch, useSelector } from 'react-redux'
import { allEveningFoodRequests } from '../../redux/features/foodSlice'
import { BulkApprove } from '../../components/mui/Buttons/Buttons'
import EveningFoodBulkApprove from './EveningFoodBulkApprove'
import DatePickerCustom from '../../components/DatePicker/DatePickerCustom'
import dayjs from 'dayjs'
import useResponsive from '../../Hooks/useResponsive'

const EveningFoodListApproval = () => {
  const { loading, evenigFoodRequestsForApproval } = useSelector((store) => store.food)
  const dispatch = useDispatch()
  const [checkedRowsIds, setCheckedRowsIds] = useState([])
  const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'))
  const smDown = useResponsive("down", "sm");
  useEffect(() => {
    dispatch(allEveningFoodRequests(selectedDate))
  }, [selectedDate])
  const handleSelectionChange = (datas) => {
    setCheckedRowsIds(datas)
  }

  return (
    <GlobalWrapper
      title={'Evening Food Approval'}
      actions={
        <>
          {checkedRowsIds.length > 1 && (
            <EveningFoodBulkApprove checkedRowsIds={checkedRowsIds} upDateCall={allEveningFoodRequests(selectedDate)} />
          )}
        </>
      }>
      <Box sx={{ mb: 1, display:smDown? 'grid':'flex', alignItems: 'center', gap:smDown?1:5 }}>
        <Box>
          <DatePickerCustom
            value={dayjs(selectedDate)}
            setValue={(date) => setSelectedDate(date.format('YYYY-MM-DD'))}
            viewDetails={['year', 'month', 'day']}
            error={false}
          />
        </Box>
        <Box>
          <marquee
            direction="right"
            style={{ fontSize: '0.5 rem', color: '#212b36', fontWeight: 'bold', letterSpacing: '1px' }}>
            <span style={{ textShadow: '1px 1px 3px rgba(0,0,0,0.3)' }}>Evening Food Request Time : 10 AM TO 2 PM</span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span style={{ textShadow: '1px 1px 3px rgba(0,0,0,0.3)' }}>Evening Food Approval Time : 3 PM TO 4 PM</span>
          </marquee>
        </Box>
      </Box>

      <Paper>
        <Box sx={{ width: '100%' }}>
          <DataGrid
            loading={loading}
            components={{ Toolbar: CustomGridToolbar }}
            rows={evenigFoodRequestsForApproval}
            columns={evenigFoodApprovalTableHeader}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            checkboxSelection
            onSelectionModelChange={handleSelectionChange}
            disableRowSelectionOnClick
            getRowId={(row) => row.food_id}
            // getRowHeight={()=>"auto"}
            autoHeight
          />
        </Box>
      </Paper>
    </GlobalWrapper>
  )
}

export default EveningFoodListApproval
