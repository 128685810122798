import React, { useEffect, useState } from "react";
import { Box, Paper, Typography, Grid, Tooltip } from "@mui/material";
import Chip from "@mui/material/Chip";
import useResponsive from "../../../../Hooks/useResponsive";

const MarkedAttendance = ({ data, monthAndYear }) => {
  const [daysInMonth, setDaysInMonth] = useState([]);
  const smDown = useResponsive("down", "sm");
  const [currentDay, setCurrentDay] = useState(null); //FOR STORING CURRENT DAY

  useEffect(() => {
    const year = monthAndYear.$y;
    const month = monthAndYear.$M + 1; //MONTH IS STARTING FROM INDEX 0 SO INCREMENTED 1
    const lastDay = new Date(year, month, 0).getDate(); //IT WILL RETURN THE TOTOAL NO OF DAYS OF THE DESIRED MONTH

    const daysArray = Array.from({ length: lastDay }, (_, i) => {
      //IT WILL RETURN AN OBJECT OF ARRAY WHICH CONTAIN EACH DAY NUMBER WIHT DAY NAME
      const date = new Date(year, month - 1, i + 1); // INCREASED MONTH VALUE SHOULD MINUS FROM HERE
      const dayNumber = i + 1;
      const dayName = date.toLocaleDateString("en-US", { weekday: "long" });
      return { dayNumber, dayName };
    });
    setDaysInMonth(daysArray);

    const today = new Date(); //TO MAKE A BORDER FOR FOR CURRENT DATE MAKE A STATE FOR STORE THE CURRENT DAY SO IT WILL CHECK THE MONTH AND YEAR IS ALSO SAME AS NOW
    if (today.getFullYear() === year && today.getMonth() + 1 === month) {
      setCurrentDay(today.getDate());
    } else {
      setCurrentDay(null);
    }
  }, [monthAndYear]);

  const getStatusAndColor = (dayNumber) => {
    //RECIVEING CURRENT DAY NUMBER AND IT WILL CHECK IN THE ATTENDNCE ARRAY AND IT WILL RETURN THE COLOR AND STATUS OF THAT DAY TO DISPLAY
    const attendanceDetails = data?.find(
      (details) => details.date === dayNumber
    );
    if (attendanceDetails) {
      let color;
      let backgroundcolor;
      let shortStatus = attendanceDetails.status;
      switch (attendanceDetails.status) {
        case "P":
        case "WFH":
          color = "#0db05d";
          backgroundcolor = "#c8facd";
          break;
        case "A":
          color = "#c03530";
          backgroundcolor = "#ffe4de";
          break;
        case "L-DL":
        case "L-DL(H)":
          color = "#9A7E6F";
          backgroundcolor = "#E9EED9";
          break;
        case "WO":
        case "HD":
          color = "#3173b4";
          backgroundcolor = "#bbd1e4cf";
          break;
        case "CO":
          color = "#3173b4";
          backgroundcolor = "#bbd1e4cf";
          break;
        case "L-CL":
        case "L-SL(H)":
        case "L-SL":
          color = "#7e4d8b";
          backgroundcolor = "#dccfe0";
          break;
        default:
          color = "#bbbd2d";
          backgroundcolor = "#faf7c8";
          shortStatus = "NM";
      }
      return {
        color,
        backgroundcolor,
        status: attendanceDetails.statusFull,
        shortStatus: shortStatus,
      };
    } else {
      return {
        color: "#bbbd2d",
        backgroundcolor: "#faf7c8",
        status: "Not Marked",
        shortStatus: "NM",
      };
    }
  };

  return (
    <Paper elevation={6}>
      <Box sx={{ p: smDown ? 1 : 2 }}>
        <Grid container spacing={1}>
          {daysInMonth.map((day) => {
            const { color, status, backgroundcolor, shortStatus } =
              getStatusAndColor(day.dayNumber);
            const isToday = day.dayNumber === currentDay; //FOR CURRENT DAY TRUE THEN GETS APPLY BORDER
            return (
              <Grid
                item
                xs={3}
                sm={3}
                md={3}
                lg={2}
                xl={1.5}
                key={day.dayNumber}
              >
                <Paper
                  square
                  elevation={6}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: isToday ? "1px solid #a69e9e" : "none",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      backgroundColor: backgroundcolor,
                      alignItems: "center",
                      justifyContent: "center",
                      width: "90%",
                      padding: 1,
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    <Typography
                      sx={{ color: color, fontSize: smDown ? 12 : 18 }}
                    >
                      {day.dayName}
                    </Typography>
                    <Typography sx={{ my: "3px" }} variant="h6">
                      {day.dayNumber}
                    </Typography>
                    <Tooltip title={status} arrow>
                      <Chip
                        label={smDown ? shortStatus : status}
                        variant="outlined"
                        sx={{
                          fontSize: 13,
                        }}
                      />
                    </Tooltip>
                  </Box>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Paper>
  );
};

export default MarkedAttendance;
