import { Box, Grid } from '@mui/material'
import React, { useEffect } from 'react'
import DashboardCountCardView from '../../components/Dashboard/HrComponents/DashboardCountCardView'
import Tickets from './Tickets/Admin/Tickets'
import GlobalWrapper from '../../components/Wrapper/GlobalWrapper'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getAllGrievancesDashboardCounts } from '../../redux/features/studentPortalSlice'

const StudentPortalDashboard = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch('')
  useEffect(() => {
    dispatch(getAllGrievancesDashboardCounts())
  }, [])
  return (
    <GlobalWrapper>
      <Box>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={3}>
            <DashboardCountCardView
              title="Total Tickets"
              total={'50'}
              icon={'bi:stack'}
              color="total"
              clickFunction={(title) => navigate('/studentPortal/tickets')}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <DashboardCountCardView
              title="Pending Tickets"
              total={'10'}
              color="pending"
              icon={'material-symbols:pending-outline'}
              clickFunction={(title) => navigate('/studentPortal/tickets?status=Pending')}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <DashboardCountCardView
              title="In Progress"
              total={'10'}
              color="progress"
              icon={'lets-icons:progress'}
              clickFunction={(title) => navigate('/studentPortal/tickets?status=In Progress')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DashboardCountCardView
              title="Resolved"
              total={'30'}
              color="completed"
              icon={'fluent-mdl2:completed-solid'}
              clickFunction={(title) => navigate('/studentPortal/tickets?status=Resolved')}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Tickets isAdmin={true} dashboard={true} />
      </Box>
    </GlobalWrapper>
  )
}

export default StudentPortalDashboard
