import React, { useEffect, useState } from 'react'
import GlobalDialog from '../../../mui/Dialogue/GlobalDialog'
import { UpdateButton } from '../../../mui/Buttons/Buttons'
import { Autocomplete, Box, Stack, TextField } from '@mui/material'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getAllDepartments, getEmployeesUnderTheDepartment } from '../../../../redux/features/studentPortalSlice'
import { useFormik, FormikProvider, Form } from 'formik'
import * as Yup from 'yup'
import { useSnackbar } from 'notistack'
import GlobalSelectField from '../../../mui/GlobalSelectField'
import { updatePostWorking } from '../../../../redux/features/postWorkingHoursSlice'
import { savePaginationData } from '../../../../redux/features/globalDatagridSlice'
const TicketAction = ({ row, dispatchParams }) => {
  const userProfile = JSON.parse(localStorage.getItem("cred")).profile;
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { loading, departments, employees } = useSelector((store) => store.studentPortal)
  const { paginationData } = useSelector((state) => state.globalDataGrid)
  const [open, setOpen] = useState(false)


  const initialValues = {
    teamHeadRemarks: row ? row.teamHeadRemarks : '',
    departmentId: row ? row?.departmentId : '',
    assignedToId: row ? row?.assignedToId : '',
  }
  const schema = Yup.object({
    departmentId: Yup.number().required('Department Required'),
    // assignedTo: Yup.number().required('User Required'),
  })

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values) => {
      if(row?.assignedToId && row?.departmentId!=values.departmentId ){
      values.assignedToId=null
      }
      console.log(values)
      
      // dispatch(updatePostWorking(updatedData)).then((res) => {
      //   if (res.payload.status === 'error') {
      //     enqueueSnackbar(res.payload.message, {
      //       variant: 'error',
      //     })
      //   } else if (res.payload.status === 'success') {
      //     handleCancel()
      //     dispatch(savePaginationData({ ...paginationData, openAnchor: false }))
      //     if (isAdmin) {
      //       //ADMIN GET PAGE WITH DISPATCH PARAMS
      //     } else {
      //       //ASSIGNEE GET PAGE WITH PARAMS
      //     }

      //     enqueueSnackbar(res.payload.message, {
      //       variant: 'success',
      //     })
      //   }
      // })
    },
  })

  useEffect(() => {
    if (open && departments.length==0) {
      dispatch(getAllDepartments())
    }
  }, [open,departments,formik.values.departmentId])

  useEffect(() => {
    if ( open && formik.values.departmentId) {
      dispatch(getEmployeesUnderTheDepartment(formik.values.departmentId))
    }
  }, [open,formik.values.departmentId])

  const handleCancel = () => {
    setOpen(false)
    formik.resetForm()
  }
  const handleDepartmentChange = (e, option) => {
    formik.setFieldValue('departmentId', option?.departmentId)
  }
  const handleUserChange = (e, option) => {
    formik.setFieldValue('userId', option?.userId)
  }
  return (
    <GlobalDialog
      maxWidth={'sm'}
      fullWidth
      title={'Action'}
      open={open}
      setOpen={setOpen}
      closeButton={true}
      closeCondition={handleCancel}
      popupButton={
        <>
          <UpdateButton
            //   disabled={row.status !== 'Pending'}
            size="small"
            action={() => setOpen(true)}>
            Assign
          </UpdateButton>
        </>
      }
      content={
        <>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <Stack gap={2}>
                <Box>
                  <Box sx={{ flex: 1 }}>
                    <Autocomplete
                      name="departments"
                      options={departments}
                      getOptionLabel={(option) => option.departmentName || ''}
                      value={departments?.find((dept) => dept?.departmentId == formik.values.departmentId) || null}
                      onChange={handleDepartmentChange}
                      renderInput={(params) => (
                        <TextField
                          disabled
                          fullWidth
                          autoComplete="off"
                          {...params}
                          label="Departments"
                          error={formik.touched.departmentId && Boolean(formik.errors.departmentId)}
                          helperText={formik.touched.departmentId && formik.errors.departmentId}
                        />
                      )}
                    />
                  </Box>
                </Box>

                <Box>
                  <Box sx={{ flex: 1 }}>
                    <Autocomplete
                      disabled={formik.values.departmentId!=userProfile.departmentId}
                      name="userId"
                      options={employees}
                      getOptionLabel={(option) => option.userName || ''}
                      value={employees?.find((dept) => dept?.userId == formik.values.assignedToId) || null}
                      onChange={handleUserChange}
                      renderInput={(params) => (
                        <TextField
                          disabled
                          fullWidth
                          autoComplete="off"
                          {...params}
                          label="Assigned To"
                          error={formik.touched.assignedToId && Boolean(formik.errors.assignedToId)}
                          helperText={formik.touched.assignedToId && formik.errors.assignedToId}
                        />
                      )}
                    />
                  </Box>
                </Box>

                <Box>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="TeamHeadRemarks"
                    label="Department Head Remarks"
                    name="TeamHeadRemarks"
                    value={formik.values.teamHeadRemarks}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.teamHeadRemarks && Boolean(formik.errors.teamHeadRemarks)}
                    helperText={formik.touched.teamHeadRemarks && formik.errors.teamHeadRemarks}
                  />
                </Box>
                <Box>
                  <UpdateButton disabled={loading} type="submit">
                    {loading ? 'Assigning...' : 'Assign'}
                  </UpdateButton>
                </Box>
              </Stack>
            </Form>
          </FormikProvider>
        </>
      }
    />
  )
}

export default TicketAction
