import React, { useEffect, useState } from 'react'
import GlobalDialog from '../../../../components/mui/Dialogue/GlobalDialog'
import { Box, Button, Typography } from '@mui/material'
import { getScreenshot } from '../../../../redux/features/invoiceSlice'
import { useDispatch, useSelector } from 'react-redux'
import SimpleLoader from '../../../../components/Loader/SimpleLoader'

const ViewScreenShot = ({ pageName, api }) => {
  const [open, setOpen] = useState(false)
  const { loading, screenshotSrc } = useSelector((store) => store[pageName])
  const dispatch = useDispatch()

  useEffect(() => {
    if (open) {
      dispatch(api)
    }
  }, [open])

  const parseMimeType = (dataUrl) => {
    if (!dataUrl.startsWith('data:')) {
      return null
    }
    const mimeType = dataUrl.split(';')[0].split(':')[1] // Extract the MIME type
    return mimeType
  }

  const mimeType = screenshotSrc ? parseMimeType(screenshotSrc) : null

  return (
    <GlobalDialog
      maxWidth={'sm'}
      fullWidth={true}
      title={'Screenshot'}
      open={open}
      setOpen={setOpen}
      closeButton={true}
      popupButton={
        <>
          <Button
            size="small"
            variant="outlined"
            sx={{ backgroundColor: '#eff4f9', color: '#3173b4' }}
            onClick={() => setOpen(true)}>
            Screenshot
          </Button>
        </>
      }
      content={
        <>
          {loading ? (
            <SimpleLoader />
          ) : !screenshotSrc ? (
            <Typography>No data available</Typography>
          ) : mimeType === 'application/pdf' ? (
            <iframe src={screenshotSrc} title="PDF Document" width="100%" height="100%" ></iframe> //IF WANT TO DOWNLOAD PUT HEIGHT:500PX
          ) : (
            <img src={screenshotSrc} alt="" />
          )}
        </>
      }
    />
  )
}

export default ViewScreenShot
