import {
  Box,
  Stack,
  Button,
  TextField,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MotionContainer from "../../../../components/FramerMotion/MotionContainer";
import { AnimatePresence, motion } from "framer-motion";
import useResponsive from "../../../../Hooks/useResponsive";
import {
  AddButton,
  DeleteButton,
  SubmitButton,
  UpdateButton,
} from "../../../../components/mui/Buttons/Buttons";
import GlobalDialog from "../../../../components/mui/Dialogue/GlobalDialog";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { deleteSubject } from "../../../../redux/features/cartSlice";
import { el } from "date-fns/locale";

const CartItemSubjects = ({
  setAvailableCartItems,
  item,
  update,
  setTotalCourses,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [preferences, setPreferences] = React.useState([
    { subjectId: "", amountInr: "", amountAed: "" },
  ]);

  const [currencyType, setCurrencyType] = React.useState(1);

  const handleChange = (event) => {
    setCurrencyType(event.target.value);
  };
  useEffect(() => {
    if (item) {
      setCurrencyType(parseInt(item.currencyType));

      setPreferences(
        item?.subjects && item?.subjects?.length > 0
          ? JSON.parse(JSON.stringify(item.subjects))
          : [{ subjectId: "", amountInr: "", amountAed: "" }]
      );
    }
  }, [item, open]);
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");

  const handleDayChange = (index, event) => {
    const newPreferences = [...preferences];
    newPreferences[index].subjectId = event.target.value;
    setPreferences(newPreferences);
  };

  const handleTimeChange = (index, field, newValue) => {
    const newPreferences = [...preferences];
    newPreferences[index][field] = newValue;
    setPreferences(newPreferences);
  };

  const handleAddPreference = () => {
    setPreferences((prevPreferences) => [
      ...prevPreferences,
      { subjectId: "", amountInr: "", amountAed: "" },
    ]);
  };

  const handleRemovePreference = (index) => {
    if (update) {
      const data = preferences[index];
      const dispatchData = { subjectId: data.subjectId, cartId: item.cartId };
      dispatch(deleteSubject(dispatchData)).then((res) => {
        if (res.payload.status === "failed" || res.payload.status === "error") {
          enqueueSnackbar(res.payload.message, { variant: "error" });
        } else if (res.payload.status === "success") {
          enqueueSnackbar(res.payload.message, {
            variant: "success",
          });
          const newPreferences = preferences.filter((_, i) => i !== index);
          setPreferences(newPreferences);
        }
      });
    } else {
      const newPreferences = preferences.filter((_, i) => i !== index);
      setPreferences(newPreferences);
    }
  };
  const { subjects } = useSelector((state) => state.cro);

  const handleSubmit = (e) => {
    e.preventDefault();
    const validation = preferences.some((item) => {
      if (currencyType && currencyType == 1) {
        return item.amountInr == "" || item.subjectId == "";
      } else {
        return (
          item.amountAed == "" || item.amountInr == "" || item.subjectId == ""
        );
      }
    });
    if (!validation) {
      if (update) {
        setAvailableCartItems((prevArray) =>
          prevArray.map((cartItem) =>
            cartItem.id === item.id
              ? {
                  ...cartItem,
                  subjects: preferences,
                  currencyType: currencyType,
                }
              : cartItem
          )
        );
        setOpen(false);
      } else {
        setTotalCourses((prevArray) =>
          prevArray.map((cartItem) =>
            cartItem.id === item.id
              ? {
                  ...cartItem,
                  subjects: preferences,
                  currencyType: currencyType,
                }
              : cartItem
          )
        );
        setOpen(false);
      }
    } else {
      enqueueSnackbar("Some fields are empty...!", {
        variant: "error",
      });
    }
  };

  return (
    <Box>
      <GlobalDialog
        title={"Add Subjects"}
        open={open}
        setOpen={setOpen}
        fullWidth
        maxWidth={"sm"}
        closeButton={true}
        popupButton={
          <>
            {update ? (
              <UpdateButton size={"small"} action={() => setOpen(true)}>
                Update subject
              </UpdateButton>
            ) : (
              <AddButton size={"small"} action={() => setOpen(true)}>
                Add Subject
              </AddButton>
            )}{" "}
          </>
        }
        content={
          <>
            <form>
              <Stack direction={"column"} spacing={2}>
                {/* <AnimatePresence> */}
                <motion.div
                  initial={{ opacity: 0, x: -100 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 100 }}
                  transition={{ duration: 0.5 }}
                >
                  <Box>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Currency Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        required
                        value={currencyType}
                        label="Currency Type"
                        onChange={handleChange}
                      >
                        <MenuItem value={1}>INR</MenuItem>
                        <MenuItem value={2}>AED</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </motion.div>
                {currencyType ? (
                  <Box>
                    {preferences?.map((pref, index) => (
                      <motion.div
                        key={index}
                        initial={{ opacity: 0, x: -100 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: 100 }}
                        transition={{ duration: 0.5 }}
                      >
                        <Stack
                          direction={mdUp ? "row" : "column"}
                          spacing={1}
                          alignItems="center"
                          sx={{ pb: 2 }}
                        >
                          <MotionContainer delay={1}>
                            <Box sx={{ width: "100%" }}>
                              <Box sx={{ width: "100%" }}>
                                <Autocomplete
                                  disablePortal
                                  name={`subject-${index}`}
                                  id={`autocomplete-subject-${index}`}
                                  options={[
                                    { subjectId: -1, subjectName: "NA" },
                                    ...subjects,
                                  ]}
                                  getOptionLabel={(option) =>
                                    option.subjectName || ""
                                  }
                                  value={
                                    subjects?.find(
                                      (item) =>
                                        item.subjectId === pref.subjectId
                                    ) || null
                                  }
                                  onChange={(e, option) => {
                                    const newPreferences = [...preferences];
                                    newPreferences[index].subjectId =
                                      option?.subjectId || "";
                                    setPreferences(newPreferences);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      required
                                      fullWidth
                                      label="Subject"
                                    />
                                  )}
                                />
                              </Box>
                            </Box>
                          </MotionContainer>
                          <MotionContainer delay={0.7}>
                            <Box sx={{ width: "100%" }}>
                              <TextField
                                fullWidth
                                required
                                type="number"
                                onWheel={(event) => event.target.blur()}
                                label="Amount INR"
                                value={pref.amountInr}
                                onChange={(e) =>
                                  handleTimeChange(
                                    index,
                                    "amountInr",
                                    e.target.value
                                  )
                                }
                              />
                            </Box>
                          </MotionContainer>
                          {currencyType == 1 ? null : (
                            <MotionContainer delay={0.5}>
                              <Box sx={{ width: "100%" }}>
                                <TextField
                                  fullWidth
                                  required
                                  type="number"
                                  onWheel={(event) => event.target.blur()}
                                  label="Amount AED"
                                  value={pref.amountAed}
                                  onChange={(e) =>
                                    handleTimeChange(
                                      index,
                                      "amountAed",
                                      e.target.value
                                    )
                                  }
                                />
                              </Box>
                            </MotionContainer>
                          )}

                          <DeleteButton
                            size="small"
                            action={() => handleRemovePreference(index)}
                          />
                        </Stack>
                      </motion.div>
                    ))}

                    {/* </AnimatePresence> */}
                    <Box>
                      <AddButton size="small" action={handleAddPreference}>
                        Add subject
                      </AddButton>
                    </Box>
                  </Box>
                ) : null}
              </Stack>
              {currencyType ? (
                <Box sx={{ display: "flex", justifyContent: "center", pt: 2 }}>
                  <SubmitButton type={"submit"} action={handleSubmit}>
                    Add Subject
                  </SubmitButton>
                </Box>
              ) : null}
            </form>
          </>
        }
      />
    </Box>
  );
};

export default CartItemSubjects;
