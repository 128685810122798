import { Box, Grid, IconButton, InputAdornment, Paper, Stack, TextField, Typography } from '@mui/material'
import { GridSearchIcon } from '@mui/x-data-grid'
import React, { useEffect, useMemo, useState } from 'react'
import DepartmentCard from './DepartmentCard'
import DepartmentPopup from './DepartmentPopup'
import DepartmentWingEmployeesModal from './DepartmentWingEmployeesModal'
import { departmentDisplayDetails } from '../../../redux/features/hrDashboard'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import Search from '../../../components/Search/Search'

const DepartmentViewComponents = () => {
  const dispatch = useDispatch()
  const [searchValue, setSearchValue] = useState('')
  const { loading, departmentDisplay, departmentsAndInner } = useSelector((store) => store.hrDashboard)

  useEffect(() => {
    dispatch(departmentDisplayDetails()) //DISPLAY  DEPARTEMNETS AND AND WINGS COUNTS AND HEIRARCHY
  }, [])

  const filteredDepartment = useMemo(() => {
    //IF SEARCH ATTRIBUTE USE THEN THIS FILL FILTER
    if (searchValue) {
      return departmentsAndInner.filter((data) => data.department.toLowerCase().includes(searchValue.toLowerCase()))
    }
    return departmentsAndInner
  }, [searchValue, departmentsAndInner])

  return (
    <>
      <Box>
        <Box padding={1} marginTop={4}>
          <Typography variant="h4">Departments</Typography>
        </Box>
        <Box>{/* <Search label={"Search Department"} value={searchValue} setValue={setSearchValue}/> */}</Box>
      </Box>

      <Box sx={{ mt: 2 }}>
        <Grid container spacing={3}>
          {filteredDepartment.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
              <DepartmentCard
                title={item.department}
                reportingHead={item.reporting_head}
                count={item.count}
                wings={item?.wings}
                poupButton={
                  <Stack direction={'row'} gap={1}>
                    <Box>
                      <DepartmentWingEmployeesModal deptName={item.department} />  {/*EMPLOYEES VIEW MODAL PAGE, HERE WE ARE PASSING DEPARTMENT DETAILS*/}
                    </Box>
                    <Box>{item?.wings?.length > 0 && <DepartmentPopup wings={item?.wings} />}</Box>  {/*DEPARTMENTS INNER WING SECTION MODAL PAGE, PASSING WINGS DETAILS AND HEIRARCHY*/}
                  </Stack>
                }
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  )
}

export default React.memo(DepartmentViewComponents)
