import { Box, Chip } from "@mui/material";
import { Icon } from "@iconify/react";

export const EMI_COURSE_PAYMENT_TABLE_HEAD = [
  {
    field: "sl_no",
    headerName: "slNo",
    headerAlign: "left",
    align: "left",
    width: 20,
  },
  {
    field: "createdBy",
    headerName: "OGA",
    width: 250,
  },
  {
    field: "courseName",
    headerName: "Course",
    width: 350,
  },
  {
    field: "sessionCount",
    headerName: "Session Count",
    width: 120,
  },
  {
    field: "MRP",
    headerName: "Total Course Amount",
    width: 120,
  },
  {
    field: "discount",
    headerName: "Discount",
    width: 120,
  },
  {
    field: "netAmt",
    headerName: "Total Discounted Amount",
    width: 120,
  },
  {
    field: "totalPaidAmt",
    headerName: "Total Paid Amount",
    width: 120,
  },
  {
    field: "balanceAmt",
    headerName: "Balance Amount",
    width: 120,
  },
  {
    field: "paymentCompletionStatus",
    headerName: "Payment Status",
    width: 200,
    renderCell: (params) => {
      return (
        <Box>
          <Chip
            variant="outlined"
            icon={
              params.row.paymentCompletionStatus == 1 ? (
                <Icon
                  icon="bitcoin-icons:verify-filled"
                  width="20"
                  height="20"
                />
              ) : (
                <Icon icon="si:warning-line" width="20" height="20" />
              )
            }
            size="small"
            color={
              params.row.paymentCompletionStatus == 1 ? "success" : "warning"
            }
            label={
              params.row.paymentCompletionStatus == 1
                ? "full payment Done"
                : "Partial Payment"
            }
          />
        </Box>
      );
    },
  },
];
