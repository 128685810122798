import React, { useEffect, useState } from "react";
import GlobalWrapper from "../../../../components/Wrapper/GlobalWrapper";
import { Box, Divider, Stack } from "@mui/material";
import CartHeader from "./CartHeader";
import StudentProfile from "./StudentProfile";
import CartCourses from "./CartCourses";
import useResponsive from "../../../../Hooks/useResponsive";
import { useLocation } from "react-router-dom";
import { getAvailableCourses } from "../../../../redux/features/coursesSlice";
import { useSelector, useDispatch } from "react-redux";
import WishList from "./WishList";
import {
  getClasses,
  getMediums,
  getSubjects,
  getSyllabus,
} from "../../../../redux/features/croSlice";
import {
  getCartItems,
  getLeadDetails,
  getleadPreferance,
} from "../../../../redux/features/cartSlice";
import { motion } from "framer-motion";

const Cart = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");
  const { availableCourse } = useSelector((state) => state.courses);
  const { cartItems } = useSelector((state) => state.cart);
  const [totalCourses, setTotalCourses] = useState([]);
  // ---------------------------------------------------------------------------
  const [availableCartItems, setAvailableCartItems] = useState([]);

  const [selectedCourse, setSelectedCourse] = useState(null);
  const [CourseDetails, setCourseDetails] = useState([]);
  const [changeValidation, setChangeValidation] = useState([]);
  const [paidCartItems, setPaidCartItems] = useState([]);
  const [paymentProcessingItems, setPaymentProcessingItems] = useState([]);

  useEffect(() => {
    const updatedCartData = cartItems
      .filter((item) => {
        return (
          item.CartPaymentLogs.length === 0 ||
          item.CartPaymentLogs.some(
            (log) => log.paidStatus === 0 && log.invoiceId == null
          )
          // item.wishList == 0
        );
      })
      .map((item) => ({
        ...item,
        discountedAmount:
          parseFloat(item.MRP) -
          parseFloat(item.discount) +
          parseFloat(item.admissionFee),
        paidAmt: item.CartPaymentLogs[0]?.payableAmt || "",
        noOfSessions: item.sessionCount,
      }));
    // const PaidItems = cartItems.filter((item) => {
    //   return item.CartPaymentLogs.some((log) => log.paidStatus === 1);

    // });
    // -------------------------------------------------------------------
    const UpdatedPaidItems = cartItems
      .filter((item) => {
        // return item.CartPaymentLogs.some((log) => log.paidStatus === 1);
        return (
          item.courseEnrollmentStatus == 1 &&
          item.CartPaymentLogs.some((log) => log.paidStatus === 1)
        );
      })
      .map((item) => ({
        ...item,
        discountedAmount: parseInt(item.MRP) - parseInt(item.discount),
        paidAmt: item.CartPaymentLogs[0]?.payableAmt || "",
        noOfSessions: item.sessionCount,
      }));
    // ----------------------------------------------------------------
    const UpdatedPaymentProcessingItems = cartItems
      .filter((item) => {
        return item.CartPaymentLogs.some(
          (log) =>
            log.linkGeneratedStatus == 1 &&
            log.invoiceId &&
            log.paidStatus == 0 &&
            item.CartPaymentLogs.length == 1
        );
      })
      .map((item) => ({
        ...item,
        discountedAmount: parseInt(item.MRP) - parseInt(item.discount),
        paidAmt: item.CartPaymentLogs[0]?.payableAmt || "",
        noOfSessions: item.sessionCount,
      }));
    setPaymentProcessingItems(UpdatedPaymentProcessingItems);
    setPaidCartItems(UpdatedPaidItems);
    setAvailableCartItems(updatedCartData);
    setChangeValidation(updatedCartData);
  }, [cartItems]);

  useEffect(() => {
    const leadId = location?.state.leadId;
    dispatch(getCartItems(leadId));
    dispatch(getLeadDetails(leadId));
  }, [location]);
  useEffect(() => {
    const leadId = location?.state.leadId;
    dispatch(getClasses());
    dispatch(getMediums());
    dispatch(getSyllabus());
    dispatch(getSubjects());
    dispatch(getAvailableCourses());
    dispatch(getleadPreferance(leadId));
  }, []);
  // Update totalCourses whenever availableCourse changes
  useEffect(() => {
    if (availableCourse?.length) {
      const updatedCourses = availableCourse.map((course) => ({
        ...course,
        emi: course.emi || false,
        wishList: course.wishList || false,
        paidAmt: course.paidAmt || 0,
        discountedAmount: course.discountedAmount || 0,
        discount: course.discount || 0,
        remarks: course.remarks || "",
        admissionFee: course.admissionFee || 0,
      }));
      setCourseDetails(updatedCourses);
    }
  }, [availableCourse]);
  return (
    <GlobalWrapper title="Cart">
      <Box>
        <Box pb={2} width={mdUp ? "25%" : "100%"}>
          {mdUp ? null : (
            <motion.div
              initial={{ x: 100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ type: "spring", stiffness: 100, damping: 20 }}
            >
              <StudentProfile />
            </motion.div>
          )}
        </Box>
        <Stack direction={smUp ? "row" : "column"} spacing={1}>
          <Box width={mdUp ? "75%" : "100%"}>
            <CartHeader
              selectedCourse={selectedCourse}
              setSelectedCourse={setSelectedCourse}
              CourseDetails={CourseDetails}
              setTotalCourses={setTotalCourses}
              availableCartItems={availableCartItems}
            />
            <Box>
              <motion.div
                initial={{ x: -100, opacity: 0 }} // Start from the left
                animate={{ x: 0, opacity: 1 }} // Slide to the center
                transition={{ type: "spring", stiffness: 100, damping: 20 }} // Smooth spring animation
              >
                <CartCourses
                  availableCartItems={availableCartItems}
                  setAvailableCartItems={setAvailableCartItems}
                  setTotalCourses={setTotalCourses}
                  totalCourses={totalCourses}
                  paidCartItems={paidCartItems}
                  changeValidation={changeValidation}
                  paymentProcessingItems={paymentProcessingItems}
                />
              </motion.div>
            </Box>
            <Box>
              {mdUp ? null : (
                <WishList
                  setTotalCourses={setTotalCourses}
                  totalCourses={totalCourses}
                  availableCartItems={availableCartItems}
                  setAvailableCartItems={setAvailableCartItems}
                />
              )}
            </Box>
          </Box>
          <Box>
            {mdUp ? (
              <motion.div
                initial={{ x: 100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ type: "spring", stiffness: 100, damping: 20 }}
              >
                <StudentProfile />
              </motion.div>
            ) : null}

            {mdUp ? (
              <WishList
                setTotalCourses={setTotalCourses}
                totalCourses={totalCourses}
                availableCartItems={availableCartItems}
                setAvailableCartItems={setAvailableCartItems}
              />
            ) : null}
          </Box>
        </Stack>
      </Box>
    </GlobalWrapper>
  );
};

export default Cart;
