import {
  Box,
  Chip,
  Divider,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useSnackbar } from "notistack";
import ActionMenu from "./ActionMenu";
import useResponsive from "../../../../Hooks/useResponsive";
import { Icon } from "@iconify/react";

const PaymentProcessingCourses = ({ paymentProcessingItems }) => {
  const { enqueueSnackbar } = useSnackbar();
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");

  const [filterCourseData, setFilterCourse] = useState([]);

  const handleInputChange = (e, courseId, field) => {
    const { value, type, checked } = e.target;

    const updatedCourses = paymentProcessingItems.map((item) => {
      if (item.id === courseId) {
        // Validate if MRP or discount fields are empty before updating
        if (
          item.id === courseId &&
          type === "checkbox" &&
          (item.MRP == "" || item.discount == "" || item.noOfSessions == "")
        ) {
          enqueueSnackbar("Some fields are empty...!", {
            variant: "error",
          });
          return item; // Don't update if there's an empty field
        }

        // Calculate discountedAmount if either amount or discount is updated
        let updatedItem = {
          ...item,
          [field]: type === "checkbox" ? checked : value,
        };

        if (field === "MRP" || field === "discount") {
          const amount = field === "MRP" ? value : item.MRP || 0;
          const discount = field === "discount" ? value : item.discount || 0;
          const discountedAmount = amount - discount;

          updatedItem = { ...updatedItem, discountedAmount };
        }
        if (field === "admissionFee") {
          updatedItem = { ...updatedItem, admissionFee: checked ? 500 : 0 };
        }

        return updatedItem;
      }
      return item;
    });

    // setAvailableCartItems(updatedCourses);
  };

  return (
    <Box pt={2}>
      {paymentProcessingItems?.length == 0 ? null : (
        <>
          <Typography variant="h6">Payment Processing Course</Typography>
          <Divider sx={{ borderStyle: "dashed", borderBottomWidth: 2 }} />
        </>
      )}

      <AnimatePresence>
        {paymentProcessingItems?.map((item) => {
          return (
            <motion.div
              key={item.id}
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -50 }}
              transition={{ duration: 0.5 }}
            >
              <Paper
                sx={{
                  backgroundColor: "#edf2fb",
                  mt: 3,
                  padding: 2,
                  borderRadius: 3,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Stack direction={mdUp ? "row" : "column"} spacing={2}>
                  <Box sx={{ maxWidth: 100, minWidth: 70 }}>
                    <img
                      style={{ borderRadius: 15 }}
                      src={
                        item.courseImg ||
                        "https://campustechnology.com/-/media/EDU/CampusTechnology/2019-Images/20191209online.jpg"
                      }
                      height={60}
                      width={60}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: 13, fontWeight: "bolder" }}>
                        {item.courseName}
                      </Typography>
                    </Box>
                  </Box>

                  {/* Text fields for session count, amount, discount, and discounted amount */}
                  <motion.div
                    initial={{ opacity: 0, x: -30 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -20 }}
                    transition={{ duration: 0.5 }}
                  >
                    <TextField
                      type="number"
                      disabled
                      label="no: of sessions"
                      value={item.noOfSessions || ""}
                      onChange={(e) =>
                        handleInputChange(e, item.id, "noOfSessions")
                      }
                      sx={{
                        // width: "150px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "30px",
                          padding: "0px",
                        },
                        "& .MuiOutlinedInput-input": {
                          height: "30px",
                          padding: "5px 10px",
                          fontSize: "0.8rem",
                        },
                        "& .MuiInputLabel-root": {
                          fontSize: "0.8rem",
                          top: "-5px",
                        },
                        "& .MuiInputLabel-shrink": {
                          top: "0",
                        },
                      }}
                    />
                  </motion.div>

                  {/* <Box> */}
                  {/* <Stack direction={"column"} spacing={1}> */}
                  <motion.div
                    initial={{ opacity: 0, x: -30 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -20 }}
                    transition={{ duration: 0.7 }}
                  >
                    <TextField
                      label="Amount"
                      type="number"
                      disabled
                      value={item.MRP || ""}
                      onChange={(e) => handleInputChange(e, item.id, "MRP")}
                      sx={{
                        // width: "150px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "30px",
                          padding: "0px",
                        },
                        "& .MuiOutlinedInput-input": {
                          height: "30px",
                          padding: "5px 10px",
                          fontSize: "0.8rem",
                        },
                        "& .MuiInputLabel-root": {
                          fontSize: "0.8rem",
                          top: "-5px",
                        },
                        "& .MuiInputLabel-shrink": {
                          top: "0",
                        },
                      }}
                    />
                  </motion.div>
                  <motion.div
                    initial={{ opacity: 0, x: -30 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -20 }}
                    transition={{ duration: 0.5 }}
                  >
                    <TextField
                      label="Discount"
                      type="number"
                      disabled
                      value={item.discount || ""}
                      onChange={(e) =>
                        handleInputChange(e, item.id, "discount")
                      }
                      sx={{
                        // width: "150px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "30px",
                          padding: "0px",
                        },
                        "& .MuiOutlinedInput-input": {
                          height: "30px",
                          padding: "5px 10px",
                          fontSize: "0.8rem",
                        },
                        "& .MuiInputLabel-root": {
                          fontSize: "0.8rem",
                          top: "-5px",
                        },
                        "& .MuiInputLabel-shrink": {
                          top: "0",
                        },
                      }}
                    />
                  </motion.div>
                  <motion.div
                    initial={{ opacity: 0, x: -30 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -20 }}
                    transition={{ duration: 0.5 }}
                  >
                    <TextField
                      label="Discounted Amount"
                      disabled
                      type="number"
                      value={item.discountedAmount || ""}
                      onChange={(e) =>
                        handleInputChange(e, item.id, "discountedAmount")
                      }
                      sx={{
                        // width: "150px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "30px",
                          padding: "0px",
                        },
                        "& .MuiOutlinedInput-input": {
                          height: "30px",
                          padding: "5px 10px",
                          fontSize: "0.8rem",
                        },
                        "& .MuiInputLabel-root": {
                          fontSize: "0.8rem",
                          top: "-5px",
                        },
                        "& .MuiInputLabel-shrink": {
                          top: "0",
                        },
                      }}
                    />
                    {parseFloat(item.admissionFee) > 0 ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          pt: 0.5,
                        }}
                      >
                        <Chip
                          variant="outlined"
                          size="small"
                          color="success"
                          label={`adm fee: ${item.admissionFee}`}
                          icon={
                            <Icon icon="mdi:approve" width="12" height="12" />
                          }
                          sx={{ height: 15, fontSize: 7 }}
                        />
                      </Box>
                    ) : null}
                  </motion.div>
                  {/* </Stack>
                        </Box> */}
                  <motion.div
                    initial={{ opacity: 0, x: -30 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -20 }}
                    transition={{ duration: 0.9 }}
                  >
                    <TextField
                      label="paying Amount"
                      type="number"
                      disabled
                      value={item.paidAmt || ""}
                      onChange={(e) => handleInputChange(e, item.id, "paidAmt")}
                      sx={{
                        // width: "150px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "30px",
                          padding: "0px",
                        },
                        "& .MuiOutlinedInput-input": {
                          height: "30px",
                          padding: "5px 10px",
                          fontSize: "0.8rem",
                        },
                        "& .MuiInputLabel-root": {
                          fontSize: "0.8rem",
                          top: "-5px",
                        },
                        "& .MuiInputLabel-shrink": {
                          top: "0",
                        },
                      }}
                    />
                  </motion.div>
                  {/* ------------------------------action-menu-------------/ */}
                  <Box>
                    <ActionMenu paymentProcessing={true} item={item} />
                  </Box>

                  {/* ------------------- */}
                </Stack>
              </Paper>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Chip
                  icon={<Icon icon="mdi:rupee" />}
                  color="warning"
                  variant="outlined"
                  size="small"
                  label="Payment Processing...!"
                />
              </Box>
            </motion.div>
          );
        })}
      </AnimatePresence>
    </Box>
  );
};

export default PaymentProcessingCourses;
