import { Box } from "@mui/material";
import React from "react";
import GlobalDeleteDialog from "../../../../components/mui/Dialogue/GlobalDeleteDialog";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  deletePaymentLog,
  getCartItems,
} from "../../../../redux/features/cartSlice";

const DeleteLog = ({ id }) => {
  const location = useLocation();
  const { loading } = useSelector((state) => state.cart);
  return (
    <Box>
      <GlobalDeleteDialog
        title="Delete Log"
        loading={loading}
        btnName={false}
        deleteNote={"Are you sure..! you want to delete this log..? "}
        api={deletePaymentLog(id)}
        upDateCall={getCartItems(location?.state.leadId)}
      />
    </Box>
  );
};

export default DeleteLog;
