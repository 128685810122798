import { Box, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DatePickerCustom from '../DatePicker/DatePickerCustom'
import dayjs from 'dayjs'

const FromAndToDatePicker = ({
  direction = 'row',
  fromLabel = 'From Date',
  toLabel = 'To Date',
  fromDate,
  toDate,
  setFromDate,
  setToDate,
}) => {

  return (
    <Stack direction={direction} gap={1}>
      <DatePickerCustom
        value={dayjs(fromDate)}
        setValue={(date) => {
          if (setFromDate) {
            setFromDate(date.format('YYYY-MM-DD'))
          }
        }}
        viewDetails={['year', 'month', 'day']}
        error={false}
        label={fromLabel}
      />
      <DatePickerCustom
        value={dayjs(toDate)}
        setValue={(date) => {
          if (setToDate) {
            setToDate(date.format('YYYY-MM-DD'))
          }
        }}
        viewDetails={['year', 'month', 'day']}
        error={false}
        label={toLabel}
      />
    </Stack>
  )
}

export default FromAndToDatePicker
