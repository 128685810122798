import { Chip } from "@mui/material";
import { Icon } from "@iconify/react";
import ToolTip from "../../../ToolTip/ToolTip";

const approvalStatus = (params) => {
  return (
    <Chip
      size="small"
      variant="outlined"
      label={
        params.row.status === 0
          ? "Pending"
          : params.row.status === 1
          ? "Approved"
          : "Rejected"
      }
      color={
        params.row.status === 0
          ? "warning"
          : params.row.status === 1
          ? "success"
          : "error"
      }
      icon={
        params.row.status === 0 ? (
          <Icon icon="ph:timer-bold" />
        ) : params.row.status === 1 ? (
          <Icon icon="mdi:tick" />
        ) : (
          <Icon icon="system-uicons:cross" />
        )
      }
    />
  );
};

const remarktooltip = (params) => {
  return (
    <ToolTip
      content={params.row.user_remarks}
      expand={params.row.user_remarks}
    />
  );
};
export const EMP_COMP_WORK_REQ = [
  // { field: "id", headerName: "ID", width: 90 },
  { field: "sl_no", headerName: "SlNo", width: 90 },
  { field: "date", headerName: "Date", width: 150 },
  {
    field: "user_remarks",
    headerName: "User Remarks",
    width: 400,
    renderCell: remarktooltip,
  },
  { field: "applied_on", headerName: "Applied On", width: 200 },
  {
    field: "status",
    headerName: "Status",
    width: 120,
    renderCell: approvalStatus,
  },
  {
    field: "reporting_head_remarks",
    headerName: "Reporting Head Remarks",
    width: 250,
  },
];
