import { DataGrid } from "@mui/x-data-grid";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import CustomGridToolbar from "../../datagrid/GridToolBar";
import GlobalAnchor from "../GlobalAnchor/GlobalAnchor";
import { Box, Button } from "@mui/material";
import useResponsive from "../../../Hooks/useResponsive";
import { useDispatch } from "react-redux";
import {
  clearPaginationData,
  savePaginationData,
} from "../../../redux/features/globalDatagridSlice";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Loader3D from "../../Loader/Loader3D";

const GlobalDatagrid = ({
  loading,
  tableHead,
  rows,
  rowUniqueId,
  getRowHeight,
  meta,
  actionComponent,
  rowClick,
  checkboxSelection,
}) => {
  const location = useLocation();
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");
  const dispatch = useDispatch();
  // ------------------------------------------------------------------------------
  const { paginationData } = useSelector((state) => state.globalDataGrid);
  const [gridSize, setGridSize] = useState(paginationData?.gridSize || 10);
  const [pageNum, setPageNum] = useState(paginationData?.pageNum || 1);
  const [rowCount, setRowCount] = useState(meta || 0);
  const [rowData, setRowData] = useState("");
  const [search, setSearch] = useState(paginationData?.search || "");
  const [openAnchor, setOpenAnchor] = useState(
    paginationData?.openAnchor || false
  );
  const [pathName, setPathName] = useState("");
  const [checkedRowsIds, setCheckedRowsIds] = useState([]);

  const [anchorLoading, setAnchorLoading] = useState(false);
  const memoizedRows = useMemo(() => rows, [rows]);
  useEffect(() => {
    if (openAnchor) {
      setAnchorLoading(true);
      setTimeout(() => {
        setAnchorLoading(false);
      }, 1000);
    }
  }, [openAnchor]);

  useEffect(() => {
    if (location.pathname) {
      setPathName(location.pathname);
    }
  }, [location.pathname]);

  useEffect(() => {
    dispatch(clearPaginationData());
  }, [pathName]);

  useEffect(() => {
    if (paginationData) {
      const { pageNum, gridSize, openAnchor, search, checkedRowsIds } =
        paginationData;

      setPageNum((prev) => (prev !== pageNum ? pageNum : prev));
      setGridSize((prev) => (prev !== gridSize ? gridSize : prev));
      setOpenAnchor((prev) => (prev !== openAnchor ? openAnchor : prev));
      setSearch((prev) => (prev !== search ? search : prev));
      setCheckedRowsIds((prev) =>
        prev !== checkedRowsIds ? checkedRowsIds : prev
      );
    }
  }, [paginationData]);
  const paginationDatas = useMemo(() => {
    return {
      gridSize,
      pageNum,
      rowCount,
      rowData,
      search,
      checkedRowsIds,
      openAnchor: false,
    };
  }, [gridSize, pageNum, rowCount, rowData, search, checkedRowsIds]);

  useEffect(() => {
    setRowCount(meta || 0);
  }, [rows]);
  // ----------------------------------------------------------------------------------

  useEffect(() => {
    // Combine all relevant data into one object for dispatch
    // const paginationData = {
    //   gridSize,
    //   pageNum,
    //   rowCount,
    //   rowData,
    //   openAnchor,
    //   search,
    //   checkedRowsIds,
    // };

    dispatch(savePaginationData(paginationDatas));
  }, [paginationDatas]);

  const handleSortModelChange = (sortModel) => {
    if (sortModel.length > 0) {
      const { field, sort } = sortModel[0]; // Field and sort direction (asc/desc)
    } else {
    }
  };

  const previousSearchValue = useRef("");
  const handleFilterModelChange = (filterModel) => {
    const quickFilter = filterModel.quickFilterValues?.[0] || "";

    if (quickFilter !== previousSearchValue.current) {
      searchFunction(quickFilter);
      previousSearchValue.current = quickFilter;
    }
    if (filterModel.items.length > 0) {
      filterFunction(filterModel.items);
    }
  };

  const searchFunction = (searchValue) => {
    setSearch(searchValue);
  };

  const filterFunction = (filters) => {
    filters.forEach((filter) => {
      const { columnField, operatorValue, value } = filter;
    });
  };

  // const handleRowClick = (params) => {
  //   if (rowClick) {
  //     setRowData(params.row);
  //   } else {
  //     setRowData("");
  //   }
  // };
  useEffect(() => {
    if (!openAnchor) {
      setRowData("");
    }
  }, [openAnchor]);

  useEffect(() => {
    if (rowData) {
      // setOpenAnchor(true);
      dispatch(savePaginationData({ ...paginationDatas, openAnchor: true }));
    } else {
      // setOpenAnchor(false);
      savePaginationData({ ...paginationDatas, openAnchor: false });
    }
  }, [rowData]);

  const handleClick = useCallback(() => {
    setOpenAnchor(false);
    savePaginationData(paginationDatas);
  }, [setOpenAnchor, savePaginationData, paginationDatas]);

  const lastTapRef = useRef(0);

  const handleRowClicked = useCallback(
    (params) => {
      const currentTime = Date.now(); // Efficient time fetching
      const tapGap = 300; // Double-tap threshold in milliseconds

      if (currentTime - lastTapRef.current < tapGap) {
        // Double-tap detected
        if (rowClick && params) {
          setRowData(params); // Update state only when necessary
        }
      }

      lastTapRef.current = currentTime; // Always update last tap time
    },
    [setRowData]
  );

  const handleSelectionChange = useCallback(
    (datas) => {
      if (checkboxSelection) {
        setCheckedRowsIds(datas);
      }
    },
    [setCheckedRowsIds]
  );

  const holdTimeoutRef = useRef(null);
  const holdDuration = 300;

  const handleRowClickStart = useCallback((rowData) => {
    holdTimeoutRef.current = setTimeout(() => {
      setRowData(rowData);
    }, holdDuration);
  }, []);

  const handleRowClickEnd = useCallback(() => {
    if (holdTimeoutRef.current) {
      clearTimeout(holdTimeoutRef.current);
      holdTimeoutRef.current = null;
    }
  }, []);

  return (
    <div>
      <DataGrid
        checkboxSelection={checkboxSelection}
        onSelectionModelChange={
          checkboxSelection ? handleSelectionChange : null
        }
        disableVirtualization
        components={{ Toolbar: CustomGridToolbar }}
        loading={loading}
        autoHeight
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            textOverflow: "clip",
            whiteSpace: "break-spaces",
            lineHeight: 1,
          },
        }}
        getRowHeight={getRowHeight}
        // onRowDoubleClick={handleRowClick}
        // onRowClick={(params) => {
        //   // Prevent default onRowClick handling
        //   params.event.preventDefault();
        // }}
        componentsProps={{
          row: {
            onMouseDown: (event) => {
              const rowElement = event.currentTarget;
              const rowId = rowElement.getAttribute("data-id");
              const rowData = rows?.find(
                (row) => row[rowUniqueId] === parseInt(rowId, 10)
              );
              if (rowData) {
                handleRowClicked(rowData);
              }
            },
            onMouseUp: handleRowClickEnd,
            onMouseLeave: handleRowClickEnd,
            onTouchStart: (event) => {
              const rowElement = event.currentTarget;
              const rowId = rowElement.getAttribute("data-id");
              const rowData = rows.find(
                (row) => row[rowUniqueId] === parseInt(rowId, 10)
              );
              if (rowData) {
                handleRowClicked(rowData);
              }
            },
            onTouchEnd: handleRowClickEnd,
            onTouchCancel: handleRowClickEnd,
          },
        }}
        rows={rows ? memoizedRows : []}
        columns={tableHead}
        pagination
        paginationMode="server"
        getRowId={(row) => row[rowUniqueId]}
        pageSize={gridSize}
        columnBuffer={30}
        rowCount={rowCount}
        rowsPerPageOptions={[5, 10, 25, 50]}
        onPageSizeChange={(newGridSize) => setGridSize(newGridSize)}
        page={pageNum - 1}
        onPageChange={(newPageNum) => {
          setPageNum(newPageNum + 1);
        }}
        onSortModelChange={handleSortModelChange}
        onFilterModelChange={handleFilterModelChange}
      />
      <GlobalAnchor
        openAnchor={openAnchor}
        setOpenAnchor={setOpenAnchor}
        handleClick={handleClick}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}></Box>
        {anchorLoading ? (
          <Box
            sx={{
              width: 300,
              display: "flex",
              justifyContent: "center",
              pr: 7,
            }}
          >
            <Loader3D />
          </Box>
        ) : (
          <Box>{actionComponent}</Box>
        )}
      </GlobalAnchor>
    </div>
  );
};

export default GlobalDatagrid;
