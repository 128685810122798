import { Box, Stack, Button, TextField } from "@mui/material";
import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import MotionContainer from "../../../../components/FramerMotion/MotionContainer";
import { AnimatePresence, motion } from "framer-motion";
import useResponsive from "../../../../Hooks/useResponsive";
import {
  AddButton,
  DeleteButton,
} from "../../../../components/mui/Buttons/Buttons";

// Extend Day.js with custom parse format
dayjs.extend(customParseFormat);

const DayTimePreference = ({ setPreferences, preferences }) => {
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");

  const handleDayChange = (index, event) => {
    const newPreferences = [...preferences];
    newPreferences[index].day = event.target.value;
    setPreferences(newPreferences);
  };

  const handleTimeChange = (index, field, newValue) => {
    const newPreferences = [...preferences];
    newPreferences[index][field] = newValue;
    setPreferences(newPreferences);
  };

  const handleAddPreference = () => {
    setPreferences((prevPreferences) => [
      ...prevPreferences,
      { day: "", startTime: dayjs(), endTime: dayjs() },
    ]);
  };

  const handleRemovePreference = (index) => {
    const newPreferences = preferences.filter((_, i) => i !== index);
    setPreferences(newPreferences);
  };

  const weekdays = [
    { name: "Sunday", value: 0 },
    { name: "Monday", value: 1 },
    { name: "Tuesday", value: 2 },
    { name: "Wednesday", value: 3 },
    { name: "Thursday", value: 4 },
    { name: "Friday", value: 5 },
    { name: "Saturday", value: 6 },
  ];

  return (
    <Box>
      <Stack direction={"column"} spacing={2}>
        {/* <AnimatePresence> */}
        {preferences.map((pref, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 100 }}
            transition={{ duration: 0.5 }}
          >
            <Stack
              direction={mdUp ? "row" : "column"}
              spacing={1}
              alignItems="center"
            >
              <MotionContainer delay={1}>
                <Box sx={{ width: "100%" }}>
                  <FormControl fullWidth>
                    <InputLabel id={`day-select-label-${index}`}>
                      Days
                    </InputLabel>
                    <Select
                      labelId={`day-select-label-${index}`}
                      id={`day-select-${index}`}
                      value={pref.day}
                      label="Days"
                      onChange={(event) => handleDayChange(index, event)}
                    >
                      {weekdays.map((item) => (
                        <MenuItem key={item.value} value={item.name}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </MotionContainer>
              <MotionContainer delay={0.7}>
                <Box sx={{ width: "100%" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      label="Start Time"
                      value={pref.startTime}
                      onChange={(newValue) =>
                        handleTimeChange(index, "startTime", newValue)
                      }
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Box>
              </MotionContainer>
              <MotionContainer delay={0.5}>
                <Box sx={{ width: "100%" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      label="End Time"
                      value={pref.endTime}
                      onChange={(newValue) =>
                        handleTimeChange(index, "endTime", newValue)
                      }
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Box>
              </MotionContainer>

              <DeleteButton
                size="small"
                action={() => handleRemovePreference(index)}
              />
            </Stack>
          </motion.div>
        ))}
        {/* </AnimatePresence> */}
        <Box>
          <AddButton size="small" action={handleAddPreference}>
            Add Preference
          </AddButton>
        </Box>
      </Stack>
      {/* <pre>
        {JSON.stringify(
          preferences.map((pref) => ({
            ...pref,
            startTime: pref.startTime.format("hh:mm A"),
            endTime: pref.endTime.format("hh:mm A"),
          })),
          null,
          2
        )}
      </pre> */}
    </Box>
  );
};

export default DayTimePreference;
