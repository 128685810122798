import React from 'react'
import GlobalWrapper from '../../../components/Wrapper/GlobalWrapper'
import CardViewComponents from './CardViewComponents'
import DepartmentViewComponents from './DepartmentViewComponents'


const NewHrDashboard = () => {

  return (
    <GlobalWrapper title={'Dashboard'}>
     <CardViewComponents/> {/*Dashboard CARDS SECTION*/}
     <DepartmentViewComponents/> {/*DEPARTMENT AND EMPLOYEES CARDS SECTION*/}
    </GlobalWrapper>
  )
}

export default NewHrDashboard
