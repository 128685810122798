import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiRequest from "../../api/request";
import { formatDataForDataTable } from "../../utils/formatData";

const initialState = {
  loading: false,
  foodList: [],
  eveningFoodList: [],
  alredayLunchMarkedDetails: {},
  alreadyEveningMarkedDetails: {},
  eveningSlots: [],
  lunchslots: [],
  foodStatus: [],
  monthlyStatus:[],
  evenigFoodRequestsForApproval:[],
  foodCategorylunchOrEvening:"",
  users:[]
};

export const getFoodList = createAsyncThunk(
  "food/getFoodList",
  async ({ date, userId }) => {
    const res = await apiRequest({
      method: "get",
      url: `food-list/get-food-list/${date}`,
    });
    let filter = null;
    if (userId) {
      filter = res.data.foodList.find((list) => list.food_user_id == userId);
    }
    return { res: res, filter };
  }
);

export const getEveningFoodList = createAsyncThunk(
  "food/getEveningFoodList",
  async ({ date, userId }) => {
    const res = await apiRequest({
      method: "get",
      url: `food-list/get-evening-food-list/${date}`,
    });
    let filter = null;
    if (userId) {
      filter = res.data.eveningData.find((list) => list.food_user_id == userId);
    }
    return { res: res, filter };
  }
);

export const getFoodSlots = createAsyncThunk("food/getFoodSlots", async () => {
  const res = await apiRequest({
    method: "get",
    url: `food-list/get-food-slots`,
  });
  
  return res;
});

export const getFoodStatus = createAsyncThunk(
  "food/getFoodStatus",
  async (date) => {
    const res = await apiRequest({
      method: "get",
      url: `food-list/get-day-food-status/${date}`,
    });
    return res;
  }
);

export const getAllUsers = createAsyncThunk(
  "food/getAllUsers",
  async () => {
    const res = await apiRequest({
      method: "get",
      url: `food-list/get-employees`,
    });
    return res;
  }
);

export const addMealStatus = createAsyncThunk("food/addMealStatus", async (data) => {
  const res = await apiRequest({
    method: "post",
    url: `food-list/add-day-meal`,
    data: data,
  });
  return res;
});


export const monthlyFoodStatus = createAsyncThunk(
  "food/monthlyFoodStatus",
  async (date) => {
    const res = await apiRequest({
      method: "get",
      url: `food-list/get-day-food-list-by-month/${date}`,
    });
    return res;
  }
);

export const removeFood = createAsyncThunk("food/removeFood", async (id) => {
  const res = await apiRequest({
    method: "delete",
    url: `food-list/delete-food-list/${id}`,
  });
  return res;
});

export const removeMealStatus = createAsyncThunk("food/removeMealStatus", async (id) => {
  const res = await apiRequest({
    method: "delete",
    url: `food-list/delete-day-food-status/${id}`,
  });
  return res;
});


export const addFood = createAsyncThunk("food/addFood", async (data) => {
  const res = await apiRequest({
    method: "post",
    url: `food-list/add-food-list`,
    data: data,
  });
  return res;
});

export const verifyStatus = createAsyncThunk(
  "food/verifyStatus",
  async (id) => {
    const res = await apiRequest({
      method: "patch",
      url: `food-list/food-list-verification/${id}`,
    });
    return res;
  }
);

export const allEveningFoodRequests = createAsyncThunk("food/allEveningFoodRequests", async (date) => {
  const res = await apiRequest({
    method: "get",
    url: `food-list/get-food-List-under/${date}`,
  });
  return res;
});

export const eveningFoodApproveOrRejectOrBulk = createAsyncThunk(
  "food/eveningFoodApproveOrRejectOrBulk",
  async (data) => {
    const res = await apiRequest({
      method: "patch",
      url: `food-list/evening-single-bulk-approval${data.id ? `/${data.id}/${data.status}`:"/0/0"}`,
      data: data
    });
    return res;
  }
);

const foodSlice = createSlice({
  name: "foodSlice",
  initialState,
  reducers: {
    foodCategoryChangeDispatch:(state,action)=>{
      return{
        ...state,
        foodCategorylunchOrEvening:action.payload
      }
      
    },
    ClearFoodCategoryChangeDispatch:(state)=>{
      return{
        ...state,
        foodCategorylunchOrEvening:""
      }
    }
  },
  extraReducers: (builder) => {
    //.................GET FOODLIST..............................
    builder.addCase(getFoodList.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getFoodList.fulfilled, (state, action) => {
      return {
        ...state,
        foodList: formatDataForDataTable(action.payload?.res?.data?.foodList),
        loading: false,
        alredayLunchMarkedDetails: action.payload?.filter || {},
      };
    });

    //...................EVENIG FOOD LIST.............
    builder.addCase(getEveningFoodList.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getEveningFoodList.fulfilled, (state, action) => {
      return {
        ...state,
        foodList: formatDataForDataTable(action.payload?.res?.data?.eveningData),
        loading: false,
        alreadyEveningMarkedDetails: action.payload?.filter || {},
      };
    });

    //.................GET SLOTS..............................
    builder.addCase(getFoodSlots.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getFoodSlots.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        lunchslots: action.payload?.data?.foodSlots?.filter(
          (list) => list.food_shift_id == 1
        ),
        eveningSlots: action.payload?.data?.foodSlots?.filter(
          (list) => list.food_shift_id == 2
        ),
      };
    });

    //.................GET FOOD STATUS..............................
    builder.addCase(getFoodStatus.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getFoodStatus.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        foodStatus: action.payload.data.status || [],
      };
    });

        //.................GET ALL USERS..............................
        builder.addCase(getAllUsers.pending, (state, action) => {
          return {
            ...state,
            loading: true,
          };
        });
        builder.addCase(getAllUsers.fulfilled, (state, action) => {
          return {
            ...state,
            loading: false,
            users: action.payload.data || [],
          };
        });
    
    
    //.................ADD MEAL STATUS..............................
    builder.addCase(addMealStatus.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(addMealStatus.fulfilled, (state) => {
      return {
        ...state,
        loading: false,
      };
    });


    //.................GET FOOD STATUS BY MONTH..............................
    builder.addCase(monthlyFoodStatus.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(monthlyFoodStatus.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        monthlyStatus: formatDataForDataTable(action.payload?.data?.list) || [],
      };
    });

    //.................DELETE FOOD..............................
    builder.addCase(removeFood.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(removeFood.fulfilled, (state) => {
      return {
        ...state,
        loading: false,
      };
    });

        //.................DELETE MEAL STATUS..............................
        builder.addCase(removeMealStatus.pending, (state, action) => {
          return {
            ...state,
            loading: true,
          };
        });
        builder.addCase(removeMealStatus.fulfilled, (state) => {
          return {
            ...state,
            loading: false,
          };
        });

    //.................ADD FOOD..............................
    builder.addCase(addFood.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(addFood.fulfilled, (state) => {
      return {
        ...state,
        loading: false,
      };
    });

    //.................VERIFY FOOD STATUS..............................
    builder.addCase(verifyStatus.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(verifyStatus.fulfilled, (state) => {
      return {
        ...state,
        loading: false,
      };
    });
        //.................GET EVENING FOOD REQUESTS FOR APPROVALS..............................
        builder.addCase(allEveningFoodRequests.pending, (state, action) => {
          return {
            ...state,
            loading: true,
          };
        });
        builder.addCase(allEveningFoodRequests.fulfilled, (state, action) => {
          return {
            ...state,
            loading: false,
            evenigFoodRequestsForApproval: formatDataForDataTable(action.payload?.data) || []
          };
        });
                //.................STATUS CHANGE FOR EVENING FOOD APPROVALS..............................
                builder.addCase(eveningFoodApproveOrRejectOrBulk.pending, (state, action) => {
                  return {
                    ...state,
                    loading: true,
                  };
                });
                builder.addCase(eveningFoodApproveOrRejectOrBulk.fulfilled, (state, action) => {
                  return {
                    ...state,
                    loading: false,
                  };
                });
  },
});

export const{foodCategoryChangeDispatch,ClearFoodCategoryChangeDispatch}=foodSlice.actions
export default foodSlice.reducer;
