import { Box, Card, Chip, Stack, Typography } from '@mui/material'
import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { fNumber } from '../../../utils/formatNumber'
import { useTheme } from '@mui/material/styles'
const DepartmentCard = ({ title, poupButton, reportingHead, count, wings }) => {
  const theme = useTheme()
  const hasWings = wings && wings.length > 0
  const wingCounts = hasWings ? wings.map((wing) => wing.count) : []
  const wingNames = hasWings ? wings.map((wing) => wing.wingName) : []

  const chartOptions = {
    colors: ['#008000'],
    chart: { sparkline: { enabled: true } },
    plotOptions: { bar: { columnWidth: '68%', borderRadius: 2 } },
    tooltip: {
      x: { show: true },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: () => 'Count',
        },
      },
      marker: { show: false },
    },
    xaxis: {
      categories: wingNames, // Apply dynamic labels for x-axis
      labels: { style: { fontSize: '10px' } },
    },
   
    // dataLabels: {
    //   enabled: true, // Enable data labels
    //   formatter: (val) => val === 0 ? '0' : val, // Display '0' for zero values
    //   style: { fontSize: '8px', colors: ['#000'] },
    // },

  }

  return (
    <>
      <Card
        // elevation={6}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 190,
          // minWidth:300,
          width: 340,
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
          // borderRadius: 3,
          '&:hover': {
            border: '2px solid #03fc0f',
          },
        }}>
        <Box px={2}>
          <Typography variant="subtitle2">{title}</Typography>

          <Box sx={{ display: 'flex', mt: 1, alignItems: 'center' }}>
            <Box>
              <Chip
                sx={{ height: 25, width: 150, fontSize: 8, backgroundColor: '#008000', color: '#fff', }}
                variant="filled"
                size="small"
                // color="success"
                label={reportingHead}
              />
            </Box>
            <Box sx={{ ml: 5 }}>
              <ReactApexChart
                type="bar"
                series={[{ data: wingCounts }]}
                options={chartOptions}
                width={50}
                height={40}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mt: 1,
              alignItems: 'center',
              pt: 1,
            }}>
            <Typography
              mr={1}
              p={1}
              variant="h3"
              sx={{
                fontWeight: 'bold',
                color: 'black', // Use a vibrant color
                textShadow: '1px 1px 3px rgba(0, 0, 0, 0.5)', // Add a subtle shadow
              }}>
              {count}
            </Typography>
            <Box>{poupButton}</Box>
          </Box>
        </Box>
      </Card>
    </>
  )
}

export default DepartmentCard
