
export const hrFoodTableHeader = [
    {
      field: "serialNumber",
      headerName: "slNo",
      width:80
    },
    {
      field: "name",
      headerName: "Name",
      width: 230,
    },
    {
      field: "designation",
      headerName: "Designation / Role",
      width: 250,
    },
    {
      field: "department",
      headerName: "Department",
      width: 250,
    },
    {
      field: "office_name",
      headerName: "Office",
      width: 150,
    },
    {
      field: "tbl_food_slot",
      headerName: "Slot",
      width: 200,
    },
  ];
  