import { Box, Grid } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { DepartmentEmpDetailsPopUpView } from "./index";
import {
    getDepartmentEmployeesCountByDepartment,
    getDepartmentEmployeesCountByReportingHead,
} from "../../../../redux/features/dashboardAnalyticsSlice";
import DashboardWidgetCard from "../../../../components/Dashboard/GlobalComponents/DashboardWidgetCard";

export const DepartmentEmployees = () => {
    const theme = useTheme();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getDepartmentEmployeesCountByReportingHead());
        dispatch(getDepartmentEmployeesCountByDepartment());
    }, [dispatch]);

    const {
        departmentEmployeesCountByReportingHead,
        departmentEmployeesCountByDepartment,
        loading,
    } = useSelector((state) => state.dashboardAnalytics);

    console.log("departmentEmployeesCountByReportingHead",departmentEmployeesCountByReportingHead);
    console.log("departmentEmployeesCountByDepartment",departmentEmployeesCountByDepartment);

    
    const filteredDepartments = useMemo(
        () =>
            departmentEmployeesCountByDepartment.filter((department) =>
                [
                    "Technical Department",
                    "Marketing (Marketing Wing)",
                    "MEL",
                    "Finance",
                    "Creative Department",
                    "Operations (OGD)",
                    "Human Resource (HR)",
                    "Talent Acquisitions",
                    "Client Acquisitions Wing",
                    "Operations (Office Administration)",
                    "Digital Marketing Department",
                ].includes(department.department)
            ),
        [departmentEmployeesCountByDepartment]
    );

    const filteredDepartmentsByReportingHead = useMemo(
        () =>
            departmentEmployeesCountByReportingHead.filter((department) =>
                [
                    "Project-Classroom",
                    "Project-Foundation",
                    "Project-Little Genie",
                    "Linguistic Programs ",
                    "Procurement",
                    // "Top Management",
                ].includes(department.department)
            ),
        [departmentEmployeesCountByReportingHead]
    );

    const combinedDepartments = useMemo(
        () => [...filteredDepartments, ...filteredDepartmentsByReportingHead],
        [filteredDepartments, filteredDepartmentsByReportingHead]
    );

    console.log("combinedDepartments",combinedDepartments);
    

    const countArray = useMemo(
        () => combinedDepartments.map((item) => item.count),
        [combinedDepartments]
    );
    console.log("ca",countArray);

    return (
        <div>
            <Box>
                <Grid container spacing={3}>
                    {combinedDepartments.map((item, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                            <DashboardWidgetCard
                                item={item}
                                title={item.department}
                                reportingHead={item.reporting_head}
                                total={item.count}
                                chartColor={theme.palette.primary.main}
                                chartData={countArray}
                                poupButton={
                                    <>
                                        <DepartmentEmpDetailsPopUpView
                                            {...item}
                                        />
                                    </>
                                }
                            />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </div>
    );
};
