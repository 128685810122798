import React, { useEffect, useMemo, useState } from "react";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import {
  AddButton,
  CancelButton,
  SubmitButton,
  UpdateButton,
} from "../../../../mui/Buttons/Buttons";
import { useFormik, FormikProvider, Form } from "formik";
import { Box, Stack, TextField } from "@mui/material";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import {
  getMatricesPreData,
  getAllObjectivePreData,
  updateGrade,
  createGrade,
  getAllGrades,
  getLevelPreData,
  getIsAvailableLevel,
  getIsAvailableObjective,
  clearIsAvailableData,
} from "../../../../../redux/features/assessmentToolSlice";
import GlobalSelectField from "../../../../mui/GlobalSelectField";
import Loader3D from "../../../../Loader/Loader3D";
import LinearProgress from "@material-ui/core/LinearProgress";

const CreateUpdateGrade = ({ params }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const {
    loading,
    matricesPreData,
    objectivePredata,
    levelPreData,
    isAvailableLevelData,
    isAvailableObjectiveData,
  } = useSelector((state) => state.assessmentTool);
  const [open, setOpen] = useState(false);
  const memoizedParams = open ? params : {};
  const [speakpro, setSpeakPro] = useState(false);

  const Schema = Yup.object().shape({
    gradeMatrixId: Yup.string().required("gradeMatrixId is required"),
    gradeLevelId: Yup.string().when("isSpeakpro", {
      is: false,
      then: Yup.string().required("gradeLevelId is required"),
      otherwise: Yup.string(),
    }),
    gradeObjectiveId: Yup.string().when("isSpeakpro", {
      is: false,
      then: Yup.string().required("gradeObjectiveId is required"),
      otherwise: Yup.string(),
    }),
    gradeName: Yup.string().required("gradeName is required"),
  });
  const spekProSchema = Yup.object().shape({
    gradeMatrixId: Yup.string().required("gradeMatrixId is required"),
    gradeName: Yup.string().required("gradeName is required"),
  });
  const profile = JSON.parse(localStorage.getItem("cred")).profile;
  const SpeakProgradeData = [
    { id: 10, gradeName: " Grade 10" },
    { id: 20, gradeName: " Grade 20" },
    { id: 30, gradeName: "Grade 30" },
    { id: 40, gradeName: "Grade 40" },
    { id: 50, gradeName: "Grade 50" },
  ];

  const foundationgradeData = [
    { id: 10, gradeName: " Grade 10" },
    { id: 20, gradeName: " Grade 20" },
    { id: 30, gradeName: "Grade 30" },
  ];

  const formik = useFormik({
    enableReinitialize: open ? true : false,
    initialValues: open
      ? {
          isSpeakpro: matricesPreData[0]?.course_name == "speakpro" || false,
          //   gradeMatrixId: params ? params.grade_matrix_id : "",
          gradeMatrixId:
            open &&
            memoizedParams &&
            matricesPreData?.some(
              (matrix) => matrix.matrix_id === memoizedParams.grade_matrix_id
            )
              ? memoizedParams.grade_matrix_id
              : "",
          gradeLevelId:
            open &&
            memoizedParams &&
            levelPreData?.some(
              (grade) => grade.level_id === memoizedParams.grade_level_id
            )
              ? memoizedParams.grade_level_id
              : "",
          gradeObjectiveId:
            open &&
            memoizedParams &&
            objectivePredata?.some(
              (objective) =>
                objective.objective_id === memoizedParams.grade_objective_id
            )
              ? params.grade_objective_id
              : "",
          //   gradeObjectiveId: params ? params.grade_objective_id : "",
          gradeName: memoizedParams ? memoizedParams.grade_name : "",
          gradeCreatedBy: profile.userId,
          levelUpdatedBy: profile.userId,
          id: memoizedParams ? memoizedParams.grade_id : "",
        }
      : {},
    validationSchema: speakpro ? spekProSchema : Schema,
    onSubmit: (values, { resetForm }) => {
      if (memoizedParams) {
        dispatch(updateGrade(values)).then((res) => {
          if (res.payload.status === "failed") {
            enqueueSnackbar(res.payload.message, {
              variant: "error",
            });
          } else if (res.payload.status === "success") {
            setOpen(false);
            resetForm();
            dispatch(getAllGrades(profile?.departmentId));
            enqueueSnackbar(res.payload.message, {
              variant: "success",
            });
          }
        });
      } else {
        dispatch(createGrade(values)).then((res) => {
          if (res.payload.status === "failed") {
            enqueueSnackbar(res.payload.message, {
              variant: "error",
            });
          } else if (res.payload.status === "success") {
            setOpen(false);
            resetForm();
            dispatch(getAllGrades(profile?.departmentId));
            enqueueSnackbar(res.payload.message, {
              variant: "success",
            });
          }
        });
      }
    },
  });
  // console.log(formik.values);

  // ---------------------------------------------------------------
  useEffect(() => {
    if (formik.values.gradeMatrixId && open) {
      formik.setFieldValue("gradeLevelId", "");
      formik.setFieldValue("gradeObjectiveId", "");
      dispatch(clearIsAvailableData());
      dispatch(getIsAvailableLevel(formik.values.gradeMatrixId));
    }
  }, [formik.values.gradeMatrixId, open]);

  useEffect(() => {
    if (formik.values.gradeLevelId && open) {
      dispatch(getIsAvailableObjective(formik.values.gradeLevelId));
    }
  }, [formik.values.gradeLevelId, open]);

  useEffect(() => {
    if (formik.values.gradeMatrixId && open) {
      const data = matricesPreData.filter(
        (item) => item.matrix_id === formik.values.gradeMatrixId
      );

      if (data.length > 0 && data[0].matrices_course_id === 1) {
        setSpeakPro(true);
      } else {
        setSpeakPro(false);
      }
    }
  }, [formik.values.gradeMatrixId, open]);
  return (
    <GlobalDialog
      title={params ? "Update Grade" : "Create Grade"}
      open={open}
      setOpen={setOpen}
      fullWidth
      maxWidth={"sm"}
      closeButton={true}
      popupButton={
        <>
          {params ? (
            <UpdateButton
              size="small"
              disabled={params.has_child_element === 1 ? true : false}
              action={() => {
                dispatch(getMatricesPreData(profile?.departmentId));
                // dispatch(getLevelPreData());
                // dispatch(getAllObjectivePreData());
                setOpen(true);
              }}
            >
              Update
            </UpdateButton>
          ) : (
            <AddButton
              size="large"
              action={() => {
                dispatch(getMatricesPreData(profile?.departmentId));
                // dispatch(getLevelPreData());
                // dispatch(getAllObjectivePreData());
                setOpen(true);
              }}
            >
              Add Grade
            </AddButton>
          )}
        </>
      }
      content={
        <>
          {open && Object.keys(formik.values).length != 0 ? (
            <Box>
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit}>
                  <Box>
                    <Stack direction={"column"} spacing={2}>
                      {loading && (
                        <Box>
                          {" "}
                          Loading....
                          <LinearProgress />
                        </Box>
                      )}
                      <GlobalSelectField
                        disabled={loading}
                        options={{
                          formik: formik,
                          label: "Matrix",
                          name: "gradeMatrixId",
                          sx: {
                            width: "100%",
                          },
                          data: matricesPreData,

                          keys: {
                            id: "matrix_id",
                            value: "matrix_name",
                          },
                        }}
                      />
                      {speakpro || profile?.departmentId == 22 ? null : (
                        <>
                          <GlobalSelectField
                            disabled={loading}
                            options={{
                              formik: formik,
                              label: "level",
                              name: "gradeLevelId",
                              sx: {
                                width: "100%",
                              },
                              data: params
                                ? levelPreData
                                : isAvailableLevelData,

                              keys: {
                                id: "level_id",
                                value: "level_name",
                              },
                            }}
                          />
                          <GlobalSelectField
                            disabled={loading}
                            options={{
                              formik: formik,
                              label: "Objective",
                              name: "gradeObjectiveId",
                              sx: {
                                width: "100%",
                              },
                              data: params
                                ? objectivePredata
                                : isAvailableObjectiveData,

                              keys: {
                                id: "objective_id",
                                value: "objective_name",
                              },
                            }}
                          />
                        </>
                      )}

                      <GlobalSelectField
                        options={{
                          formik: formik,
                          label: "Grade Name",
                          name: "gradeName",
                          sx: {
                            width: "100%",
                          },
                          data:
                            speakpro || profile?.departmentId == 22
                              ? SpeakProgradeData
                              : foundationgradeData,

                          keys: {
                            id: "id",
                            value: "gradeName",
                          },
                        }}
                      />
                    </Stack>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: 2,
                      }}
                    >
                      <Stack direction={"row"} spacing={1}>
                        <CancelButton action={() => setOpen(false)}>
                          Cancel
                        </CancelButton>
                        {params ? (
                          <UpdateButton type="submit">
                            {loading ? "Updating..." : "Update"}
                          </UpdateButton>
                        ) : (
                          <SubmitButton type="submit">
                            {loading ? "Saving..." : "Save"}
                          </SubmitButton>
                        )}
                      </Stack>
                    </Box>
                  </Box>
                </Form>
              </FormikProvider>
            </Box>
          ) : null}
        </>
      }
    />
  );
};

export default CreateUpdateGrade;
