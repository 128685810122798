import React, { useState } from "react";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import {
  AddButton,
  CancelButton,
  SubmitButton,
  UpdateButton,
} from "../../../../mui/Buttons/Buttons";
import { useFormik, FormikProvider, Form } from "formik";
import { Box, Stack, TextField } from "@mui/material";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import {
  createMatrix,
  getAllMatrices,
  updateMatrix,
  getAllCourse,
} from "../../../../../redux/features/assessmentToolSlice";
import GlobalSelectField from "../../../../mui/GlobalSelectField";

const UpdateMatrix = ({ params }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { loading, courseData } = useSelector((state) => state.assessmentTool);
  const [open, setOpen] = useState(false);

  const Schema = Yup.object().shape({
    matrixName: Yup.string().required("matrixName is required"),
    createdBY: Yup.string().required("updatedBy is required"),
    matrixDescription: Yup.string(),
    matricesCouseId: Yup.string().required("matricesCouseId is required"),
  });
  const profile = JSON.parse(localStorage.getItem("cred")).profile;
  const formik = useFormik({
    enableReinitialize: open ? true : false,
    initialValues: open
      ? {
          matrixName: params ? params.matrix_name : "",
          createdBY: profile.userId,
          updatedBy: profile.userId,
          matrixDescription: params ? params.matrix_description : "",
          id: params ? params.matrix_id : "",
          matricesCouseId: params ? params.matrices_course_id : "",
        }
      : {},
    validationSchema: Schema,
    onSubmit: (values, { resetForm }) => {
      if (params) {
        dispatch(updateMatrix(values)).then((res) => {
          if (res.payload.status === "failed") {
            enqueueSnackbar(res.payload.message, {
              variant: "error",
            });
          } else if (res.payload.status === "success") {
            setOpen(false);
            resetForm();
            dispatch(getAllMatrices(profile?.departmentId));
            enqueueSnackbar(res.payload.message, {
              variant: "success",
            });
          }
        });
      } else {
        dispatch(createMatrix(values)).then((res) => {
          if (res.payload.status === "failed") {
            enqueueSnackbar(res.payload.message, {
              variant: "error",
            });
          } else if (res.payload.status === "success") {
            setOpen(false);
            resetForm();
            dispatch(getAllMatrices(profile?.departmentId));
            enqueueSnackbar(res.payload.message, {
              variant: "success",
            });
          }
        });
      }
    },
  });
  return (
    <GlobalDialog
      title={params ? "Update Matrix" : "Create Matrix"}
      open={open}
      setOpen={setOpen}
      fullWidth
      maxWidth={"sm"}
      closeButton={true}
      popupButton={
        <>
          {params ? (
            <UpdateButton
              size="small"
              disabled={params.has_child_element === 1 ? true : false}
              action={() => {
                // dispatch(getAllCourse());
                setOpen(true);
              }}
            >
              Update
            </UpdateButton>
          ) : (
            <AddButton
              size="large"
              action={() => {
                // dispatch(getAllCourse());
                setOpen(true);
              }}
            >
              Add Metrix
            </AddButton>
          )}
        </>
      }
      content={
        <>
          {open && Object.keys(formik.values).length != 0 ? (
            <Box>
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit}>
                  <Box>
                    <Stack direction={"column"} spacing={2}>
                      <GlobalSelectField
                        options={{
                          formik: formik,
                          label: "Course",
                          name: "matricesCouseId",
                          sx: {
                            width: "100%",
                            // "& .MuiInputBase-root": {
                            //     height: 50,
                            // },
                          },
                          data: courseData,

                          keys: {
                            id: "course_id",
                            value: "course_name",
                          },
                        }}
                      />
                      <TextField
                        name="matrixName"
                        label="Matrix Name"
                        onChange={formik.handleChange}
                        value={formik.values.matrixName}
                        fullWidth
                        autoComplete="off"
                        error={
                          formik.touched.matrixName &&
                          Boolean(formik.errors.matrixName)
                        }
                        helperText={formik.errors.matrixName}
                      />
                      <TextField
                        name="matrixDescription"
                        label="Description"
                        onChange={formik.handleChange}
                        value={formik.values.matrixDescription}
                        fullWidth
                        autoComplete="off"
                        error={
                          formik.touched.matrixDescription &&
                          Boolean(formik.errors.matrixDescription)
                        }
                        helperText={formik.errors.matrixDescription}
                      />
                    </Stack>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: 2,
                      }}
                    >
                      <Stack direction={"row"} spacing={1}>
                        <CancelButton action={() => setOpen(false)}>
                          Cancel
                        </CancelButton>
                        {params ? (
                          <UpdateButton type="submit">
                            {loading ? "Updating..." : "Update"}
                          </UpdateButton>
                        ) : (
                          <SubmitButton type="submit">
                            {loading ? "Saving..." : "Save"}
                          </SubmitButton>
                        )}
                      </Stack>
                    </Box>
                  </Box>
                </Form>
              </FormikProvider>
            </Box>
          ) : null}
        </>
      }
    />
  );
};

export default UpdateMatrix;
