import React, { useEffect, useState } from 'react'
import GlobalWrapper from '../../../../components/Wrapper/GlobalWrapper'
import useResponsive from '../../../../Hooks/useResponsive'
import FromAndToDatePicker from '../../../../components/mui/FromAndToDatePicker'
import { Box, Grid, Paper, Stack } from '@mui/material'
import GlobalMultiSelect from '../../../../components/mui/GlobalMultiSelect'
import { useDispatch, useSelector } from 'react-redux'
import DatePickerCustom from '../../../../components/DatePicker/DatePickerCustom'
import dayjs from 'dayjs'
import GlobalDatagrid from '../../../../components/mui/DataGrid/GlobalDatagrid'
import { employeeTableHeader } from '../../../../components/datagrid/hrDashboard/employeeTableHeader'
import { getAllDepartmenst } from '../../../../redux/features/postWorkingHoursSlice'
import { getEmployeeStatuses, getEmployeeTypes, totalEmployees } from '../../../../redux/features/hrDashboard'


const gender = [
  { id: 1, value: 'Male' },
  { id: 2, value: 'Female' },
]

const User = ({ dashboardView, open, genderFilter, employeTypeFilter, employeeStatusFilter }) => {
  const [direction, setDirection] = useState('row')
  const dispatch = useDispatch()
  const smDown = useResponsive('down', 'sm')
  const [departmentNames, setDepartmentNames] = useState([])
  const [genderNames, setGenderNames] = useState(genderFilter ? [genderFilter] : [])
  const [employeTypeName, setEmployeTypeName] = useState(employeTypeFilter ? [employeTypeFilter] : [])
  const [employeStatus, setEmployeStatus] = useState(employeeStatusFilter ? [employeeStatusFilter] : [])

  const { paginationData } = useSelector((state) => state.globalDataGrid)
  const { gridSize, pageNum, rowData, openAnchor, search } = paginationData
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const { departments } = useSelector((store) => store.postWorking)
  const { resData, loading, employeeStatuses, employeeTypes } = useSelector((store) => store.hrDashboard)
  const { data, meta } = resData
  const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'))
  const dispatchParams = {
    // selectedDate,
    paginationData,
    departmentNames,
    genderNames,
    employeTypeName,
    employeStatus,
  }


  // useEffect(() => {
  //   if (smDown) {
  //     setDirection('column')
  //   } else {
  //     setDirection('row')
  //   }
  // }, [smDown])

  useEffect(() => {
    if (departments.length == 0 || employeeStatuses.length == 0) {
      dispatch(getAllDepartmenst())
      dispatch(getEmployeeStatuses())
      dispatch(getEmployeeTypes())
    }
  }, [])

  useEffect(() => {
    if (paginationData?.pageNum) {
      dispatch(totalEmployees(dispatchParams))
    }
  }, [gridSize, pageNum, search, selectedDate, departmentNames, genderNames, employeTypeName, employeStatus])

  const content = () => {
    return (
      <>
        <Box sx={{ mb: 1 }}>
          <Grid container spacing={2}>
            {/* {date && ( */}
            {/* <Box>
              <DatePickerCustom
                value={dayjs(selectedDate)}
                setValue={(date) => setSelectedDate(date.format('YYYY-MM-DD'))}
                viewDetails={['year', 'month', 'day']}
                error={false}
              />
            </Box> */}
            {/* )} */}
            <Grid item xs={12} sm={6}  md={4} lg={3}>
              <Box>
                <GlobalMultiSelect
                  label={'Department Filter'}
                  fullWidth
                  arrayState={departmentNames}
                  arraySetState={setDepartmentNames}
                  data={departments}
                  keys={{ id: 'departmentId', value: 'departmentName' }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Box>
                <GlobalMultiSelect
                  disabled={genderFilter}
                  fullWidth
                  label={'Gender Filter'}
                  arrayState={genderNames}
                  arraySetState={setGenderNames}
                  data={gender}
                  keys={{ id: 'id', value: 'value' }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}  md={4} lg={3}>
              <Box>
                <GlobalMultiSelect
                  disabled={employeTypeFilter}
                  label={'Employee Type Filter'}
                  fullWidth
                  arrayState={employeTypeName}
                  arraySetState={setEmployeTypeName}
                  data={employeeTypes}
                  keys={{ id: 'id', value: 'value' }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Box>
                <GlobalMultiSelect
                  disabled={employeeStatusFilter}
                  label={'Employee Satus Filter'}
                  arrayState={employeStatus}
                  arraySetState={setEmployeStatus}
                  fullWidth
                  data={employeeStatuses}
                  keys={{ id: 'id', value: 'value' }}
                />
              </Box>
            </Grid>
            {/* {fromAndToDate && ( */}
            {/* <Box>
            <FromAndToDatePicker setFromDate={setFromDate} setToDate={setToDate} />
          </Box> */}
            {/* )} */}
          </Grid>
        </Box>
        <Box>
          <Paper>
            <GlobalDatagrid
              tableHead={employeeTableHeader}
              rows={data || []}
              meta={meta?.totalRecords}
              rowUniqueId="sl_no"
              loading={loading}
            />
          </Paper>
        </Box>
      </>
    )
  }

  if (dashboardView) {
    return content()
  } else {
    return <GlobalWrapper title={'User'}>{content()}</GlobalWrapper>
  }
}

export default User
