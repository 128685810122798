import { Box, Chip, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import GlobalDialog from "../../../../components/mui/Dialogue/GlobalDialog";
import { Icon } from "@iconify/react";
import {
  CancelButton,
  SaveButton,
} from "../../../../components/mui/Buttons/Buttons";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router-dom";
import {
  getCartItems,
  UpdateCartItems,
} from "../../../../redux/features/cartSlice";
import { useDispatch, useSelector } from "react-redux";

const UpdateAdmissionFee = ({
  item,
  handleInputChange,
  availableCartItems,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const { loading } = useSelector((state) => state.cart);
  const [adm, setadm] = useState("");
  const [admEvent, setadmEvent] = useState("");

  useEffect(() => {
    setadm(item.admissionFee);
  }, [item, open]);

  const handleSubmit = () => {
    // Check if there are availableCartItems to validate
    if (availableCartItems && availableCartItems.length > 0) {
      const isAvailable = availableCartItems.map((itemdata) => {
        if (itemdata.id == item.id) {
          itemdata.admissionFee = adm;
        }
      });

      let hasEmptyFields = false;
      availableCartItems.forEach((itemdata) => {
        if (
          !itemdata.MRP ||
          !itemdata.discount ||
          !itemdata.noOfSessions ||
          (!itemdata.paidAmt && itemdata.wishList == false)
        ) {
          enqueueSnackbar("Some fields are empty...!", {
            variant: "error",
          });
          setOpen(false);
          hasEmptyFields = true;
        }
      });

      if (hasEmptyFields) return;
    }
    const data = {
      leadId: location?.state.leadId,
      items: availableCartItems,
    };

    dispatch(UpdateCartItems(data)).then((res) => {
      if (res.payload.status === "error") {
        enqueueSnackbar(res.payload.message, { variant: "error" });
      } else if (res.payload.status === "success") {
        dispatch(getCartItems(data.leadId));
        setOpen(false);
        enqueueSnackbar(res.payload.message, {
          variant: "success",
        });
      }
    });
  };
  return (
    <Box>
      <GlobalDialog
        open={open}
        setOpen={setOpen}
        title={"Update Admission Fee"}
        fullWidth
        maxWidth={"sm"}
        closeButton={true}
        popupButton={
          <Chip
            variant="outlined"
            size="small"
            color="success"
            onClick={() => setOpen(true)}
            label={
              parseFloat(item.admissionFee) > 0
                ? `adm fee: ${item.admissionFee}`
                : `Add`
            }
            icon={
              parseFloat(item.admissionFee) > 0 ? (
                <Icon icon="mdi:approve" width="12" height="12" />
              ) : (
                <Icon
                  icon="material-symbols-light:add"
                  width="12"
                  height="12"
                />
              )
            }
            sx={{ height: 15, fontSize: 7 }}
          />
        }
        content={
          <>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Chip
                color="primary"
                variant="outlined"
                label={`Added Admission Fee: ${item.admissionFee}`}
              />
            </Box>
            <Box padding={3}>
              <TextField
                type="number"
                label="Admission Fee"
                fullWidth
                value={adm || ""}
                onChange={(e) => {
                  if (e.target.value >= 0 || e.target.value === "") {
                    setadm(e.target.value);
                  }
                  setadmEvent(e);
                }}
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Stack direction={"row"} spacing={2}>
                <CancelButton action={() => setOpen(false)}>
                  Cancel
                </CancelButton>
                <SaveButton
                  disabled={adm ? false : true}
                  size={"small"}
                  action={handleSubmit}
                >
                  {loading ? "Saving...!" : "Save"}
                </SaveButton>
              </Stack>
            </Box>
          </>
        }
      />
    </Box>
  );
};

export default UpdateAdmissionFee;
