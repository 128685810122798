import { Box } from '@mui/material'
import ToolTip from '../../../ToolTip/ToolTip'
import dayjs from 'dayjs'
import { Icon } from '@iconify/react/dist/iconify.js'

export const postWorkingTableHeader = (data) => {
  const headers = [
    { field: 'sl_no', headerName: 'Serial No', width: 80 },
    { field: 'date', headerName: 'Date', width: 150 },
    { field: 'createdBy', headerName: 'Employee Name', width: 220 },
    {
      field: 'time',
      headerName: 'Working Hours',
      width: 200,
      renderCell: (params) => {
        const [start, end] = params.row.time.split('-')
        const formattedStart = dayjs(start, 'HH:mm:ss').format('h:mm A')
        const formattedEnd = dayjs(end, 'HH:mm:ss').format('h:mm A')
        return `${formattedStart} To ${formattedEnd}`
      },
    },
    ...(Array.isArray(data) && data?.some((row) => row?.dept)
      ? [
          { field: 'dept', headerName: 'Department', width: 250 },
          { field: 'designation', headerName: 'Designation', width: 250 },
          { field: 'ApprovedBy', headerName: 'Approved By', width: 150 }
        ]
      : []),

    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderCell: (params) => {
        if (params.row.status == 'Pending') {
          return (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Icon
                icon="material-symbols:pending"
                width="20"
                height="20"
                style={{
                  color: ' #f1f139',
                  marginRight: '4px',
                }}
              />
              Pending
            </Box>
          )
        } else if (params.row.status == 'Approved') {
          return (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Icon
                icon="icon-park-twotone:success"
                width="20"
                height="20"
                style={{
                  color: ' #2acc1e',
                  marginRight: '4px',
                }}
              />
              Approved
            </Box>
          )
        } else if (params.row.status == 'Rejected') {
          return (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Icon
                icon="fluent:text-change-reject-20-filled"
                width="20"
                height="20"
                style={{ color: '#eb0000', marginRight: '4px' }}
              />
              Rejected
            </Box>
          )
        } else {
          return params.row.status
        }
      },
    },
    {
      field: 'remarks',
      headerName: 'Remarks',
      width: 250,
      renderCell: (params) => {
        return <ToolTip expand={params.row.remarks} content={params.row.remarks} />
      },
    },
  ]

  return headers
}
