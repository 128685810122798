import React, {useState } from 'react'
import GlobalBulkDialog from '../../../../components/mui/Dialogue/GlobalBulkDialog'
import { savePaginationData } from '../../../../redux/features/globalDatagridSlice'
import { PostWorkingApproveOrReject } from '../../../../redux/features/postWorkingHoursSlice'
import { useSelector } from 'react-redux'
import { BulkApprove } from '../../../../components/mui/Buttons/Buttons'
import { Typography } from '@mui/material'
import useResponsive from '../../../../Hooks/useResponsive'

const PostWorkingBulkApprove = ({ upDateCall, checkedRowsIds,titleNote,disabled }) => {
  const { paginationData } = useSelector((state) => state.globalDataGrid)
  const [open, setOpen] = useState(false)
  const { loading } = useSelector((store) => store.postWorking)
  const smDown = useResponsive("down", "sm");
  return (
    <GlobalBulkDialog
      disabled={disabled}
      openModal={open}
      setOpen={setOpen}
      title={<>
      <Typography variant='h6'>Bulk Approve</Typography>
      <Typography variant="body1">{titleNote}</Typography>
      </>}
      dialogNote={'Are you sure you want to bulk-approve the selected rows?'}
      api={PostWorkingApproveOrReject({ ids: checkedRowsIds, status: 1 })}
      upDateCall={upDateCall}
      updatePaginationCall={savePaginationData({ ...paginationData, openAnchor: false })}
      loading={loading}
      popUpButton={
        <BulkApprove size={smDown?"small":"large"} action={() => setOpen(true)}>
          Bulk Approve
        </BulkApprove>
      }
    />
  )
}

export default PostWorkingBulkApprove
