import React, { useEffect, useState } from 'react'
import GlobalWrapper from '../../../../components/Wrapper/GlobalWrapper'
import GlobalMultiSelect from '../../../../components/mui/GlobalMultiSelect'
import { Box, Paper } from '@mui/material'
import GlobalDatagrid from '../../../../components/mui/DataGrid/GlobalDatagrid'
import DatePickerCustom from '../../../../components/DatePicker/DatePickerCustom'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { getAllDepartmenst } from '../../../../redux/features/postWorkingHoursSlice'
import { presentTableHeader } from '../../../../components/datagrid/hrDashboard/presentTableHeader'
import { useDispatch } from 'react-redux'

const Present = () => {
  const dispatch = useDispatch()
  const [departmentNames, setDepartmentNames] = useState([])
  const { departments } = useSelector((store) => store.postWorking)
  const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'))
  const { paginationData } = useSelector((state) => state.globalDataGrid)
  const { gridSize, pageNum, rowData, openAnchor, search } = paginationData
  const { resData, loading, employeeStatuses, employeeTypes } = useSelector((store) => store.hrDashboard)
  const { data, meta } = resData
  useEffect(() => {
    dispatch(getAllDepartmenst())
  }, [])
  const dispatchParams = {
    selectedDate,
    departmentNames,
    paginationData,
  }
  return (
    <GlobalWrapper title={'Present'}>
      <Box sx={{display:"flex",gap:1}}>
      <Box>
        <DatePickerCustom
          value={dayjs(selectedDate)}
          setValue={(date) => setSelectedDate(date.format('YYYY-MM-DD'))}
          viewDetails={['year', 'month', 'day']}
          error={false}
        />
      </Box>
      <Box>
        <GlobalMultiSelect
          label={'Department Filter'}
          sx={{ maxWidth: '600px' }}
          arrayState={departmentNames}
          arraySetState={setDepartmentNames}
          data={departments}
          keys={{ id: 'departmentId', value: 'departmentName' }}
        />
      </Box>
      </Box>
      <Box sx={{mt:1}}>
        <Paper>
          <GlobalDatagrid
            tableHead={presentTableHeader}
            rows={data || []}
            meta={meta?.totalRecords}
            rowUniqueId="sl_no"
            loading={loading}
          />
        </Paper>
      </Box>
    </GlobalWrapper>
  )
}

export default Present
