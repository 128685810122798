import React, { useEffect, useState } from 'react'
import GlobalDialog from '../../../components/mui/Dialogue/GlobalDialog'
import { Box, Button, Typography } from '@mui/material'
import { Icon } from '@iconify/react/dist/iconify.js'
import { useDispatch, useSelector } from 'react-redux'
import { getEmployeeDetailsUnderHead, totalEmployees } from '../../../redux/features/hrDashboard'
import { ProfileUserCard, ProfileUserCardSkeleton } from './ProfileUserCard'
const DepartmentWingEmployeesModal = ({ deptName, wingName }) => {
  //HERE WE ARE GETTING DEPAT OR WING DETAILS,WE JUST FILTERING THE NAMES WIHT IN THE SAME API CALL
  const { loading, employeDetails, resData } = useSelector((store) => store.hrDashboard)
  const { data, meta } = resData
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()
  const [pageNumber, setPageNumber] = useState(1)
  const dispatchParams = {
    departmentNames: deptName ? [deptName] : [],
    wingNames: wingName ? [wingName] : [],
    paginationData: {
      search: '',
      gridSize: 10,
      pageNum: pageNumber,
    },
  }
  useEffect(() => {
    if (open) {
      dispatch(totalEmployees(dispatchParams))
    }
  }, [open, pageNumber])

  return (
    <GlobalDialog
      title={'Employee Details'}
      open={open}
      setOpen={setOpen}
      closeButton={true}
      fullWidth
      maxWidth={'xl'}
      popupButton={
        <>
          <Button
            sx={{
              color: 'white',
              backgroundColor: 'black',
              border: '1px solid black',
              '&:hover': { backgroundColor: 'white', color: 'black' },
            }}
            size="small"
            onClick={() => setOpen(true)}
            variant="text">
            <Box sx={{ display: 'flex', gap: 1, px: '2px', alignItems: 'center' }}>
              <Typography variant="button">Employees</Typography>
              <Icon icon="ion:people" width="25" height="25" style={{ color: 'green' }} />
            </Box>
          </Button>
        </>
      }
      content={
        <>
          {loading ? (
            <ProfileUserCardSkeleton />
          ) : (
            <Box>
              <ProfileUserCard usersData={data} />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  {meta.hasPrevPage && (
                    <Button variant="outlined" size="medium" onClick={() => setPageNumber((prev) => prev - 1)}>
                      Previous
                    </Button>
                  )}
                  {meta.hasNextPage && (
                    <Button variant="outlined" size="medium" onClick={() => setPageNumber((prev) => prev + 1)}>
                      Next
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
          )}
        </>
      }
    />
  )
}

export default DepartmentWingEmployeesModal
