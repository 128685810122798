import { Avatar, Box, Button, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import profileImg from "./profileimg.jpg";
import { Icon } from "@iconify/react";
import IconPaper from "./IconPaper";
import dayjs from "dayjs";
import { StudentsDetailstable } from "./StudentDetailsList";
import { useDispatch, useSelector } from "react-redux";
import { getLeadDetails } from "../../../../redux/features/cartSlice";
import ToolTip from "../../../../components/ToolTip/ToolTip";
import Loader3D from "../../../../components/Loader/Loader3D";
import { useLocation } from "react-router-dom";

import InduvidualAssessmentReport from "../../../assessmentReportInduvidual/InduvidualAssessmentReport";
const StudentProfile = () => {
  const location = useLocation();
  const { leadDetails, leadDetailsLoading } = useSelector(
    (state) => state.cart
  );

  return (
    <Box>
      <Paper elevation={2} sx={{ borderRadius: 3 }}>
        {leadDetailsLoading ? (
          <Loader3D />
        ) : (
          <>
            {" "}
            <Box padding={2}>
              <Typography
                variant="body2"
                sx={{ fontWeight: "bolder", fontSize: 12 }}
              >
                Student Profile
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box
                sx={{
                  width: 64,
                  height: 64,
                  borderRadius: "50%",
                  background: `linear-gradient(to bottom, #702dff 0%, #702dff 10%, white 100%)`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Avatar
                  alt="studentimg"
                  src={profileImg}
                  sx={{ width: 56, height: 56, border: "2px solid white" }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box mt={1}>
                <Stack direction={"column"} spacing={0}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography sx={{ fontSize: 15, fontWeight: "bolder" }}>
                      {leadDetails?.name || "Not Available..!"}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        opacity: 0.5,
                      }}
                      variant="caption"
                    >
                      {leadDetails?.place || "Not Available"}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            </Box>
            {/* ----------------------------------------------------------------------- */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 2,
              }}
            >
              <Stack direction={"row"} spacing={2}>
                <ToolTip
                  action={() => {
                    if (leadDetails) {
                      window.open(
                        `https://wa.me/${leadDetails.contactNo}`,
                        "_blank"
                      );
                    }
                  }}
                  content={
                    <IconPaper>
                      <Icon
                        icon="ic:baseline-whatsapp"
                        height={23}
                        width={23}
                      />
                    </IconPaper>
                  }
                  expand={
                    leadDetails ? leadDetails.contactNo : "Not Available..!"
                  }
                />

                <ToolTip
                  content={
                    <IconPaper>
                      <Icon
                        icon="ic:outline-alternate-email"
                        height={23}
                        width={23}
                      />
                    </IconPaper>
                  }
                  expand={leadDetails?.email || "Not Available..!"}
                />
                <ToolTip
                  content={
                    <IconPaper>
                      <Icon
                        icon="material-symbols:school-outline"
                        height={23}
                        width={23}
                      />
                    </IconPaper>
                  }
                  expand={leadDetails?.schoolName || "Not Available..!"}
                />
              </Stack>
            </Box>
            {/* -----------------------------details------------------------------------------ */}
            <Box>
              <Box padding={2}>
                <Typography sx={{ fontSize: 11, fontWeight: "bolder" }}>
                  More Details
                </Typography>
              </Box>
              <Box>
                <StudentsDetailstable data={leadDetails} />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 2,
              }}
            >
              <InduvidualAssessmentReport
                leadId={location?.state.leadId}
                btnIcon={
                  <Icon icon="mdi:file-report-outline" height={22} width={22} />
                }
                buttonName={" View Assessment Report"}
                sx={{
                  color: "white",
                  background: `linear-gradient(to bottom, #0089d0 0%, #0089d0 10%, #a125c2 100%)`,
                  borderRadius: 3,
                }}
              />
            </Box>
          </>
        )}
      </Paper>
    </Box>
  );
};

export default StudentProfile;
