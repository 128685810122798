export const ticketTableHeader=[
    { field: 'sl_no', headerName: 'Serial No', width: 80 },
    { field: 'studentName', headerName: 'Student Name', width: 250 },
    { field: 'departmentName', headerName: 'Department Name', width: 250 },
    { field: 'teamHeadName', headerName: 'Team Head', width: 250 },
    { field: 'status', headerName: 'Status', width: 150 },
    { field: 'reason', headerName: 'Reason', width: 150 },
    { field: 'teamHeadRemarks', headerName: 'Team Head Remarks', width: 150 },  
    { field: 'assignedToName', headerName: 'Assigned To', width: 150 },
    { field: 'assigneeRemarks', headerName: 'Assignee Remarks', width: 150 },    
    { field: 'verification', headerName: 'Verification Status', width: 150 },    
]