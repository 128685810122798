import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import HrDashboard from "../../views/user/Dashboard/hr/IndexDashoard";
import NewHrDashboard from "../../views/Dashboard/hrDashboard/NewHrDashboard";

const DashboardRouter = () => {
    const { navChild } = useSelector((state) => state.role);
    
    return (
        <Routes>
            <Route path="/" element={<Navigate to={navChild[0]} />} />
            <Route path="/analytics" element={<NewHrDashboard />} />
            <Route path="/recruitment" element={<HrDashboard />} />     
        </Routes>
    );
};

export default DashboardRouter;
