import React, { useEffect, useState } from 'react'
import GlobalDialog from '../../../components/mui/Dialogue/GlobalDialog'
import { AddButton } from '../../../components/mui/Buttons/Buttons'
import { Box, Button, Grid, Typography } from '@mui/material'
import { Icon } from '@iconify/react/dist/iconify.js'
import DepartmentCard from './DepartmentCard'
import DepartmentWingEmployeesModal from './DepartmentWingEmployeesModal'

const DepartmentPopup = ({ wings }) => {
  const [open, setOpen] = useState(false)

  return (
    <GlobalDialog
      title={'Department Details'}
      open={open}
      setOpen={setOpen}
      closeButton={true}
      fullWidth
      maxWidth={'lg'}
      popupButton={
        <>
          <Button
            sx={{
              color: 'white',
              backgroundColor: 'black',
              border: '1px solid black',
              '&:hover': {backgroundColor: 'white',color:"black" },
            }}
            size="small"
            onClick={() => setOpen(true)}
            variant="text">
            <Box sx={{ display: 'flex', gap: 1, px: '2px', alignItems: 'center' }}>
              <Typography variant="button">Wings</Typography>
              <Icon icon="gravity-ui:branches-down" width="25" height="25" style={{ color: 'green' }} />
            </Box>
          </Button>
        </>
      }
      content={
        <>
          <Box sx={{ mt: 4 }}>
            <Grid container spacing={3}>
              {wings?.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                  <DepartmentCard
                    title={item?.wingName}
                    reportingHead={item?.wingHead}
                    count={item?.count}
                    poupButton={
                      <>
                        <DepartmentWingEmployeesModal wingName={item?.wingName}/>
                      </>
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </>
      }
    />
  )
}

export default DepartmentPopup
