import {
  Alert,
  Box,
  Chip,
  Divider,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useSnackbar } from "notistack";
import ActionMenu from "./ActionMenu";
import { useDispatch } from "react-redux";
import { deleteCart } from "../../../../redux/features/cartSlice";
import useResponsive from "../../../../Hooks/useResponsive";
import MotionContainer from "../../../../components/FramerMotion/MotionContainer";
import { Icon } from "@iconify/react";
import UpdateAdmissionFee from "./UpdateAdmissionFee";

const IsAvailableCartItems = ({
  totalDiscountedAmount,
  setTotalDiscountedAmount,
  setTotalDiscount,
  setTotalAmount,
  setTotalPayingAmount,
  availableCartItems,
  setAvailableCartItems,
  setIsAvailableTotalPayingAmount,
  setIsVailableFilterCourse,
  setAvailablePayingAmountValidation,
  setAvailableDiscountValidation,
  setAvailableZeroValidation,
  availablePayingAmountValidation,
  availableDiscountValidation,
  availableZeroValidation,
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");

  // const [filterCourseData, setFilterCourse] = useState([]);

  // Handle deleting a course
  const handleDelete = (courseId, cartItemId) => {
    dispatch(deleteCart(cartItemId)).then((res) => {
      if (res.payload.status === "failed") {
        enqueueSnackbar(res.payload.message, { variant: "error" });
      } else if (res.payload.status === "success") {
        const updatedCourses = availableCartItems?.filter(
          (item) => item.id != courseId
        );

        setAvailableCartItems(updatedCourses);
        enqueueSnackbar(res.payload.message, {
          variant: "success",
        });
      }
    });
  };

  // useEffect(() => {
  //   if (availableCartItems) {
  //     const filterData = availableCartItems?.filter(
  //       (item) =>
  //         (item.CartPaymentLogs.length == 0 && item.wishList == false) ||
  //         (item.wishList == false &&
  //           item.wishList == 0 &&
  //           item.CartPaymentLogs[0]?.invoiceId == null &&
  //           item.CartPaymentLogs[0]?.paidStatus == 0)
  //     );

  //     setIsVailableFilterCourse(filterData);
  //     setFilterCourse(filterData);
  //   }
  // }, [availableCartItems]);
  const filterCourseData = useMemo(
    () =>
      availableCartItems?.filter(
        (item) =>
          (item.CartPaymentLogs.length == 0 && item.wishList == false) ||
          (item.wishList == false &&
            item.CartPaymentLogs[0]?.invoiceId == null &&
            item.CartPaymentLogs[0]?.paidStatus == 0)
      ),
    [availableCartItems]
  );

  useEffect(() => {
    setIsVailableFilterCourse(filterCourseData || []);
  }, [filterCourseData]);
  useEffect(() => {
    if (filterCourseData) {
      const validation = filterCourseData.some(
        (data) =>
          (data.noOfSessions && data.noOfSessions == 0) ||
          (data.paidAmt && data.paidAmt == 0) ||
          (data.MRP && data.MRP == 0)
      );

      if (validation) {
        setAvailableZeroValidation(true);
      } else {
        setAvailableZeroValidation(false);
      }
    }

    const discountValidations = filterCourseData.some(
      (item) =>
        item.MRP &&
        parseFloat(item.MRP) <= item.discount &&
        parseFloat(item.discount)
    );

    if (discountValidations) {
      setAvailableDiscountValidation(true);
    } else {
      setAvailableDiscountValidation(false);
    }
    const payingValidation = filterCourseData.some(
      (item) =>
        item.discountedAmount &&
        parseFloat(item.discountedAmount) < item.paidAmt &&
        parseFloat(item.paidAmt)
    );
    if (payingValidation) {
      setAvailablePayingAmountValidation(true);
    } else {
      setAvailablePayingAmountValidation(false);
    }
  }, [filterCourseData]);
  // useEffect(() => {
  //   if (filterCourseData) {
  //     const sum = filterCourseData.reduce(
  //       (acc, course) => acc + (parseInt(course.discountedAmount) || 0),
  //       0
  //     );
  //     const totalDiscount = filterCourseData.reduce(
  //       (acc, course) => acc + (parseInt(course.discount) || 0),
  //       0
  //     );
  //     const totalAmount = filterCourseData.reduce(
  //       (acc, course) => acc + (parseInt(course.MRP) || 0),
  //       0
  //     );
  //     const totalPayingAmount = filterCourseData.reduce(
  //       (acc, course) => acc + (parseInt(course.paidAmt) || 0),
  //       0
  //     );
  //     setIsAvailableTotalPayingAmount(totalPayingAmount);
  //     setTotalPayingAmount(totalPayingAmount);
  //     setTotalAmount(totalAmount);
  //     setTotalDiscount(totalDiscount);
  //     setTotalDiscountedAmount(sum);
  //   }
  // }, [filterCourseData]);

  const calculations = useMemo(() => {
    const sum = filterCourseData.reduce(
      (acc, course) => acc + (parseInt(course.discountedAmount) || 0),
      0
    );
    const totalDiscount = filterCourseData.reduce(
      (acc, course) => acc + (parseInt(course.discount) || 0),
      0
    );
    const totalAmount = filterCourseData.reduce(
      (acc, course) => acc + (parseInt(course.MRP) || 0),
      0
    );
    const totalPayingAmount = filterCourseData.reduce(
      (acc, course) => acc + (parseInt(course.paidAmt) || 0),
      0
    );
    return { sum, totalDiscount, totalAmount, totalPayingAmount };
  }, [filterCourseData]);
  useEffect(() => {
    setIsAvailableTotalPayingAmount(calculations.totalPayingAmount);
    setTotalPayingAmount(calculations.totalPayingAmount);
    setTotalAmount(calculations.totalAmount);
    setTotalDiscount(calculations.totalDiscount);
    setTotalDiscountedAmount(calculations.sum);
  }, [calculations]);
  const handleInputChange = useCallback(
    (e, courseId, field) => {
      const { value, type, checked } = e.target;
      setAvailableCartItems((prevCourses) =>
        prevCourses.map((item) => {
          if (item.id === courseId) {
            if (
              item.id === courseId &&
              type === "checkbox" &&
              (item.MRP == "" || item.discount == "" || item.noOfSessions == "")
            ) {
              enqueueSnackbar("Some fields are empty...!", {
                variant: "error",
              });
              return item;
            }
            let updatedItem = {
              ...item,
              [field]: type === "checkbox" ? checked : value,
            };

            if (field === "MRP" || field === "discount") {
              const amount = field === "MRP" ? value : item.MRP || 0;
              const discount =
                field === "discount" ? value : item.discount || 0;
              const discountedAmount = amount - discount;

              updatedItem = { ...updatedItem, discountedAmount };
            }
            if (field === "admissionFee") {
              updatedItem = { ...updatedItem, admissionFee: parseFloat(value) };
            }
            return updatedItem;
          }
          return item;
        })
      );

      // const updatedCourses = availableCartItems.map((item) => {
      //   if (item.id === courseId) {
      //     if (
      //       item.id === courseId &&
      //       type === "checkbox" &&
      //       (item.MRP == "" || item.discount == "" || item.noOfSessions == "")
      //     ) {
      //       enqueueSnackbar("Some fields are empty...!", {
      //         variant: "error",
      //       });
      //       return item;
      //     }
      //     let updatedItem = {
      //       ...item,
      //       [field]: type === "checkbox" ? checked : value,
      //     };

      //     if (field === "MRP" || field === "discount") {
      //       const amount = field === "MRP" ? value : item.MRP || 0;
      //       const discount = field === "discount" ? value : item.discount || 0;
      //       const discountedAmount = amount - discount;

      //       updatedItem = { ...updatedItem, discountedAmount };
      //     }
      //     if (field === "admissionFee") {
      //       updatedItem = { ...updatedItem, admissionFee: checked ? 500 : 0 };
      //     }

      //     return updatedItem;
      //   }
      //   return item;
      // });

      // setAvailableCartItems(updatedCourses);
    },
    [setAvailableCartItems]
  );
  // const handleInputChange = (e, courseId, field) => {
  //   const { value, type, checked } = e.target;
  //   const updatedCourses = availableCartItems.map((item) => {
  //     if (item.id === courseId) {
  //       if (
  //         item.id === courseId &&
  //         type === "checkbox" &&
  //         (item.MRP == "" || item.discount == "" || item.noOfSessions == "")
  //       ) {
  //         enqueueSnackbar("Some fields are empty...!", {
  //           variant: "error",
  //         });
  //         return item;
  //       }
  //       let updatedItem = {
  //         ...item,
  //         [field]: type === "checkbox" ? checked : value,
  //       };

  //       if (field === "MRP" || field === "discount") {
  //         const amount = field === "MRP" ? value : item.MRP || 0;
  //         const discount = field === "discount" ? value : item.discount || 0;
  //         const discountedAmount = amount - discount;

  //         updatedItem = { ...updatedItem, discountedAmount };
  //       }
  //       if (field === "admissionFee") {
  //         updatedItem = { ...updatedItem, admissionFee: checked ? 500 : 0 };
  //       }

  //       return updatedItem;
  //     }
  //     return item;
  //   });

  //   setAvailableCartItems(updatedCourses);
  // };

  return (
    <Box>
      <Typography variant="h6">Added Courses</Typography>
      <Divider sx={{ borderStyle: "dashed", borderBottomWidth: 2 }} />
      <AnimatePresence>
        {filterCourseData?.map((item) => {
          return (
            <motion.div
              key={item.id}
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -50 }}
              transition={{ duration: 0.5 }}
            >
              <Paper
                sx={{
                  backgroundColor: "#edf2fb",
                  mt: 3,
                  padding: 2,
                  borderRadius: 3,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Stack direction={mdUp ? "row" : "column"} spacing={2}>
                  <Box sx={{ maxWidth: 100, minWidth: 70 }}>
                    <img
                      style={{ borderRadius: 15 }}
                      src={
                        "https://campustechnology.com/-/media/EDU/CampusTechnology/2019-Images/20191209online.jpg"
                      }
                      height={60}
                      width={60}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: 12, fontWeight: "bolder" }}>
                        {item.courseName.replace(/[_-]/g, " ")}
                      </Typography>
                    </Box>
                  </Box>

                  {/* Text fields for session count, amount, discount, and discounted amount */}
                  <motion.div
                    initial={{ opacity: 0, x: -30 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -20 }}
                    transition={{ duration: 0.5 }}
                  >
                    <TextField
                      type="number"
                      onWheel={(event) => event.target.blur()}
                      InputProps={{ inputProps: { min: 0 } }}
                      label="no: of sessions"
                      value={item.noOfSessions || ""}
                      onChange={(e) => {
                        const value = e.target.value;
                        // Validate for non-negative integers only
                        if (/^\d*$/.test(value)) {
                          handleInputChange(e, item.id, "noOfSessions");
                        }
                      }}
                      sx={{
                        // width: "150px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "30px",
                          padding: "0px",
                        },
                        "& .MuiOutlinedInput-input": {
                          height: "30px",
                          padding: "5px 10px",
                          fontSize: "0.8rem",
                        },
                        "& .MuiInputLabel-root": {
                          fontSize: "0.8rem",
                          top: "-5px",
                        },
                        "& .MuiInputLabel-shrink": {
                          top: "0",
                        },
                      }}
                    />
                  </motion.div>
                  <motion.div
                    initial={{ opacity: 0, x: -30 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -20 }}
                    transition={{ duration: 0.7 }}
                  >
                    <TextField
                      label="Amount"
                      type="number"
                      onWheel={(event) => event.target.blur()}
                      InputProps={{ inputProps: { min: 0 } }}
                      value={item.MRP || ""}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value >= 0 || value === "") {
                          handleInputChange(e, item.id, "MRP");
                        }
                      }}
                      sx={{
                        // width: "150px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "30px",
                          padding: "0px",
                        },
                        "& .MuiOutlinedInput-input": {
                          height: "30px",
                          padding: "5px 10px",
                          fontSize: "0.8rem",
                        },
                        "& .MuiInputLabel-root": {
                          fontSize: "0.8rem",
                          top: "-5px",
                        },
                        "& .MuiInputLabel-shrink": {
                          top: "0",
                        },
                      }}
                    />
                  </motion.div>
                  <motion.div
                    initial={{ opacity: 0, x: -30 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -20 }}
                    transition={{ duration: 0.5 }}
                  >
                    <TextField
                      label="Discount"
                      type="number"
                      onWheel={(event) => event.target.blur()}
                      InputProps={{ inputProps: { min: 0 } }}
                      value={item.discount || ""}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value >= 0 || value === "") {
                          handleInputChange(e, item.id, "discount");
                        }
                      }}
                      sx={{
                        // width: "150px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "30px",
                          padding: "0px",
                        },
                        "& .MuiOutlinedInput-input": {
                          height: "30px",
                          padding: "5px 10px",
                          fontSize: "0.8rem",
                        },
                        "& .MuiInputLabel-root": {
                          fontSize: "0.8rem",
                          top: "-5px",
                        },
                        "& .MuiInputLabel-shrink": {
                          top: "0",
                        },
                      }}
                    />
                  </motion.div>
                  <motion.div
                    initial={{ opacity: 0, x: -30 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -20 }}
                    transition={{ duration: 0.5 }}
                  >
                    <TextField
                      label="Discounted Amount"
                      disabled
                      type="number"
                      onWheel={(event) => event.target.blur()}
                      value={item.discountedAmount || ""}
                      onChange={(e) =>
                        handleInputChange(e, item.id, "discountedAmount")
                      }
                      sx={{
                        // width: "150px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "30px",
                          padding: "0px",
                        },
                        "& .MuiOutlinedInput-input": {
                          height: "30px",
                          padding: "5px 10px",
                          fontSize: "0.8rem",
                        },
                        "& .MuiInputLabel-root": {
                          fontSize: "0.8rem",
                          top: "-5px",
                        },
                        "& .MuiInputLabel-shrink": {
                          top: "0",
                        },
                      }}
                    />

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        pt: 0.5,
                      }}
                    >
                      <UpdateAdmissionFee
                        handleInputChange={handleInputChange}
                        item={item}
                        availableCartItems={availableCartItems}
                      />
                    </Box>
                  </motion.div>
                  {/* </Stack>
                    </Box> */}
                  <motion.div
                    initial={{ opacity: 0, x: -30 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -20 }}
                    transition={{ duration: 0.9 }}
                  >
                    <TextField
                      label="paying Amount"
                      type="number"
                      onWheel={(event) => event.target.blur()}
                      InputProps={{ inputProps: { min: 0 } }}
                      value={item.paidAmt || ""}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value >= 0 || value === "") {
                          handleInputChange(e, item.id, "paidAmt");
                        }
                      }}
                      sx={{
                        // width: "150px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "30px",
                          padding: "0px",
                        },
                        "& .MuiOutlinedInput-input": {
                          height: "30px",
                          padding: "5px 10px",
                          fontSize: "0.8rem",
                        },
                        "& .MuiInputLabel-root": {
                          fontSize: "0.8rem",
                          top: "-5px",
                        },
                        "& .MuiInputLabel-shrink": {
                          top: "0",
                        },
                      }}
                    />
                  </motion.div>
                  {/* ------------------------------action-menu-------------/ */}

                  <ActionMenu
                    totalCourses={availableCartItems}
                    setAvailableCartItems={setAvailableCartItems}
                    item={item}
                    update={true}
                    handleDelete={handleDelete}
                    handleInputChange={handleInputChange}
                  />
                  {/* ------------------- */}
                </Stack>
              </Paper>
            </motion.div>
          );
        })}
      </AnimatePresence>
      <Box pt={2}>
        {availableZeroValidation ? (
          <MotionContainer delay={0.5}>
            <Alert severity="error">Fields cannot be zero...!</Alert>
          </MotionContainer>
        ) : null}
      </Box>
      <Box pt={2}>
        {availableDiscountValidation ? (
          <MotionContainer delay={0.5}>
            <Alert severity="error">
              Discount amount must not be greater than or equal to the course
              amount...!
            </Alert>
          </MotionContainer>
        ) : null}
      </Box>
      <Box pt={2}>
        {availablePayingAmountValidation ? (
          <MotionContainer delay={0.5}>
            <Alert severity="error">
              Paying amount must not be greater than or equal to the discouted
              course amount...!
            </Alert>
          </MotionContainer>
        ) : null}
      </Box>
    </Box>
  );
};

export default IsAvailableCartItems;
