import React from 'react'
import { Route, Routes } from "react-router-dom";
import StudentPortalDashboard from '../../views/StudentPortal/StudentPortalDashboard';
import Tickets from '../../views/StudentPortal/Tickets/Admin/Tickets';
import HeadOrAssigneeTickets from '../../views/StudentPortal/Tickets/HeadOrAssignee/HeadOrAssigneeTickets';
const StudentPortalRouter = () => {
  return (
    <Routes>
    <Route path="dashboard" element={<StudentPortalDashboard />} />
    <Route path="tickets" element={<Tickets/>} />
    <Route path="assigned-tickets" element={<HeadOrAssigneeTickets />} />
  </Routes>
  )
}

export default StudentPortalRouter