import React, { useEffect, useState } from 'react'
import GlobalDialog from '../../../../components/mui/Dialogue/GlobalDialog'
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  TextField,
} from '@mui/material'
import { AddButton, SubmitButton } from '../../../../components/mui/Buttons/Buttons'
import DatePickerCustom from '../../../../components/DatePicker/DatePickerCustom'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import {
  addFood,
  ClearFoodCategoryChangeDispatch,
  foodCategoryChangeDispatch,
  getAllUsers,
  getEveningFoodList,
  getFoodList,
  getFoodSlots,
  getFoodStatus,
} from '../../../../redux/features/foodSlice'
import { useSnackbar } from 'notistack'
import useResponsive from '../../../../Hooks/useResponsive'
import Loader3D from '../../../../components/Loader/Loader3D'

const AddFood = ({ parentDate, parentSetState, parentFoodCatId, parentSetFoodCat }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [open, setOpen] = useState(false)
  const [selectedFoodSlot, setSelectedFoodSlot] = useState('')
  const [selectedTimeSlot, setSelectedTimeSlot] = useState('')
  const [selectedOfficeSlot, setSelectedOfficeSlot] = useState(6)
  const [selectedUserId, setSelectedUserId] = useState('')
  const [timingSlots, setTimingSlots] = useState([])
  const [isNonVegDay, setIsNonVegDay] = useState(false)
  const [vegReq, setVegReq] = useState(false)
  const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'))
  const dispatch = useDispatch()
  const { loading, lunchslots, eveningSlots, foodStatus ,users} = useSelector((store) => store.food)
  const [direction, setDirection] = useState('row')
  const mdDown = useResponsive('down', 'md')

  const foodSlots = [
    { id: 1, value: 'Lunch' },
    { id: 2, value: 'Evening' },
  ]
  const offices = [
    { id: 6, value: 'Pathanapuram' },
    { id: 7, value: 'Office 2' },
  ]

  useEffect(() => {
    if (mdDown) {
      setDirection('column')
    } else {
      setDirection('row')
    }
  }, [mdDown])

  const handleSlotChange = (e) => {
    if (e.target.name == 'foodSolt') {
      setSelectedFoodSlot(e.target.value)
    } else if (e.target.name == 'timeSlots') {
      setSelectedTimeSlot(e.target.value)
    } else if (e.target.name == 'offices') {
      setSelectedOfficeSlot(e.target.value)
    } else if (e.target.name == 'vegRequired') {
      setVegReq(e.target.checked)
    }
  }

  const handleSubmit = () => {
    const data = {
      user_id: selectedUserId,
      foodDate: selectedDate,
      foodOffice: selectedOfficeSlot,
      foodSlotId: selectedTimeSlot,
      foodCategory: vegReq ? 2 : 1,
    }
    dispatch(addFood(data)).then((res) => {
      if (res.payload.status == 'failed' || res.payload.status === 'error') {
        enqueueSnackbar(`${res.payload.message}`, { variant: 'error' })
      } else {
        enqueueSnackbar(` ${res.payload.message}`, {
          variant: 'success',
        })
        setOpen(false)
        if (selectedFoodSlot == 1) {
          //LUNCH SUCCESS ACTION
          parentSetFoodCat(1)
          parentSetState(selectedDate)
          if (selectedFoodSlot == parentFoodCatId && parentDate == selectedDate) {
            dispatch(getFoodList({ date: selectedDate })) //ONLY HERE DISPATCH IF DATE AND FOOD CAT ARE SAMW THEN CALL IT
          }
        } else if (selectedFoodSlot == 2) {
          //EVENING SUCCESS ACTION
          parentSetFoodCat(2)
          parentSetState(selectedDate)
          if (selectedFoodSlot == parentFoodCatId && parentDate == selectedDate) {
            dispatch(getEveningFoodList({ date: selectedDate })) //ONLY HERE DISPATCH IF DATE AND FOOD CAT ARE SAMW THEN CALL IT
          }
        }
      }
    })
  }

  useEffect(() => {
dispatch(getAllUsers())
  }, [open])

  useEffect(() => {
    dispatch(getFoodStatus(selectedDate))
    dispatch(getFoodSlots())
  }, [open, selectedDate])

  useEffect(() => {
    if (selectedFoodSlot === 1) {
      setTimingSlots(lunchslots)
      setSelectedTimeSlot(lunchslots[0]?.food_slot_id)
    } else if (selectedFoodSlot === 2) {
      setTimingSlots(eveningSlots)
      setSelectedTimeSlot(eveningSlots[0]?.food_slot_id)
    }
  }, [selectedFoodSlot])

  useEffect(() => {
    if (foodStatus.length > 0) {
      const isComingDayFoodAlreadyDefined = foodStatus[0].day_food_status_date == selectedDate
      if (isComingDayFoodAlreadyDefined) {
        const isNon = foodStatus[0].day_food_meal_status == 2
        setIsNonVegDay(isNon)
      }
    } else if (dayjs(selectedDate).get('day') === 5) {
      setIsNonVegDay(true)
    } else {
      setIsNonVegDay(false)
    }
  }, [foodStatus])

  const handleUserChange = (e, option, value) => {
    setSelectedUserId(option.userID)
  }

  const handleCancel = () => {
    setOpen(false)
    setSelectedFoodSlot('')
    setSelectedTimeSlot('')
    setSelectedUserId('')
    setIsNonVegDay(false)
    setIsNonVegDay(false)
    setSelectedDate(dayjs().format('YYYY-MM-DD'))
  }
  return (
    <GlobalDialog
      title={'Add Food'}
      open={open}
      setOpen={setOpen}
      fullWidth
      // maxWidth={"md"}
      closeButton={true}
      closeCondition={handleCancel}
      popupButton={<AddButton action={() => setOpen(true)}>Add Food</AddButton>}
      content={
        <>
          {loading ? (
            <Box>
              <Stack gap={2}>
                <Skeleton variant="rectangular" width={'100%'} height={60} />
                <Skeleton variant="rectangular" width={'100%'} height={60} />
                <Skeleton variant="rectangular" width={'100%'} height={60} />
                <Skeleton variant="square" width={80} height={40} />
              </Stack>
            </Box>
          ) : (
            <Stack gap={2}>
              <Box sx={{ flex: 1 }}>
                <Autocomplete
                  name="name"
                  options={users}
                  getOptionLabel={(option) => option.name || ''}
                  value={users.find((users) => users.userID == selectedUserId) || null}
                  onChange={handleUserChange}
                  renderInput={(params) => (
                    <TextField disabled fullWidth autoComplete="off" {...params} label="Users" />
                  )}
                />
              </Box>

              <Stack direction={direction} gap={2} sx={{ width: '100%' }}>
                <Box sx={{ flex: 1 }}>
                  <DatePickerCustom
                    value={dayjs(selectedDate)}
                    setValue={(date) => setSelectedDate(date.format('YYYY-MM-DD'))}
                    viewDetails={['year', 'month', 'day']}
                    fullWidth
                  />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <FormControl fullWidth>
                    <InputLabel>Food Slots </InputLabel>
                    <Select
                      fullWidth
                      name="foodSolt"
                      label="Food Slots"
                      value={selectedFoodSlot}
                      onChange={handleSlotChange}>
                      {foodSlots?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Stack>

              <Stack direction={direction} gap={2} sx={{ width: '100%' }}>
                <Box sx={{ flex: 1 }}>
                  <FormControl fullWidth>
                    <InputLabel>Time Slots </InputLabel>
                    <Select name="timeSlots" label="Time Slots" value={selectedTimeSlot} onChange={handleSlotChange}>
                      {timingSlots?.map((item) => (
                        <MenuItem key={item.food_slot_id} value={item.food_slot_id}>
                          {item.food_slot_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <FormControl fullWidth>
                    <InputLabel>Office</InputLabel>
                    <Select
                      name="offices"
                      label="offices"
                      value={selectedOfficeSlot}
                      onChange={handleSlotChange}
                      disabled>
                      {offices?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Stack>

              <Box sx={{ mt: -1, ml: -1 }}>
                {selectedFoodSlot == 1 && !loading && isNonVegDay && (
                  <FormGroup row>
                    <FormControlLabel
                      control={<Checkbox onChange={handleSlotChange} name="vegRequired" checked={vegReq} />}
                      label="Veg Required"
                      labelPlacement="start"
                    />
                  </FormGroup>
                )}
              </Box>

              <Box>
                <SubmitButton disabled={loading} action={() => handleSubmit()}>
                  Add Food
                </SubmitButton>
              </Box>
            </Stack>
          )}
        </>
      }
    />
  )
}

export default AddFood
