import {
  Box,
  Button,
  Chip,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import GlobalWrapper from "../../../../components/Wrapper/GlobalWrapper";
import CartCourseComponent from "./CartCourseComponent";
import NoDataFound from "../../../../components/NodataIndication/NoDataFound";
import { motion } from "framer-motion";
import PopuForm from "./PopuForm";
import MotionContainer from "../../../../components/FramerMotion/MotionContainer";
import IsAvailableCartItems from "./IsAvailableCartItems";
import { UpdateButton } from "../../../../components/mui/Buttons/Buttons";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getCartItems,
  UpdateCartItems,
} from "../../../../redux/features/cartSlice";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import PaidCartItems from "./PaidCartItems";
import SlideToPay from "../../../../components/SlidingButton";
import PaymentMethodsPopup from "./PaymentMethodsPopup";
import PaymentProcessingCourses from "./PaymentProcessingCourses";
import CircularLoader from "../../../../components/Loader/CircularLoader";

const CartCourses = ({
  totalCourses,
  setTotalCourses,
  paidCartItems,
  setAvailableCartItems,
  availableCartItems,
  changeValidation,
  paymentProcessingItems,
}) => {
  const [totalDiscountedAmount, setTotalDiscountedAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [totalPayingAmount, setTotalPayingAmount] = useState(0);
  const [isAvailabletotalPayingAmount, setIsAvailableTotalPayingAmount] =
    useState(0);
  const [isVailablefilterCourseData, setIsVailableFilterCourse] = useState([]);
  const { cartItems } = useSelector((state) => state.cart);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.cart);
  const { enqueueSnackbar } = useSnackbar();

  const [zeroValidation, setZeroValidation] = useState(false);
  const [discountValidation, setDiscountValidation] = useState(false);
  const [payingAmountValidation, setPayingAmountValidation] = useState(false);
  // -------------------------------------------------------------------------

  const [availableZeroValidation, setAvailableZeroValidation] = useState(false);
  const [availableDiscountValidation, setAvailableDiscountValidation] =
    useState(false);
  const [availablePayingAmountValidation, setAvailablePayingAmountValidation] =
    useState(false);

  const handleUpdate = () => {
    // Check if there are availableCartItems to validate
    if (availableCartItems && availableCartItems.length > 0) {
      // Perform validation for each item

      let hasEmptyFields = false;
      availableCartItems.forEach((item) => {
        if (
          !item.MRP ||
          !item.discount ||
          !item.noOfSessions ||
          (!item.paidAmt && item.wishList == false)
        ) {
          enqueueSnackbar("Some fields are empty...!", {
            variant: "error",
          });
          hasEmptyFields = true;
        }
      });

      // If there are empty fields, stop further processing
      if (hasEmptyFields) return;
    }

    if (availableCartItems && availableCartItems.length > 0) {
      const validation = availableCartItems.some(
        (item) => item.subjects.length == 0
      );
      if (!validation) {
        const data = {
          leadId: location?.state.leadId,
          items: availableCartItems,
        };

        dispatch(UpdateCartItems(data)).then((res) => {
          if (res.payload.status === "error") {
            enqueueSnackbar(res.payload.message, { variant: "error" });
          } else if (res.payload.status === "success") {
            dispatch(getCartItems(data.leadId));
            enqueueSnackbar(res.payload.message, {
              variant: "success",
            });
          }
        });
      } else {
        enqueueSnackbar("Add subjects...!", {
          variant: "error",
        });
      }
    }
  };
  // -----------------validation-------------------
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    const hasArrayDataChanged = (arr1, arr2) => {
      if (arr1.length !== arr2.length) return true;

      return arr1.some((obj, index) => {
        const obj2 = arr2[index];
        return Object.keys(obj).some((key) => obj[key] !== obj2[key]);
      });
    };
    setHasChanges(hasArrayDataChanged(changeValidation, availableCartItems));
  }, [availableCartItems, changeValidation]);
  const handlePaymentConfirm = () => {
    if (availableCartItems && availableCartItems.length > 0) {
      availableCartItems.forEach((item) => {
        if (
          !item.MRP ||
          !item.discount ||
          !item.noOfSessions ||
          (!item.paidAmt && item.wishList == false)
        ) {
          enqueueSnackbar("Some fields are empty...!", {
            variant: "error",
          });
          setOpen(false);
        } else {
          setOpen(true);
        }
      });
    }
  };
  return (
    <Box sx={{ paddingTop: 2 }}>
      <Paper
        elevation={2}
        sx={{ borderRadius: 3, padding: 1, backgroundColor: "#fafaff" }}
      >
        <Box padding={2}>
          <Chip
            sx={{ backgroundColor: "#702dff", color: "white" }}
            variant="contained"
            icon={
              <Icon
                icon="hugeicons:course"
                height={22}
                width={22}
                color="white"
              />
            }
            label={
              <Typography variant="h6">Details of Added Courses</Typography>
            }
          />
        </Box>
        <Box padding={1}>
          <GlobalWrapper>
            <PaidCartItems paidCartItems={paidCartItems} />
            <PaymentProcessingCourses
              paymentProcessingItems={paymentProcessingItems}
            />

            {availableCartItems.length == 0 ? null : (
              <>
                <IsAvailableCartItems
                  availableCartItems={availableCartItems}
                  setAvailableCartItems={setAvailableCartItems}
                  setTotalCourses={setTotalCourses}
                  totalCourses={totalCourses}
                  totalDiscountedAmount={totalDiscountedAmount}
                  setTotalDiscountedAmount={setTotalDiscountedAmount}
                  setTotalDiscount={setTotalDiscount}
                  setTotalAmount={setTotalAmount}
                  setTotalPayingAmount={setTotalPayingAmount}
                  setIsVailableFilterCourse={setIsVailableFilterCourse}
                  setIsAvailableTotalPayingAmount={
                    setIsAvailableTotalPayingAmount
                  }
                  setAvailablePayingAmountValidation={
                    setAvailablePayingAmountValidation
                  }
                  setAvailableDiscountValidation={
                    setAvailableDiscountValidation
                  }
                  setAvailableZeroValidation={setAvailableZeroValidation}
                  availablePayingAmountValidation={
                    availablePayingAmountValidation
                  }
                  availableDiscountValidation={availableDiscountValidation}
                  availableZeroValidation={availableZeroValidation}
                />

                <MotionContainer delay={0.7}>
                  {availablePayingAmountValidation ||
                  availableDiscountValidation ||
                  availableZeroValidation ? null : (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: 2,
                      }}
                    >
                      {hasChanges ? (
                        <>
                          <Button
                            onClick={handleUpdate}
                            sx={{
                              color: "white",
                              background: `linear-gradient(to bottom, #0089d0 0%, #0089d0 10%, #a125c2 100%)`,
                              borderRadius: 3,
                              width: 200,
                            }}
                          >
                            <Icon
                              icon="grommet-icons:update"
                              height={22}
                              width={22}
                            />
                            {loading ? (
                              <Box sx={{ display: "flex" }}>
                                Please Wait... <CircularLoader />
                              </Box>
                            ) : (
                              " Update Cart Items"
                            )}
                          </Button>
                        </>
                      ) : null}
                    </Box>
                  )}
                </MotionContainer>
                <MotionContainer delay={0.7}>
                  {availablePayingAmountValidation ||
                  availableDiscountValidation ||
                  availableZeroValidation ? null : (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: 2,
                      }}
                    >
                      {hasChanges ? null : (
                        <>
                          {isVailablefilterCourseData?.length == 0 ? null : (
                            <SlideToPay onConfirm={handlePaymentConfirm} />
                          )}
                        </>
                      )}
                    </Box>
                  )}
                </MotionContainer>
                <PaymentMethodsPopup
                  isAvailabletotalPayingAmount={isAvailabletotalPayingAmount}
                  open={open}
                  setOpen={setOpen}
                />
              </>
            )}

            <Box mt={2}>
              <Typography variant="h6">New Courses</Typography>
              <Divider sx={{ borderStyle: "dashed", borderBottomWidth: 2 }} />
            </Box>

            {totalCourses.length !== 0 ? (
              <Box>
                <CartCourseComponent
                  setTotalCourses={setTotalCourses}
                  totalCourses={totalCourses}
                  totalDiscountedAmount={totalDiscountedAmount}
                  setTotalDiscountedAmount={setTotalDiscountedAmount}
                  setTotalDiscount={setTotalDiscount}
                  setTotalAmount={setTotalAmount}
                  setTotalPayingAmount={setTotalPayingAmount}
                  setPayingAmountValidation={setPayingAmountValidation}
                  payingAmountValidation={payingAmountValidation}
                  setDiscountValidation={setDiscountValidation}
                  discountValidation={discountValidation}
                  setZeroValidation={setZeroValidation}
                  zeroValidation={zeroValidation}
                />
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      padding: 2,
                      alignItems: "self-start",
                    }}
                  >
                    <Stack
                      direction={"column"}
                      spacing={1}
                      sx={{ alignItems: "flex-end" }}
                    >
                      <MotionContainer delay={0.5}>
                        <Box>
                          <Button sx={{ color: "black" }}>
                            Total Amount :-
                            <Icon
                              icon="mdi:currency-inr"
                              height={22}
                              width={22}
                            />
                            {totalAmount}
                          </Button>
                          <Divider
                            sx={{ borderStyle: "dashed", borderBottomWidth: 2 }}
                          />
                        </Box>
                      </MotionContainer>
                      <MotionContainer delay={0.7}>
                        <Box>
                          <Button sx={{ color: "black" }}>
                            Total Discount :-
                            <Icon
                              icon="mdi:currency-inr"
                              height={22}
                              width={22}
                            />
                            {totalDiscount}
                          </Button>
                          <Divider
                            sx={{ borderStyle: "dashed", borderBottomWidth: 2 }}
                          />
                        </Box>
                      </MotionContainer>
                      <MotionContainer delay={1}>
                        <Box>
                          <Button sx={{ color: "black" }}>
                            Total Discounted Amount :-
                            <Icon
                              icon="mdi:currency-inr"
                              height={22}
                              width={22}
                            />
                            {totalDiscountedAmount}
                          </Button>
                          <Divider
                            sx={{ borderStyle: "dashed", borderBottomWidth: 2 }}
                          />
                        </Box>
                      </MotionContainer>
                      <MotionContainer delay={1.2}>
                        <Box>
                          <Button sx={{ color: "black" }}>
                            Total Paying Amount :-
                            <Icon
                              icon="mdi:currency-inr"
                              height={22}
                              width={22}
                            />
                            {totalPayingAmount}
                          </Button>
                          <Divider
                            sx={{ borderStyle: "dashed", borderBottomWidth: 2 }}
                          />
                        </Box>
                      </MotionContainer>
                    </Stack>
                  </Box>
                </Box>
                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", pt: 2 }}
                >
                  <PopuForm
                    disabled={
                      payingAmountValidation ||
                      discountValidation ||
                      zeroValidation
                        ? true
                        : false
                    }
                    totalPayingAmount={totalPayingAmount}
                    totalDiscountedAmount={totalDiscountedAmount}
                    totalCourses={totalCourses}
                    setTotalCourses={setTotalCourses}
                  />
                </Box>
              </Box>
            ) : (
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -50 }}
                transition={{ duration: 0.5 }}
              >
                <NoDataFound />
              </motion.div>
            )}
            <motion.div
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -50 }}
              transition={{ duration: 0.5 }}
            >
              <Box mt={2}>
                <Divider sx={{ borderStyle: "dashed", borderBottomWidth: 2 }} />
                <Box sx={{ display: "flex", justifyContent: "center", pt: 1 }}>
                  <Chip
                    icon={<Icon icon="emojione-monotone:end-arrow" />}
                    size="small"
                    color="primary"
                    label={
                      <Typography sx={{ fontSize: 10 }}>
                        Cart items end
                      </Typography>
                    }
                    variant="outlined"
                    sx={{
                      borderColor: "#e3f2fd",
                      color: "#64b5f6",
                    }}
                  />
                </Box>
              </Box>
            </motion.div>
          </GlobalWrapper>
        </Box>
      </Paper>
    </Box>
  );
};

export default CartCourses;
