import React, { useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  AddButton,
  CancelButton,
  SubmitButton,
  UpdateButton,
} from "../../../../mui/Buttons/Buttons";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import GlobalSelectFieldWithoutFormik from "../../../../mui/GlobalSelectFieldWithoutFormik";
import {
  createIncome,
  getIncome,
  getIncomeScreenshot,
  getStudentsList,
  getStudentYearAndMonth,
  updateIncome,
} from "../../../../../redux/features/incomeSlice";
import { useDispatch, useSelector } from "react-redux";
import { useFormik, FormikProvider, Form } from "formik";
import * as Yup from "yup";
import DatePickerCustom from "../../../../DatePicker/DatePickerCustom";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import ViewScreenShot from "../../../../../views/ogd/myworkspace/Invoice/ViewScreenShot";
import { savePaginationData } from "../../../../../redux/features/globalDatagridSlice";

const feeType = [
  { idNo: 1, valueNo: "Tuition Fee" },
  { idNo: 2, valueNo: "Admission Fee" },
];

const paymentAccount = [
  { idNo: 1, valueNo: "Indian A/c" },
  { idNo: 2, valueNo: "GCC A/c" },
];

const paymentModes = [
  { idNo: 1, valueNo: "Bank Transfer" },
  { idNo: 2, valueNo: "UPI" },
  { idNo: 3, valueNo: "Cash" },
  { idNo: 4, valueNo: "EMI" },
];
const AddOrUpdateIncome = ({ row, paginationDetails, isVerified }) => {
  const { loading, studentsList, currentLedger } = useSelector(
    (store) => store.income
  );
  const { paginationData } = useSelector((state) => state.globalDataGrid);
  const { otherConfig } = useSelector((store) => store.role);
  const [permissionInEditIncom, setPermissionInEditIncom] = useState(false);
  useEffect(() => {
    const permission = otherConfig.find((perm) => perm.title === "income edit");
    if (permission) {
      setPermissionInEditIncom(true);
    } else {
      setPermissionInEditIncom(false);
    }
  }, [otherConfig]);

  const userProfile = JSON.parse(localStorage.getItem("cred")).profile;
  // userProfile.roleID==90

  const fileInputRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const prevOpenRef = useRef(open);
  const [resetGlobalSelectField, setResetGlobalSelectField] = useState(false);

  const [selectedFeeType, setSelectedFeeType] = useState({
    id: row ? feeType.find((obj) => obj.valueNo == row?.feeType)?.idNo : "",
    value: row
      ? feeType.find((obj) => obj.valueNo == row?.feeType)?.valueNo
      : "",
  });
  const [paymentBank, setPaymentBank] = useState({
    id: row
      ? paymentAccount.find((obj) => obj.valueNo == row?.creditedAccount)?.idNo
      : "",
    value: row
      ? paymentAccount.find((obj) => obj.valueNo == row?.creditedAccount)
          ?.valueNo
      : "",
  });
  const [paymentMode, setPaymentMode] = useState({
    id: row
      ? paymentModes.find((obj) => obj.valueNo == row?.paymentMode)?.idNo
      : "",
    value: row
      ? paymentModes.find((obj) => obj.valueNo == row?.paymentMode)?.valueNo
      : "",
  });
  const [transactionDate, setTransactionDate] = useState(
    row ? dayjs(row.transactionDate, "DD-MM-YYYY") : dayjs()
  );

  useEffect(() => {
    if (open) {
      dispatch(getStudentsList());
    }
  }, [open]);

  const initialValues = {
    isUpdate: row ? true : false,
    isAed: false,
    enrolmentId: row ? row.courseEnrollmentId : "",
    year: "",
    month: "",
    amount: row ? row.incomInr : "",
    amountAed: row ? row.incomeAED : 0,
    screenShot: "",
    remarks: row ? row.incomeRemarks : "",
  };

  const schema = Yup.object({
    enrolmentId: Yup.number().required("Student Required"),
    amount: Yup.string().required("Amount INR Required"),
    amountAed: Yup.number().when("isAed", {
      is: true,
      then: Yup.number().required("Amount AED required"),
      otherwise: Yup.number().nullable(),
    }),
    remarks: Yup.string().required("Remarks required"),
    month: Yup.number().required("Month  required"),
    year: Yup.number().required("Year  required"),
    // screenShot: Yup.mixed().required("Screenshot Required"),
    screenShot: Yup.mixed().when("isUpdate", {
      is: false,
      then: Yup.mixed().required("Screenshot is required"), // Make it required
      otherwise: Yup.mixed().nullable(), // Not required if `row` exists
    }),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values) => {
      const data = {
        ...values,
        feeType: selectedFeeType.value,
        courseEnrollmentId: values.enrolmentId,
        paymentAccountId: paymentBank.id,
        amountInAED: values.amountAed,
        paymentMode: paymentMode.value,
        incomeScreenshot: values.screenShot,
        screenShot: undefined,
        incomeRemarks: values.remarks,
        transactionDate: transactionDate.format("YYYY-MM-DD"),
      };

      if (row) {
        const updateData = {
          ...data,
          id: row.incomeId,
          incomeScreenshot: data.incomeScreenshot
            ? data.incomeScreenshot
            : undefined,
        };

        dispatch(updateIncome(updateData)).then((res) => {
          if (res.payload.status === "error") {
            enqueueSnackbar(res.payload.message, {
              variant: "error",
            });
          } else if (res.payload.status === "success") {
            // const {selectedDate, pageNumber, pageLimit }=paginationDetails
            setOpen(false);
            dispatch(getIncome(paginationDetails));
            dispatch(
              savePaginationData({ ...paginationData, openAnchor: false })
            );
            enqueueSnackbar(res.payload.message, {
              variant: "success",
            });
          }
        });
      } else {
        dispatch(createIncome(data)).then((res) => {
          if (res.payload.status === "error") {
            enqueueSnackbar(res.payload.message, {
              variant: "error",
            });
          } else if (res.payload.status === "success") {
            setOpen(false);
            dispatch(getIncome({ selectedDate: dayjs().format("YYYY-MM") }));
            // dispatch(getIncome(paginationDetails));
            enqueueSnackbar(res.payload.message, {
              variant: "success",
            });
          }
        });
      }
    },
  });

  const handleStudentChange = (e, option) => {
    formik.setFieldValue("enrolmentId", option.id);
  };

  useEffect(() => {
    if (formik.values.enrolmentId) {
      dispatch(getStudentYearAndMonth(formik.values.enrolmentId));
    }
  }, [formik.values.enrolmentId]);

  useEffect(() => {
    if (formik.values.enrolmentId && currentLedger?.year) {
      formik.setFieldValue("year", currentLedger.year);
      formik.setFieldValue("month", currentLedger.month);
    }
  }, [currentLedger, formik.values.enrolmentId]);

  const handleFileChange = (e) => {
    formik.setFieldValue("screenShot", e.currentTarget.files[0]);
  };

  useEffect(() => {
    if (paymentBank.id === 2) {
      formik.setFieldValue("isAed", true);
      formik.setFieldValue("amountAed", row ? row.incomeAED : 0);
    } else {
      formik.setFieldValue("isAed", false);
      formik.setFieldValue("amountAed", 0);
    }
  }, [paymentBank]);

  const handleCancel = () => {
    formik.resetForm();
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setTransactionDate(dayjs());
    setOpen(false);
    setResetGlobalSelectField(true);
  };

  useEffect(() => {
    if (selectedFeeType.id === 2) {
      formik.setFieldValue("amount", 500);
    } else {
      formik.setFieldValue("amount", row ? row.incomInr : "");
    }
  }, [selectedFeeType.id]);

  useEffect(() => {
    if (prevOpenRef.current && !open) {
      // If it was previously open and now closed
      handleCancel();
    }
    prevOpenRef.current = open; // Update the previous state
  }, [open]);

  return (
    <GlobalDialog
      title={row ? "Update Income" : "Add Income"}
      open={open}
      setOpen={setOpen}
      fullWidth
      maxWidth={"md"}
      closeButton={true}
      popupButton={
        <>
          {row ? (
            <UpdateButton
              //  disabled={!permissionInEditIncom || isVerified == "Verified"}  NO NEED
              //  disabled={ userProfile.roleID!=90 || isVerified == "Verified"}
              disabled={isVerified == "Verified"}
              size="small"
              action={() => setOpen(true)}
            >
              Update
            </UpdateButton>
          ) : (
            <AddButton size="large" action={() => setOpen(true)}>
              Add Income
            </AddButton>
          )}
        </>
      }
      content={
        <>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <Stack gap={2}>
                <GlobalSelectFieldWithoutFormik
                  sx={{ width: "100%" }}
                  keys={{ itemId: "idNo", itemValue: "valueNo" }}
                  label={"Fee Type"}
                  data={feeType}
                  selectedDetails={selectedFeeType}
                  setChange={setSelectedFeeType}
                  reset={resetGlobalSelectField}
                />
                <Box>
                  <Autocomplete
                    disabled={loading}
                    name="enrolmentId"
                    options={studentsList}
                    getOptionLabel={(option) => option.name || ""}
                    // isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={
                      studentsList?.find(
                        (student) => student.id == formik.values.enrolmentId 
                      ) || null
                    }
                    onChange={handleStudentChange}
                    renderInput={(params) => (
                      <TextField
                        disabled
                        fullWidth
                        autoComplete="off"
                        {...params}
                        label="Name"
                        error={
                          formik.touched.enrolmentId &&
                          Boolean(formik.errors.enrolmentId)
                        }
                        helperText={
                          formik.touched.enrolmentId &&
                          formik.errors.enrolmentId
                        }
                      />
                    )}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    gap: 1,
                  }}
                >
                  <Box sx={{ flex: 1 }}>
                    <TextField
                      name="year"
                      fullWidth
                      disabled
                      type="number"
                      value={formik.values.year}
                      label="Year"
                      error={formik.touched.year && Boolean(formik.errors.year)}
                      helperText={
                        formik.touched.year && Boolean(formik.errors.year)
                      }
                    />
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <TextField
                      name="month"
                      fullWidth
                      disabled
                      type="number"
                      value={formik.values.month}
                      label="Month"
                      error={
                        formik.touched.month && Boolean(formik.errors.month)
                      }
                      helperText={
                        formik.touched.month && Boolean(formik.errors.month)
                      }
                    />
                  </Box>
                </Box>

                <Box>
                  <DatePickerCustom
                    label="Transaction Date"
                    value={transactionDate}
                    setValue={setTransactionDate}
                    fullWidth={true}
                    viewDetails={["month", "year", "day"]}
                  />
                </Box>
                <Box>
                  <GlobalSelectFieldWithoutFormik
                    sx={{ width: "100%" }}
                    keys={{ itemId: "idNo", itemValue: "valueNo" }}
                    label={"Amount Credited To"}
                    data={paymentAccount}
                    selectedDetails={paymentBank}
                    setChange={setPaymentBank}
                    reset={resetGlobalSelectField}
                  />
                </Box>
                <Box>
                  <TextField
                    fullWidth
                    id="amount"
                    name="amount"
                    disabled={selectedFeeType.id == 2}
                    value={formik.values.amount}
                    // type={row?"text":"number"}
                    label="Amount (INR)"
                    error={
                      formik.touched.amount && Boolean(formik.errors.amount)
                    }
                    helperText={formik.touched.amount && formik.errors.amount}
                    onChange={formik.handleChange}
                    autoComplete="off"
                  />
                </Box>
                {formik.values.isAed && (
                  <Box>
                    <TextField
                      fullWidth
                      id="amountAed"
                      name="amountAed"
                      value={formik.values.amountAed}
                      type="number"
                      label="Amount (AED)"
                      error={
                        formik.touched.amountAed &&
                        Boolean(formik.errors.amountAed)
                      }
                      helperText={
                        formik.touched.amountAed && formik.errors.amountAed
                      }
                      onChange={formik.handleChange}
                      autoComplete="off"
                    />
                  </Box>
                )}
                <Box>
                  <GlobalSelectFieldWithoutFormik
                    sx={{ width: "100%" }}
                    keys={{ itemId: "idNo", itemValue: "valueNo" }}
                    label={"Payment Mode"}
                    data={paymentModes}
                    selectedDetails={paymentMode}
                    setChange={setPaymentMode}
                    reset={resetGlobalSelectField}
                  />
                </Box>
                {row && (
                  <Box>
                    <ViewScreenShot
                      pageName="income"
                      api={getIncomeScreenshot(row.screenshotLink)}
                    />
                  </Box>
                )}
                <Box>
                  <Typography sx={{ pl: 1, color: "#697077" }}>
                    Screenshot
                  </Typography>
                  <TextField
                    inputProps={{
                      accept: "image/*,application/pdf",
                    }}
                    name="incomeScreenshot"
                    onChange={handleFileChange}
                    inputRef={fileInputRef}
                    type="file"
                    error={
                      formik.touched.screenShot &&
                      Boolean(formik.errors.screenShot)
                    }
                    helperText={
                      formik.touched.screenShot && formik.errors.screenShot
                    }
                  />
                </Box>

                <Box>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="remarks"
                    label="Remarks"
                    name="remarks"
                    value={formik.values.remarks}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.remarks && Boolean(formik.errors.remarks)
                    }
                    helperText={
                      formik.touched.remarks && Boolean(formik.errors.remarks)
                    }
                  />
                </Box>

                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Stack direction={"row"} spacing={1}>
                    <CancelButton action={handleCancel}>Cancel</CancelButton>

                    {row ? (
                      <UpdateButton type="submit">
                        {loading ? "Updating..." : "Update"}
                      </UpdateButton>
                    ) : (
                      <SubmitButton type="submit">
                        {loading ? " Please Wait" : "Save"}
                      </SubmitButton>
                    )}
                  </Stack>
                </Box>
              </Stack>
            </Form>
          </FormikProvider>
        </>
      }
    />
  );
};

export default AddOrUpdateIncome;
