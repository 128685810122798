import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import GlobalDialog from "../../../../components/mui/Dialogue/GlobalDialog";
import { Box, Button, Chip } from "@mui/material";
import { Icon } from "@iconify/react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSnackbar } from "notistack";
import { LogButton } from "../../../../components/mui/Buttons/Buttons";
import DeleteLog from "./DeleteLog";

const CartPaymentLogTbl = ({ item }) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  return (
    <GlobalDialog
      title={"Cart Payment Logs"}
      open={open}
      setOpen={setOpen}
      fullWidth
      maxWidth={"lg"}
      closeButton={true}
      popupButton={
        <>
          <LogButton size={"small"} action={() => setOpen(true)}>
            View Logs
          </LogButton>
        </>
      }
      content={
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Invoice Id</TableCell>
                  <TableCell>Transaction Date</TableCell>
                  <TableCell>Created Date</TableCell>
                  <TableCell>Paid Amount</TableCell>
                  <TableCell>Paid Status</TableCell>
                  <TableCell>Payment Method</TableCell>
                  <TableCell>link Generated Status</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {item?.CartPaymentLogs.map((row) => (
                  <TableRow key={row.invoiceId}>
                    <TableCell component="th" scope="row">
                      {row.invoiceId}
                    </TableCell>
                    <TableCell>{row.transactionDate}</TableCell>
                    <TableCell>{row.createdDate}</TableCell>
                    <TableCell>{row.payableAmt}</TableCell>
                    <TableCell>
                      {
                        <Box>
                          {
                            <Chip
                              size="small"
                              variant="outlined"
                              icon={
                                row.paidStatus == 1 ? (
                                  <Icon icon="mdi:tick" />
                                ) : (
                                  <Icon icon="hugeicons:clock-05" />
                                )
                              }
                              color={
                                row.paidStatus == 1 ? "success" : "warning"
                              }
                              label={
                                row.paidStatus == 1 ? "Completed" : "Pending"
                              }
                            />
                          }
                        </Box>
                      }
                    </TableCell>
                    <TableCell>{row.paymentMethod}</TableCell>
                    <TableCell>
                      {
                        <Box>
                          {row.linkGeneratedStatus == 0 ? (
                            <Chip
                              variant="outlined"
                              size="small"
                              color="primary"
                              label="Manual Enrollment"
                            />
                          ) : (
                            <CopyToClipboard
                              text={row.paymentLink}
                              onCopy={() => {
                                enqueueSnackbar(
                                  "Link Copied Successfully...!",
                                  {
                                    variant: "success",
                                  }
                                );
                              }}
                            >
                              <Button
                                sx={{ borderRadius: 4 }}
                                size="small"
                                variant="contained"
                                disabled={row.paidStatus == 1 ? true : false}
                              >
                                <Icon
                                  icon="bitcoin-icons:link-filled"
                                  height={22}
                                  width={22}
                                />
                                Copy Link
                              </Button>
                            </CopyToClipboard>
                          )}
                        </Box>
                      }
                    </TableCell>
                    <TableCell>
                      {row.linkGeneratedStatus == 1 && row.paidStatus == 0 ? (
                        <DeleteLog id={row.paymentLogId} />
                      ) : null}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      }
    />
  );
};

export default CartPaymentLogTbl;
