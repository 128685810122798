import {
  Alert,
  Box,
  Divider,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useSnackbar } from "notistack";
import ActionMenu from "./ActionMenu";
import useResponsive from "../../../../Hooks/useResponsive";
import MotionContainer from "../../../../components/FramerMotion/MotionContainer";

const CartCourseComponent = ({
  totalCourses,
  setTotalCourses,
  totalDiscountedAmount,
  setTotalDiscountedAmount,
  setTotalDiscount,
  setTotalAmount,
  setTotalPayingAmount,
  zeroValidation,
  setZeroValidation,
  discountValidation,
  setDiscountValidation,
  payingAmountValidation,
  setPayingAmountValidation,
}) => {
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");
  const { enqueueSnackbar } = useSnackbar();

  // const [filterCourseData, setFilterCourse] = useState([]);

  // Handle deleting a course
  const handleDelete = (courseId) => {
    const updatedCourses = totalCourses.filter((item) => item.id != courseId);
    enqueueSnackbar("Deleted Successfully...!", {
      variant: "success",
    });
    setTotalCourses(updatedCourses);
  };

  // useEffect(() => {
  //   const filterData = totalCourses.filter((item) => item.wishList == false);
  //   setFilterCourse(filterData);
  // }, [totalCourses]);
  const filterCourseData = useMemo(
    () => totalCourses.filter((item) => !item.wishList),
    [totalCourses]
  );

  // useEffect(() => {
  //   if (filterCourseData) {
  //     const sum = filterCourseData.reduce(
  //       (acc, course) => acc + (parseInt(course.discountedAmount) || 0),
  //       0
  //     );
  //     const totalDiscount = filterCourseData.reduce(
  //       (acc, course) => acc + (parseInt(course.discount) || 0),
  //       0
  //     );
  //     const totalAmount = filterCourseData.reduce(
  //       (acc, course) => acc + (parseInt(course.MRP) || 0),
  //       0
  //     );
  //     const totalPayingAmount = filterCourseData.reduce(
  //       (acc, course) => acc + (parseInt(course.paidAmt) || 0),
  //       0
  //     );
  //     setTotalPayingAmount(totalPayingAmount);
  //     setTotalAmount(totalAmount);
  //     setTotalDiscount(totalDiscount);
  //     setTotalDiscountedAmount(sum);
  //   }
  // }, [filterCourseData]);
  const calculations = useMemo(() => {
    const sum = filterCourseData.reduce(
      (acc, course) => acc + (parseInt(course.discountedAmount) || 0),
      0
    );
    const totalDiscount = filterCourseData.reduce(
      (acc, course) => acc + (parseInt(course.discount) || 0),
      0
    );
    const totalAmount = filterCourseData.reduce(
      (acc, course) => acc + (parseInt(course.MRP) || 0),
      0
    );
    const totalPayingAmount = filterCourseData.reduce(
      (acc, course) => acc + (parseInt(course.paidAmt) || 0),
      0
    );
    return { sum, totalDiscount, totalAmount, totalPayingAmount };
  }, [filterCourseData]);
  useEffect(() => {
    setTotalDiscountedAmount(calculations.sum);
    setTotalDiscount(calculations.totalDiscount);
    setTotalAmount(calculations.totalAmount);
    setTotalPayingAmount(calculations.totalPayingAmount);
  }, [calculations]);

  useEffect(() => {
    if (totalCourses) {
      const validation = totalCourses.some(
        (data) =>
          (data.noOfSessions && data.noOfSessions == 0) ||
          (data.paidAmt && data.paidAmt == 0) ||
          (data.MRP && data.MRP == 0)
      );

      if (validation) {
        setZeroValidation(true);
      } else {
        setZeroValidation(false);
      }
    }

    const discountValidations = totalCourses.some(
      (item) =>
        item.MRP &&
        parseFloat(item.MRP) <= item.discount &&
        parseFloat(item.discount)
    );

    if (discountValidations) {
      setDiscountValidation(true);
    } else {
      setDiscountValidation(false);
    }
    const payingValidation = totalCourses.some(
      (item) =>
        item.discountedAmount &&
        parseFloat(item.discountedAmount) < item.paidAmt &&
        parseFloat(item.paidAmt)
    );
    if (payingValidation) {
      setPayingAmountValidation(true);
    } else {
      setPayingAmountValidation(false);
    }
  }, [totalCourses]);

  // const handleInputChange = (e, courseId, field) => {
  //   const { value, type, checked } = e.target;
  //   const updatedCourses = totalCourses.map((item) => {
  //     if (item.id === courseId) {
  //       if (
  //         item.id === courseId &&
  //         type === "checkbox" &&
  //         (item.MRP == "" || item.discount == "" || item.noOfSessions == "")
  //       ) {
  //         enqueueSnackbar("Some fields are empty...!", {
  //           variant: "error",
  //         });
  //         return item;
  //       }
  //       let updatedItem = {
  //         ...item,
  //         [field]: type === "checkbox" ? checked : value,
  //       };

  //       if (field === "MRP" || field === "discount") {
  //         const amount = field === "MRP" ? value : item.MRP || 0;
  //         const discount = field === "discount" ? value : item.discount || 0;
  //         const discountedAmount = amount - discount;

  //         updatedItem = { ...updatedItem, discountedAmount };
  //       }
  //       if (field === "admissionFee") {
  //         updatedItem = { ...updatedItem, admissionFee: checked ? 500 : 0 };
  //       }

  //       return updatedItem;
  //     }
  //     return item;
  //   });

  //   setTotalCourses(updatedCourses);
  // };
  // Handle input changes
  const handleInputChange = useCallback(
    (e, courseId, field) => {
      const { value, type, checked } = e.target;
      setTotalCourses((prevCourses) =>
        prevCourses.map((item) => {
          if (item.id !== courseId) return item;

          const updatedItem = {
            ...item,
            [field]: type === "checkbox" ? checked : value,
          };

          if (field === "MRP" || field === "discount") {
            const MRP = field === "MRP" ? value : item.MRP || 0;
            const discount = field === "discount" ? value : item.discount || 0;
            updatedItem.discountedAmount = MRP - discount;
          }

          return updatedItem;
        })
      );
    },
    [setTotalCourses]
  );

  return (
    <Box>
      <AnimatePresence>
        {filterCourseData
          ?.filter((item) => item.id)
          .map((item) => {
            return (
              <motion.div
                key={item.sl_no}
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -50 }}
                transition={{ duration: 0.5 }}
              >
                <Paper
                  sx={{
                    backgroundColor: "#edf2fb",
                    mt: 3,
                    padding: 2,
                    borderRadius: 3,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Stack direction={mdUp ? "row" : "column"} spacing={2}>
                    <Box sx={{ maxWidth: 100, minWidth: 70 }}>
                      <img
                        style={{ borderRadius: 15 }}
                        src={
                          "https://campustechnology.com/-/media/EDU/CampusTechnology/2019-Images/20191209online.jpg"
                        }
                        height={60}
                        width={60}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography sx={{ fontSize: 12, fontWeight: "bolder" }}>
                          {item.courseName.replace(/[_-]/g, " ")}
                        </Typography>
                      </Box>
                    </Box>
                    {/* Text fields for session count, amount, discount, and discounted amount */}
                    <motion.div
                      initial={{ opacity: 0, x: -30 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: -20 }}
                      transition={{ duration: 0.5 }}
                    >
                      <TextField
                        type="number"
                        label="no: of sessions"
                        onWheel={(event) => event.target.blur()}
                        InputProps={{ inputProps: { min: 0, step: 1 } }}
                        value={item.noOfSessions || ""}
                        onChange={(e) => {
                          const value = e.target.value;
                          // Validate for non-negative integers only
                          if (/^\d*$/.test(value)) {
                            handleInputChange(e, item.id, "noOfSessions");
                          }
                        }}
                        sx={{
                          // width: "150px",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "30px",
                            padding: "0px",
                          },
                          "& .MuiOutlinedInput-input": {
                            height: "30px",
                            padding: "5px 10px",
                            fontSize: "0.8rem",
                          },
                          "& .MuiInputLabel-root": {
                            fontSize: "0.8rem",
                            top: "-5px",
                          },
                          "& .MuiInputLabel-shrink": {
                            top: "0",
                          },
                        }}
                      />
                    </motion.div>
                    <motion.div
                      initial={{ opacity: 0, x: -30 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: -20 }}
                      transition={{ duration: 0.7 }}
                    >
                      <TextField
                        label="Amount"
                        type="number"
                        onWheel={(event) => event.target.blur()}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={item.MRP || ""}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value >= 0 || value === "") {
                            handleInputChange(e, item.id, "MRP");
                          }
                        }}
                        sx={{
                          // width: "150px",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "30px",
                            padding: "0px",
                          },
                          "& .MuiOutlinedInput-input": {
                            height: "30px",
                            padding: "5px 10px",
                            fontSize: "0.8rem",
                          },
                          "& .MuiInputLabel-root": {
                            fontSize: "0.8rem",
                            top: "-5px",
                          },
                          "& .MuiInputLabel-shrink": {
                            top: "0",
                          },
                        }}
                      />
                    </motion.div>
                    <motion.div
                      initial={{ opacity: 0, x: -30 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: -20 }}
                      transition={{ duration: 0.5 }}
                    >
                      <TextField
                        label="Discount"
                        type="number"
                        onWheel={(event) => event.target.blur()}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={item.discount || ""}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value >= 0 || value === "") {
                            handleInputChange(e, item.id, "discount");
                          }
                        }}
                        sx={{
                          // width: "150px",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "30px",
                            padding: "0px",
                          },
                          "& .MuiOutlinedInput-input": {
                            height: "30px",
                            padding: "5px 10px",
                            fontSize: "0.8rem",
                          },
                          "& .MuiInputLabel-root": {
                            fontSize: "0.8rem",
                            top: "-5px",
                          },
                          "& .MuiInputLabel-shrink": {
                            top: "0",
                          },
                        }}
                      />
                    </motion.div>
                    <motion.div
                      initial={{ opacity: 0, x: -30 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: -20 }}
                      transition={{ duration: 0.5 }}
                    >
                      <TextField
                        label="Discounted Amount"
                        disabled
                        type="number"
                        onWheel={(event) => event.target.blur()}
                        value={item.discountedAmount || ""}
                        onChange={(e) =>
                          handleInputChange(e, item.id, "discountedAmount")
                        }
                        sx={{
                          // width: "150px",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "30px",
                            padding: "0px",
                          },
                          "& .MuiOutlinedInput-input": {
                            height: "30px",
                            padding: "5px 10px",
                            fontSize: "0.8rem",
                          },
                          "& .MuiInputLabel-root": {
                            fontSize: "0.8rem",
                            top: "-5px",
                          },
                          "& .MuiInputLabel-shrink": {
                            top: "0",
                          },
                        }}
                      />
                    </motion.div>
                    <motion.div
                      initial={{ opacity: 0, x: -30 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: -20 }}
                      transition={{ duration: 0.9 }}
                    >
                      <TextField
                        label="paying Amount"
                        type="number"
                        onWheel={(event) => event.target.blur()}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={item.paidAmt || ""}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value >= 0 || value === "") {
                            handleInputChange(e, item.id, "paidAmt");
                          }
                        }}
                        sx={{
                          // width: "150px",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "30px",
                            padding: "0px",
                          },
                          "& .MuiOutlinedInput-input": {
                            height: "30px",
                            padding: "5px 10px",
                            fontSize: "0.8rem",
                          },
                          "& .MuiInputLabel-root": {
                            fontSize: "0.8rem",
                            top: "-5px",
                          },
                          "& .MuiInputLabel-shrink": {
                            top: "0",
                          },
                        }}
                      />
                    </motion.div>

                    {/* ------------------------------action-menu-------------/ */}
                    <ActionMenu
                      totalCourses={totalCourses}
                      item={item}
                      setTotalCourses={setTotalCourses}
                      handleDelete={handleDelete}
                      handleInputChange={handleInputChange}
                    />
                    {/* ------------------- */}
                  </Stack>
                </Paper>
              </motion.div>
            );
          })}
      </AnimatePresence>
      <Box pt={2}>
        {zeroValidation ? (
          <MotionContainer delay={0.5}>
            <Alert severity="error">Fields cannot be zero...!</Alert>
          </MotionContainer>
        ) : null}
      </Box>
      <Box pt={2}>
        {discountValidation ? (
          <MotionContainer delay={0.5}>
            <Alert severity="error">
              Discount amount must not be greater than or equal to the course
              amount...!
            </Alert>
          </MotionContainer>
        ) : null}
      </Box>
      <Box pt={2}>
        {payingAmountValidation ? (
          <MotionContainer delay={0.5}>
            <Alert severity="error">
              Paying amount must not be greater than or equal to the discouted
              course amount...!
            </Alert>
          </MotionContainer>
        ) : null}
      </Box>
    </Box>
  );
};

export default CartCourseComponent;
