import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiRequest from "../../api/request";
import { formatDataForDataTable } from "../../utils/formatData";

const initialState = {
  loading: false,
  data: [],
  leadDetails: {},
  leadDetailsLoading: false,
  cartItems: [],
  leadPreferance: {},
  nextPaymentLink: {},
  cartStudents: [],
  emiCartItems: [],
};

export const createCartItem = createAsyncThunk(
  "cart/createCartItem",
  async (data) => {
    const res = await apiRequest({
      url: `cart/add-items`,
      method: "post",
      data: data,
    });
    return res;
  }
);
export const getLeadDetails = createAsyncThunk(
  "cart/getLeadDetails",
  async (id) => {
    const res = await apiRequest({
      url: `lead/getLead/${id}`,
      method: "get",
    });
    return res;
  }
);

export const getCartItems = createAsyncThunk(
  "cart/getCartItems",
  async (id) => {
    const res = await apiRequest({
      url: `cart/get-cart/${id}`,
      method: "get",
    });
    return res;
  }
);
export const UpdateCartItems = createAsyncThunk(
  "cart/UpdateCartItems",
  async (data) => {
    const res = await apiRequest({
      url: `cart/patch-items`,
      method: "patch",
      data: data,
    });
    return res;
  }
);
export const deleteCart = createAsyncThunk("cart/deleteCart", async (id) => {
  const res = await apiRequest({
    url: `cart/delete-items/${id}`,
    method: "delete",
  });
  return res;
});

export const getleadPreferance = createAsyncThunk(
  "ogd/getleadPreferance",
  async (id) => {
    const res = await apiRequest({
      method: "get",
      url: `cart/leadPreferance/${id}`,
    });
    return res;
  }
);
export const makeNextPayment = createAsyncThunk(
  "ogd/makeNextPayment",
  async (data) => {
    const res = await apiRequest({
      method: "post",
      url: `cart/next-payment`,
      data,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    });
    return res;
  }
);

export const deletePaymentLog = createAsyncThunk(
  "ogd/deletePaymentLog",
  async (id) => {
    const res = await apiRequest({
      method: "delete",
      url: `cart/delete-payment/${id}`,
    });
    return res;
  }
);

export const getCartStudents = createAsyncThunk(
  "ogd/getCartStudents",
  async () => {
    const res = await apiRequest({
      method: "get",
      url: `cart/cart-admissions?limit=no`,
    });
    return res;
  }
);
export const getEmiCartItem = createAsyncThunk(
  "ogd/getEmiCartItem",
  async (data) => {
    const res = await apiRequest({
      method: "get",
      url: `cart/finance/${data.id}?page=${data.page}&limit=${data.limit}`,
    });
    return res;
  }
);
export const deleteSubject = createAsyncThunk(
  "ogd/deleteSubject",
  async (data) => {
    const res = await apiRequest({
      method: "delete",
      url: `cart/subject/${data.cartId}/${data.subjectId}`,
    });
    return res;
  }
);
const cartSlice = createSlice({
  name: "cartSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //.................createCartItem..............................
    builder.addCase(createCartItem.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(createCartItem.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
      };
    });
    // -------------------getLeadDetails-----------------------------
    builder.addCase(getLeadDetails.pending, (state, action) => {
      return {
        ...state,
        leadDetailsLoading: true,
      };
    });
    builder.addCase(getLeadDetails.fulfilled, (state, action) => {
      return {
        ...state,
        leadDetails: action.payload.data,
        leadDetailsLoading: false,
      };
    });
    // --------------------getCartItems----------------------------
    builder.addCase(getCartItems.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getCartItems.fulfilled, (state, action) => {
      return {
        ...state,
        cartItems: action.payload.data,
        loading: false,
      };
    });
    // --------------------UpdateCartItems---------------------------
    builder.addCase(UpdateCartItems.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(UpdateCartItems.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
      };
    });
    // -----------------deleteCart---------------------------------
    builder.addCase(deleteCart.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(deleteCart.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
      };
    });
    // ----------------------------------------
    builder.addCase(getleadPreferance.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getleadPreferance.fulfilled, (state, action) => {
      return {
        ...state,
        leadPreferance: action.payload.data,
        loading: false,
      };
    });
    // --------------------makeNextPayment------------------------------
    builder.addCase(makeNextPayment.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(makeNextPayment.fulfilled, (state, action) => {
      return {
        ...state,
        nextPaymentLink: action.payload.data,
        loading: false,
      };
    });
    // --------------------deletePaymentLog-----------------------
    builder.addCase(deletePaymentLog.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(deletePaymentLog.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
      };
    });
    // -------------------getCartStudents----------------------------

    builder.addCase(getCartStudents.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getCartStudents.fulfilled, (state, action) => {
      const data = action.payload.data;
      return {
        ...state,
        cartStudents: data,
        loading: false,
      };
    });
    // ---------------------getEmiCartItem--------------------------
    builder.addCase(getEmiCartItem.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getEmiCartItem.fulfilled, (state, action) => {
      const data = action.payload;
      return {
        ...state,
        emiCartItems: data,
        loading: false,
      };
    });
    // ---------------------deleteSubject------------------------
    builder.addCase(deleteSubject.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(deleteSubject.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
      };
    });
  },
});

export default cartSlice.reducer;
