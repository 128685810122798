import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import HrRoute from "../route/myworkspace/HrRoute";
import { HodRouter, HrRouter } from "./myworkspace";
import useRoles from "../../Hooks/useRoles";
import HodRoute from "../route/HodRoute";
import MyStudentsTable from "../../views/cro/MyStudents";
import { useSelector } from "react-redux";
import CrmLeads from "../../views/crm/myworkspace/CRM Leads/CrmLeads";
import AdmissionRegister from "../../views/crm/myworkspace/AdmissionRegister/AdmissionRegister";
import UpdateExpectedSession from "../../views/crm/myworkspace/ExpectedSession/UpdateExpectedSession";
import {
  CroLeads,
  UpdateLeadForm,
  CroStudentsView,
  CroCourseEnroll,
  StudentErollForm,
  UpdateTotalFee,
  ExpectedSessions,
  CroAdmissionRegister,
  IncomePage,
  AddIncome,
  ExpensePage,
  AddExpense,
  CroEvaluations,
  CroStudentSessionAnalytics,
  CroNotMarkedAttendance,
  CrLedgerView,
  CroEnquiriesFoundationProgramsPending,
  CroEnquiriesClassRoomProgramsPending,
  DayWiseSessions,
  TrainerAttendance,
  EnrollmentDetails,
  CreateTutor,
  AssignTutor,
} from "../../views/cro/myworkspace";
import CroEnquiries from "../../views/crm/myworkspace/CRO Enquiries/croEnquiries";
import CroEnquiryLog from "../../views/crm/myworkspace/CRO Enquiries/CroEnquiryLog/CroEnquiryLog";
import StudentEnrollment from "../../views/cro/myworkspace/Student Enrollment/StudentEnrollment";
import StudentClassRestartDates from "../../views/cro/myworkspace/StudentClassRestartDates/StudentClassRestartDates";
import InactiveStudents from "../../views/cro/myworkspace/InactiveStudents/InactiveStudents";
import TrainerDetails from "../../views/cro/myworkspace/TrainerDetails/TrainerDetails";
import TrainerAttendanceView from "../../views/cro/myworkspace/TrainerDetails/TrainerAttendanceView";
import TrainerMoUDetails from "../../views/cro/myworkspace/TrainerMoUDetails/TrainerMoUDetails";
import { TrainerPaymentVerification } from "../../views/cro/myworkspace/TrainerPaymentVerification/TrainerPaymentVerification";
import MonthlyAccounts from "../../views/cro/myworkspace/Accounts/MonthlyAccounts";
import LedgerView from "../../views/cro/myworkspace/Accounts/LedgerView/LedgerView";
import CROIncomeForecast from "../../views/cro/myworkspace/CROIncomeForecast/CROIncomeForecast";
import PaymentInvoices from "../../views/ogd/myworkspace/Invoice/PaymentInvoices";
import InactiveStudentsDataAnalytics from "../../views/crm/myworkspace/InactiveStudentsDataAnalytics/InactiveStudentsDataAnalytics";
import { TerminationClearance } from "../../views/crm/myworkspace/TerminationClearance/TerminationClearance";
import TcUploadedStudentDetails from "../../views/crm/myworkspace/TcUploadedStudentDetails/TcUploadedStudentDetails";
import CrmIncome from "../../views/crm/myworkspace/Income/CrmIncome";
import CrmExpense from "../../views/crm/myworkspace/Expense/CrmExpense";
import SqaLeads from "../../views/sqa/mytasks/SqaLeads/SqaLeads";
import CroTermfeeCollection from "../../views/cro/myworkspace/CroTermfeeCollection";
import StudyMaterials from "../../views/sqa/mytasks/StudyMaterials/StudyMaterials";
import TrainersPortal from "../../views/ta/mytasks/TrainersPortal/TrainersPortal";
import TrainerPan from "../../views/ta/mytasks/TrainerPan/TrainerPan";
import { TrainersForCros } from "../../views/ta/mytasks/TrainersForCros/TrainersForCros";
import TrainerPayment from "../../views/ta/mytasks/TrainerPayment/TrainerPayment";
import TrainersEvaluation from "../../views/ta/mytasks/TrainersEvaluation";
import MobileAppStudents from "../../views/cro/myworkspace/MobileAppStudents";
import { Invoice } from "../../views/cro/myworkspace/PaymentInvoices/Invoice";
import ReferralAdmissionRegister from "../../views/cro/myworkspace/ReferralAdmissionRegister";
import BaseCourses from "../../views/research/myworkspace/BaseCourses";
import { AttendanceView } from "../../views/cro/myworkspace/AttendanceView";
import { CabinSettings } from "../../views/hr/myworkspace/CabinSettings";
import { SlotSettings } from "../../views/hr/myworkspace/SlotSettings";
import { DashBoard } from "../../views/user/Dashboard/DashboadIndex/DashBoard";
import { MentoringRequest } from "../../views/user/EmployeeServices/mentoring/MentoringRequest";
import { WiseAdmin } from "../../views/crm/myworkspace";
import Enrollment from "../../views/cro/myworkspace/Student Enrollment/Enrollment";
import DepartmentEmployeesDetails from "../../views/user/Dashboard/DashboadIndex/DepartmentEmployeesDetails";
import DigitalMarketingAnalytics from "../../views/user/Dashboard/DigitalMarketing/DigitalMarketingAnalytics";
import CourseRegistration from "../../views/speakpro/myworkspace/CourseRegistration";
import Holidays from "../../views/hr/myworkspace/Holidays";
import OgmLeads from "../../views/ogd/myworkspace/OgmLeads";
import OgaLeads from "../../views/ogd/myworkspace/OgaLeads";
import OgaExpense from "../../views/ogd/myworkspace/OgaExpense";
import NewIncomePage from "../../views/ogd/myworkspace/Income/NewIncomePage";
import ReportingHeadCompWorkReq from "../../views/user/EmployeeServices/CompensatoryWork/ReportingHeadCompWorkReq";
import AdmissionRegisterOgd from "../../views/ogd/myworkspace/AdmissionRegister/AdmissionRegisterOgd";
import InvoiceUpdatedPagination from "../../views/ogd/myworkspace/Invoice/InvoiceUpdatedPagination"; //customdatagrid pagination  page invoice
import IncomeUpdatedPagination from "../../views/ogd/myworkspace/Income/IncomeUpdatedPagination";
import FoodManagement from "../../views/hr/myworkspace/Food/FoodManagement";
import EditAttendance from "../../views/user/mytasks/Attendance/EditAttendance";
import Cart from "../../views/ogd/myworkspace/Cart/Cart";
import CartLeads from "../../views/ogd/myworkspace/Cart/CartLeads";
import Course from "../../views/Course/Course";
import StudentTrack from "../../views/studentTrack/StudentTrack";
import EveningFoodListApproval from "../../views/FoodApproval/EveningFoodListApproval";
import EmiCoursePayment from "../../views/finance/EmiCoursePayment";
import NewHrDashboard from "../../views/Dashboard/hrDashboard/NewHrDashboard";

const MyWorkspaceRouter = () => {
  useRoles("my workspace");
  const { role } = useSelector((state) => state);
  return (
    <Routes>
      {role.navChild.includes("my students") && (
        <Route path="/my-students" element={<MyStudentsTable />} />
      )}
      {role.navChild.includes("food") && (
        <Route path="food" element={<FoodManagement />} />
      )}
      <Route
        path="/*"
        element={
          <HrRoute>
            <HrRouter />
          </HrRoute>
        }
      />
      <Route
        path="/reporting_head_or_hod/*"
        element={
          <HodRoute>
            <HodRouter />
          </HodRoute>
        }
      />
      <Route path="/cro-leads" element={<CroLeads />} />
      <Route path="/cro-lead-update" element={<UpdateLeadForm />} />
      <Route path="/cro-students" element={<CroStudentsView />} />
      <Route path="/cro-course-enroll" element={<CroCourseEnroll />} />
      <Route path="/cro-student-enroll/:cEid" element={<StudentErollForm />} />
      <Route path="/cro-fee-update" element={<UpdateTotalFee />} />
      <Route path="/cro-expected-sessions" element={<ExpectedSessions />} />
      <Route
        path="/cro-admission-register"
        element={<CroAdmissionRegister />}
      />
      <Route path="/crm-leads" element={<CrmLeads />} />
      <Route path="/admission-register" element={<AdmissionRegister />} />
      <Route
        path="/update-expected-session"
        element={<UpdateExpectedSession />}
      />
      {/* income features for cros */}
      <Route path="/cro-income-view" element={<IncomePage />} />
      <Route path="/add-income" element={<AddIncome />} />
      <Route path="/cro-expense-view" element={<ExpensePage />} />
      <Route path="/cro-add-expense" element={<AddExpense />} />
      {/* income features for cros compleated */}
      <Route path="/cro-enquiries" element={<CroEnquiries />} />
      <Route path="/cro-enquiry-log" element={<CroEnquiryLog />} />
      <Route path="/subject-enrollment" element={<StudentEnrollment />} />
      <Route
        path="/cro-enquiries-foundation-pending"
        element={<CroEnquiriesFoundationProgramsPending />}
      />
      <Route
        path="/cro-enquiries-classroom-pending"
        element={<CroEnquiriesClassRoomProgramsPending />}
      />
      <Route
        path="/class-restart-dates"
        element={<StudentClassRestartDates />}
      />
      <Route path="/inactive-students" element={<InactiveStudents />} />
      <Route path="/trainer-details" element={<TrainerDetails />} />
      <Route
        path="/trainer-attendance-view"
        element={<TrainerAttendanceView />}
      />
      <Route path="/trainer-mou-details" element={<TrainerMoUDetails />} />
      <Route
        path="/trainer-payment-verification"
        element={<TrainerPaymentVerification />}
      />
      <Route path="/student-ledger" element={<MonthlyAccounts />} />
      <Route path="/ledger-view" element={<LedgerView />} />
      <Route path="/cro-income-forecast" element={<CROIncomeForecast />} />
      {/* <Route path="/payment-invoices" element={<PaymentInvoices />} /> */}
      <Route path="/payment-invoices" element={<InvoiceUpdatedPagination />} />
      <Route
        path="/inactive-students-data-analytics"
        element={<InactiveStudentsDataAnalytics />}
      />
      <Route path="/day-wise-sessions" element={<DayWiseSessions />} />
      <Route path="/crm-admission-close" element={<TerminationClearance />} />
      <Route
        path="/tc-uploaded-student-details"
        element={<TcUploadedStudentDetails />}
      />
      <Route path="/crm-income" element={<IncomeUpdatedPagination />} />
      {/* <Route path="/crm-income" element={<NewIncomePage />} /> */}
      {/* <Route path="/crm-income" element={<CrmIncome />} /> */}
      <Route path="/crm-expense" element={<CrmExpense />} />
      {/* ---------------SQA--Mytasks--------------------------------------- */}
      <Route path="/sqa-leads" element={<SqaLeads />} />
      <Route path="/study-materials" element={<StudyMaterials />} />
      {/* ---------------------------------------------------------------------------- */}
      {/* ---------------------------TA---Mytasks-------------------------------------- */}
      <Route path="/trainers-portal" element={<TrainersPortal />} />
      <Route path="/trainers-pan" element={<TrainerPan />} />
      <Route path="/trainers-for-cros" element={<TrainersForCros />} />
      <Route path="/trainer-payment" element={<TrainerPayment />} />
      {/* ---------------------------------------------------------------------------- */}
      <Route path="cro-student-evaluations" element={<CroEvaluations />} />
      <Route
        path="cro-student-session-analytics"
        element={<CroStudentSessionAnalytics />}
      />
      <Route
        path="cro-daily-no-attendance-students"
        element={<CroNotMarkedAttendance />}
      />
      <Route
        path="cro-term-fee-collection"
        element={<CroTermfeeCollection />}
      />
      <Route path="mobile-app-students" element={<MobileAppStudents />} />
      <Route path="invoice" element={<Invoice />} />
      <Route path="ta-trainer-evaluation" element={<TrainersEvaluation />} />
      <Route path="cr-Ledger-view" element={<CrLedgerView />} />
      <Route
        path="referral-admission-register"
        element={<ReferralAdmissionRegister />}
      />
      {/* --------------------research------------------------- */}
      <Route path="base-courses" element={<BaseCourses />} />
      <Route path="trainer-attendance" element={<TrainerAttendance />} />
      <Route path="enrollment-details" element={<EnrollmentDetails />} />
      <Route path="attendance-view" element={<AttendanceView />} />
      <Route path="cabin-settings" element={<CabinSettings />} />
      <Route path="slot-settings/:id" element={<SlotSettings />} />
      <Route path="dashboard" element={<DashBoard />} />
      <Route path="mentoring-request" element={<MentoringRequest />} />
      <Route path="wise-admin" element={<WiseAdmin />} />
      <Route path="student-enrollment" element={<Enrollment />} />
      <Route path="create-tutor" element={<CreateTutor />} />
      <Route path="assign-tutor" element={<AssignTutor />} />
      <Route
        path="department-employees-Details"
        element={<DepartmentEmployeesDetails />}
      />
      <Route
        path="digital-marketing-analytics"
        element={<DigitalMarketingAnalytics />}
      />
      <Route path="course-registration" element={<CourseRegistration />} />
      {/* -------------------ogd---------------------------------- */}
      <Route path="ogm-leads" element={<OgmLeads />} />
      <Route path="oga-leads" element={<OgaLeads />} />
      <Route path="oga-expense" element={<OgaExpense />} />
      <Route
        path="reporting-head-comp-work-req"
        element={<ReportingHeadCompWorkReq />}
      />
      <Route path="ogd-admissonRegister" element={<AdmissionRegisterOgd />} />
      <Route path="edit-attendance/:id" element={<EditAttendance />} />
      {/* -------------------------------cart--------------------------------- */}
      <Route path="cart" element={<Cart />} />
      <Route path="cart-leads" element={<CartLeads />} />
      <Route path="course" element={<Course />} />
      <Route path="studentTrack" element={<StudentTrack />} />
      <Route path="emi-course-payment" element={<EmiCoursePayment />} />
      {role.navChild.includes("evening food approval") && (
        <Route
          path="/evening-food-approval"
          element={<EveningFoodListApproval />}
        />
      )}
      <Route path="/hr-dashboard" element={<NewHrDashboard />} /> //for test oga
      testing purpose
    </Routes>
  );
};

export default MyWorkspaceRouter;
