import { Autocomplete, Box, Paper, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GlobalWrapper from "../../components/Wrapper/GlobalWrapper";
import GlobalDatagrid from "../../components/mui/DataGrid/GlobalDatagrid";
import MotionContainer from "../../components/FramerMotion/MotionContainer";

import {
  getCartStudents,
  getEmiCartItem,
} from "../../redux/features/cartSlice";
import { EMI_COURSE_PAYMENT_TABLE_HEAD } from "../../components/datagrid/myworkspace/finance/EmiCoursePaymentTableHead";
import MakeNextPayment from "../ogd/myworkspace/Cart/MakeNextPayment";
import ActionDetails from "../../components/mui/GlobalAnchor/ActionDetails";
import GlobalAnchorList from "../../components/mui/GlobalAnchor/GlobalAnchorList";
const EmiCoursePayment = () => {
  const { paginationData } = useSelector((state) => state.globalDataGrid);
  const { gridSize, pageNum, rowData, openAnchor, search } = paginationData;
  const { loading, cartStudents, emiCartItems } = useSelector(
    (state) => state.cart
  );
  const dispatch = useDispatch();
  const [studentAdmNo, setStudentAdmNo] = useState("");

  useEffect(() => {
    dispatch(getCartStudents());
  }, []);
  const data = {
    page: pageNum,
    limit: gridSize,
    id: studentAdmNo,
  };
  useEffect(() => {
    if (studentAdmNo) {
      dispatch(getEmiCartItem(data));
    }
  }, [studentAdmNo]);

  useEffect(() => {
    const datas = {
      ...data,
      search: search,
    };
    // dispatch(getOgmLeads(datas));
  }, [pageNum, gridSize, search]);
  return (
    <GlobalWrapper title={"EMI Course Payments"}>
      <Box sx={{ width: "30%", pb: 2 }}>
        <MotionContainer delay={0.7}>
          <Autocomplete
            disablePortal
            name="Students"
            id="combo-box-demo"
            options={cartStudents}
            getOptionLabel={(option) => option.name}
            onChange={(e, option, value) => {
              setStudentAdmNo(option.admnNo);
            }}
            renderInput={(params) => (
              <TextField
                disabled
                fullWidth
                {...params}
                label="Student"
                //   error={formik.touched.subject && Boolean(formik.errors.subject)}
                //   helperText={formik.errors.subject}
              />
            )}
          />
        </MotionContainer>
      </Box>

      <Paper elevation={3}>
        <Box sx={{ width: "100%", padding: 0 }}>
          <GlobalDatagrid
            tableHead={EMI_COURSE_PAYMENT_TABLE_HEAD}
            rows={emiCartItems?.data}
            meta={emiCartItems?.meta?.totalRecords || 0}
            rowUniqueId="sl_no"
            loading={loading}
            rowClick={true}
            actionComponent={
              <>
                <ActionDetails val={rowData?.createdBy} />
                <GlobalAnchorList
                  label="Make Payment"
                  component={
                    <MakeNextPayment
                      updateData={data}
                      emi={true}
                      paidItem={rowData}
                    />
                  }
                />
              </>
            }
          />
        </Box>
      </Paper>
    </GlobalWrapper>
  );
};

export default EmiCoursePayment;
