import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiRequest from "../../api/request";
import { formatDataForDataTable } from "../../utils/formatData";

const initialState = {
  data: [],
  loading: false,
  error: "",
  analytics: [],
  applications: [],
  singleApplication: {},
  leaveBypassCounts: [],
  empCompData: [],
  rhCompWorkReq: [],
  availableCompData: [],
  availableLeaveCredits: [],
};

export const getLeaveTypes = createAsyncThunk(
  "leave/getLeaveTypes",
  async () => {
    const res = await apiRequest({
      method: "get",
      url: "leave/getLeaveTypes",
    });
    return res;
  }
);

export const getLeaveAnalytics = createAsyncThunk(
  "leave/getAnalytics",
  async (e) => {
    const res = await apiRequest({
      method: "get",
      url: `analytics/leaveAnalytics/${e}`,
    });
    return res;
  }
);
export const applyLeave = createAsyncThunk("leave/apply", async (e) => {
  const res = await apiRequest({
    url: "leave/apply",
    method: "post",
    data: e,
  });
  return res;
});

export const getLeaveAplictions = createAsyncThunk(
  "leave/getApplications",
  async (year) => {
    const res = await apiRequest({
      url: `leave/getLeaveApplications/${year}`,
      method: "get",
    });
    return res;
  }
);

export const getLeaveAplictionsforReportingHead = createAsyncThunk(
  "leave/getApplications_Rph",
  async (filter) => {
    const res = await apiRequest({
      url: `leave/getReportingHeadLeaveApplications/${filter}`,
      method: "get",
    });
    // console.log(res);

    return res;
  }
);

export const getLeaveAplictionsforHr = createAsyncThunk(
  "leave/get_applications_hr",
  async (str) => {
    const res = await apiRequest({
      url: `leave/getAllLeaveApplications/${str}`,
      method: "get",
    });

    return res;
  }
);

export const getLeaveApplicationById = createAsyncThunk(
  "leave/leave_apltn_by_id",
  async (id) => {
    const res = await apiRequest({
      method: "get",
      url: `leave/getLeaveApplication/${id}`,
    });
    return res;
  }
);

export const editLeave = createAsyncThunk("leave/edit", async (values) => {
  const res = await apiRequest({
    url: "leave/editLeaveApplication",
    method: "put",
    data: values,
  });
  return res;
});

export const deleteLeave = createAsyncThunk("leave/delete", async (id) => {
  const res = await apiRequest({
    method: "delete",
    url: `leave/deleteLeaveApplication/${id}`,
  });
  return res;
});

export const getBypassCount = createAsyncThunk(
  "leave/bypassCount",
  async (date) => {
    const res = await apiRequest({
      method: "get",
      url: `analytics/leaveBypassCounts/${date}`,
    });
    return res;
  }
);

export const approveLeaveReportingHead = createAsyncThunk(
  "leave/approve_leave_hod",
  async (data) => {
    const res = await apiRequest({
      url: "leave/reportingHeadApproval",
      method: "post",
      data: data,
    });

    return res;
  }
);

export const approveLeaveHr = createAsyncThunk(
  "leave/approve_leave_Hr",
  async (data) => {
    const res = await apiRequest({
      url: "leave/HRApproval",
      method: "post",
      data: data,
    });

    return res;
  }
);

export const getCompWorkReq = createAsyncThunk(
  "leave/getCompWorkReq",
  async (data) => {
    const res = await apiRequest({
      url: `leave/compLeave?page=${data.page}&limit=${data.limit}&search=${
        data.search ? encodeURIComponent(data.search) : ""
      }`,
      method: "get",
    });

    return res;
  }
);
export const deleteComWorkreq = createAsyncThunk(
  "leave/deleteComWorkreq",
  async (id) => {
    const res = await apiRequest({
      url: `leave/compLeave/${id}`,
      method: "delete",
    });

    return res;
  }
);
export const applyCompWorkReq = createAsyncThunk(
  "leave/applyCompWorkReq",
  async (data) => {
    const res = await apiRequest({
      url: `leave/compLeave`,
      method: "post",
      data: data,
    });

    return res;
  }
);
export const updateCompWork = createAsyncThunk(
  "leave/updateCompWork",
  async (data) => {
    const res = await apiRequest({
      url: `leave/compLeave/${data.id}`,
      method: "patch",
      data: data,
    });

    return res;
  }
);

export const getReportingHeadCompWorkReq = createAsyncThunk(
  "leave/getReportingHeadCompWorkReq",
  async (data) => {
    const res = await apiRequest({
      url: `leave/compLeave/approve?page=${data.page}&limit=${
        data.limit
      }&search=${data.search ? encodeURIComponent(data.search) : ""}`,
      method: "get",
    });

    return res;
  }
);
export const approveActions = createAsyncThunk(
  "leave/approveActions",
  async (data) => {
    const res = await apiRequest({
      url: `leave/compLeave/approve/${data.id}`,
      method: "patch",
      data: data,
    });

    return res;
  }
);
export const getAvailableCompLeave = createAsyncThunk(
  "leave/getAvailableCompLeave",
  async () => {
    const res = await apiRequest({
      url: `leave/compLeave/available`,
      method: "get",
    });

    return res;
  }
);

export const getAvailableLeaveCredits = createAsyncThunk(
  "leave/getAvailableLeaveCredits",
  async (data) => {
    const res = await apiRequest({
      url: `leave/getLeaveCount`,
      method: "post",
      data: data,
    });

    return res;
  }
);

const leaveSlice = createSlice({
  name: "leave",
  initialState,
  reducers: {
    clearApplications: (state, action) => {
      return {
        ...state,
        applications: [],
      };
    },
    clearAllLeaveData: (state) => {
      state = initialState;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLeaveTypes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLeaveTypes.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
    });
    builder.addCase(getLeaveTypes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // leave analytics

    builder.addCase(getLeaveAnalytics.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getLeaveAnalytics.fulfilled, (state, action) => {
      return {
        ...state,
        analytics: action.payload.data.analytics,
        loading: false,
      };
    });

    // apply leave

    builder.addCase(applyLeave.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(applyLeave.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
      };
    });

    // get leave applications

    builder.addCase(getLeaveAplictions.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getLeaveAplictions.fulfilled, (state, action) => {
      const applications = formatDataForDataTable(action.payload.data);
      return {
        ...state,
        applications: applications,
        loading: false,
      };
    });

    // getLeaveApplicationBy id

    builder.addCase(getLeaveApplicationById.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });

    builder.addCase(getLeaveApplicationById.fulfilled, (state, action) => {
      return {
        ...state,
        singleApplication: action.payload.data,
        loading: false,
      };
    });

    // get leave applications for reporting head

    builder.addCase(getLeaveAplictionsforReportingHead.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(
      getLeaveAplictionsforReportingHead.fulfilled,
      (state, action) => {
        const applications = formatDataForDataTable(
          action.payload.data.leaveTypes
        );
        return {
          ...state,
          applications: applications,
          loading: false,
        };
      }
    );
    // leave applications for hr
    builder.addCase(getLeaveAplictionsforHr.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getLeaveAplictionsforHr.fulfilled, (state, action) => {
      const applications = formatDataForDataTable(
        action.payload.data.leaveTypes
      );
      return {
        ...state,
        applications: applications,
        loading: false,
      };
    });

    // delete leave

    builder.addCase(deleteLeave.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });

    builder.addCase(deleteLeave.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
      };
    });
    //bypassCount
    builder.addCase(getBypassCount.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });

    builder.addCase(getBypassCount.fulfilled, (state, action) => {
      const leaveBypassCounts = formatDataForDataTable(
        action.payload.data.leaveBypassCounts
      );
      return {
        ...state,
        leaveBypassCounts: leaveBypassCounts,
        loading: false,
      };
    });
    // -----------------------getCompWorkReq----------------------
    builder.addCase(getCompWorkReq.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });

    builder.addCase(getCompWorkReq.fulfilled, (state, action) => {
      const data = action.payload;
      return {
        ...state,
        empCompData: data,

        loading: false,
      };
    });
    // -----------------applyCompWorkReq--------------------------------
    builder.addCase(applyCompWorkReq.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });

    builder.addCase(applyCompWorkReq.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
      };
    });
    // -----------------deleteComWorkreq----------------------------
    builder.addCase(deleteComWorkreq.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });

    builder.addCase(deleteComWorkreq.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
      };
    });
    // ----------------updateCompWork----------------------------
    builder.addCase(updateCompWork.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });

    builder.addCase(updateCompWork.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
      };
    });
    // ----------------getReportingHeadCompWorkReq-------------------------------
    builder.addCase(getReportingHeadCompWorkReq.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });

    builder.addCase(getReportingHeadCompWorkReq.fulfilled, (state, action) => {
      const data = action.payload;
      return {
        ...state,
        rhCompWorkReq: data,
        loading: false,
      };
    });
    // --------------approveActions--------------------------------
    builder.addCase(approveActions.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });

    builder.addCase(approveActions.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
      };
    });
    // ------------getAvailableCompLeave--------------------------
    builder.addCase(getAvailableCompLeave.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });

    builder.addCase(getAvailableCompLeave.fulfilled, (state, action) => {
      return {
        ...state,
        availableCompData: formatDataForDataTable(action.payload.data),
        loading: false,
      };
    });
    // -----------------getAvailableLeaveCredits----------------------------
    builder.addCase(getAvailableLeaveCredits.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });

    builder.addCase(getAvailableLeaveCredits.fulfilled, (state, action) => {
      return {
        ...state,
        availableLeaveCredits: action.payload.data,
        loading: false,
      };
    });
  },
});
export const { clearAllLeaveData, clearApplications } = leaveSlice.actions;
export default leaveSlice.reducer;
