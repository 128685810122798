import React, { useEffect, useState } from 'react'
import GlobalWrapper from '../../../../components/Wrapper/GlobalWrapper'
import AddOrUpdateWorkingHours from '../../../../components/datagrid/EmployeeService/PostWorkingHours/AddOrUpdateWorkingHours'
import {
  Box,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import GlobalDatagrid from '../../../../components/mui/DataGrid/GlobalDatagrid'
import { useDispatch, useSelector } from 'react-redux'
import { postWorkingTableHeader } from '../../../../components/datagrid/EmployeeService/PostWorkingHours/postWorkingTableHeader'
import {
  getAllDepartmenst,
  getAllPostWorkingHours,
  getAllPostWorkingHoursForHr,
  PostWorkingApproveOrReject,
} from '../../../../redux/features/postWorkingHoursSlice'
import DatePickerCustom from '../../../../components/DatePicker/DatePickerCustom'
import dayjs from 'dayjs'
import ActionDetails from '../../../../components/mui/GlobalAnchor/ActionDetails'
import GlobalAnchorList from '../../../../components/mui/GlobalAnchor/GlobalAnchorList'
import DeleteWorkingHour from '../../../../components/datagrid/EmployeeService/PostWorkingHours/DeleteWorkingHour'
import ApproveGlobal from '../../../../components/datagrid/EmployeeService/PostWorkingHours/ApproveGlobal'
import RejectGlobal from '../../../../components/datagrid/EmployeeService/PostWorkingHours/RejectGlobal'
import useResponsive from '../../../../Hooks/useResponsive'
import PostWorkingBulkApprove from './PostWorkingBulkApprove'
import { savePaginationData } from '../../../../redux/features/globalDatagridSlice'
import GlobalMultiSelect from '../../../../components/mui/GlobalMultiSelect'

const PostWorking = ({ isHr }) => {
  const [direction, setDirection] = useState('row')
  const mdDown = useResponsive('down', 'md')
  const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'))
  const { resData, loading, departments } = useSelector((store) => store.postWorking)
  const { data, meta } = resData

  const dispatch = useDispatch()
  const { paginationData } = useSelector((state) => state.globalDataGrid)
  const { gridSize, pageNum, rowData, openAnchor, search, checkedRowsIds } = paginationData
  const [departmentNames, setDepartmentNames] = useState([])
  const [isDisabled,setIsDisabled]=useState(false)

  const dispatchParams = {
    selectedDate,
    paginationData,
    departmentNames,
  }

  useEffect(() => {
    if (mdDown) {
      setDirection('column')
    } else {
      setDirection('row')
    }
  }, [mdDown])

  useEffect(() => {
    dispatch(getAllDepartmenst())
  }, [])

  useEffect(() => {
    if (meta && !isHr) {
      dispatch(savePaginationData({ ...paginationData, openAnchor: false, gridSize: meta?.totalRecords }))
    }
  }, [meta])

  useEffect(() => {
    //DATE OR PAGINATION STATE CHANGE IT WILL TRIGGER API CALL
    if (pageNum) {
      if (isHr) {
        dispatch(getAllPostWorkingHoursForHr(dispatchParams))
      } else {
        dispatch(getAllPostWorkingHours(dispatchParams))
      }
    }
  }, [gridSize, pageNum, search, selectedDate, departmentNames])

  useEffect(()=>{
    const now=dayjs()
    const startTime=dayjs().set('hour',17).set('minute',29) //5:30pm
    const endTime=dayjs().set('hour',18).set('minute',30) //6:30pm
  if(now.isAfter(startTime)&& now.isBefore(endTime)){
    setIsDisabled(false)
  }else{
    setIsDisabled(true)
  }
  },[pageNum])
  return (
    <GlobalWrapper
      title={'Post Working Hours'}
      actions={
        <>
          {!isHr && (
            <Stack gap={1} direction={direction}>
              <AddOrUpdateWorkingHours dispatchParams={dispatchParams} />
              {checkedRowsIds?.length > 1 && (
                <PostWorkingBulkApprove
                  checkedRowsIds={checkedRowsIds}
                  upDateCall={getAllPostWorkingHours(dispatchParams)}
                  titleNote={"Approving Time : 5:30 PM to 6:30 PM"}
                  disabled={isDisabled}
                />
              )}
            </Stack>
          )}
        </>
      }>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: direction }}>
        <Stack gap={1} direction={direction} sx={{alignItems:mdDown?"":"center"}}>
          <Box>
            <DatePickerCustom
              value={dayjs(selectedDate)}
              setValue={(date) => setSelectedDate(date.format('YYYY-MM-DD'))}
              viewDetails={['year', 'month', 'day']}
              error={false}
            />
          </Box>
          <Box>
          <marquee
            direction="right"
            style={{ fontSize: '0.5 rem', color: '#212b36', fontWeight: 'bold', letterSpacing: '1px' }}>
            <span style={{ textShadow: '1px 1px 3px rgba(0,0,0,0.3)' }}>Post Working Request Time : 2:30 PM TO 5:30 PM</span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span style={{ textShadow: '1px 1px 3px rgba(0,0,0,0.3)' }}>Post Working Approval Time : 5:30 PM TO 6:30 PM</span>
          </marquee>
        </Box>
          {isHr && (
            <>
              <Box>
                <GlobalMultiSelect
                  label={'Department Filter'}
                  sx={{ maxWidth: '600px' }}
                  arrayState={departmentNames}
                  arraySetState={setDepartmentNames}
                  data={departments}
                  keys={{ id: 'departmentId', value: 'departmentName' }}
                />
              </Box>
            </>
          )}
        </Stack>
        {isHr && (
          <Box sx={{ display: 'flex', alignItems: 'center', pt: direction == 'row' ? 3 : 1, px: 1 }}>
            <Typography variant="subtitle1">Total Employees : {meta?.totalRecords || 0}</Typography>
          </Box>
        )}
      </Box>
      <Paper sx={{ mt: 1 }}>
        <GlobalDatagrid
          checkboxSelection={data?.isApprover ? true : isHr ? false : false}
          tableHead={postWorkingTableHeader(data?.data)}
          rows={Array.isArray(data?.data) ?data?.data:[]}
          meta={meta?.totalRecords}
          rowUniqueId="id"
          loading={loading}
          rowClick={isHr ? false : true}
          actionComponent={
            <>
              <ActionDetails val={rowData?.createdBy} />
              <GlobalAnchorList
                label="Update"
                component={<AddOrUpdateWorkingHours dispatchParams={dispatchParams} row={rowData} />}
              />
              <GlobalAnchorList
                label="Delete"
                component={
                  <DeleteWorkingHour
                    requestId={rowData?.id}
                    isDisabled={rowData?.status !== 'Pending' || rowData?.editor == 0}
                    dispatchParams={dispatchParams}
                  />
                }
              />

              {(data?.isApprover || false) && rowData?.status == 'Pending' && (
                <>
                  <GlobalAnchorList
                    label="Approve"
                    component={
                      <ApproveGlobal
                        statusChangeApiCall={PostWorkingApproveOrReject({ ids: [rowData?.id], status: 1 })}
                        loading={loading}
                        upDateCall={getAllPostWorkingHours(dispatchParams)}
                        isDisabled={isDisabled}
                      />
                    }
                  />
                  <GlobalAnchorList
                    label="Reject"
                    component={
                      <RejectGlobal
                        statusChangeApiCall={PostWorkingApproveOrReject({ ids: rowData?.id, status: 2 })}
                        loading={loading}
                        upDateCall={getAllPostWorkingHours(dispatchParams)}
                        isDisabled={isDisabled}
                      />
                    }
                  />
                </>
              )}
            </>
          }
        />
      </Paper>
    </GlobalWrapper>
  )
}

export default PostWorking
