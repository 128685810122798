import React, { useEffect, useRef, useState } from 'react'
import GlobalWrapper from '../../../../components/Wrapper/GlobalWrapper'
import { AppBar, Box, Button, Container, Dialog, Divider, IconButton, Stack, Toolbar, Typography } from '@mui/material'
import Slide from '@mui/material/Slide'
import CloseIcon from '@mui/icons-material/Close'
import GlobalDialog from '../../../../components/mui/Dialogue/GlobalDialog'
import GlobalFullDialog from '../../../../components/mui/Dialogue/GlobalFullDialog'
import { Icon } from '@iconify/react/dist/iconify.js'
import { useReactToPrint } from 'react-to-print'
import { DataGrid } from '@mui/x-data-grid'
import { useDispatch, useSelector } from 'react-redux'
import { getInvoice, getInvoiceTemplate } from '../../../../redux/features/invoiceSlice'
import SimpleLoader from '../../../../components/Loader/SimpleLoader'
import dayjs from 'dayjs'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const InvoicePrint = ({ invoiceId, isVerified }) => {
  let componentRef = useRef(null)
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()
  const { loading, invoiceTemplate } = useSelector((store) => store.invoice)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    dispatch(getInvoiceTemplate(invoiceId))
  }, [open])

  const downloadPdf = useReactToPrint({
    //BROWSER PREVIEW PAGE TO DOWNLOAD AND PRINT
    content: () => componentRef.current,
    documentTitle: `Invoice`,
    onPrintError: () => alert('error occured'),
    // onAfterPrint: () => setconfirmedAlert(false),
  })
  const col = [
    {
      field: 'description',
      headerName: 'Description',
      headerClassName: 'super-app-theme--header',
      flex: 3, // 30% of the width
      renderCell: (params) => (
        <Box sx={{ paddingLeft: 1 }}>{params.value}</Box> // Apply padding left
      ),
      renderHeader: (params) => (
        <Box sx={{ paddingLeft: 2 }}>{params.colDef.headerName}</Box> // Apply padding left to the header
      ),
    },
    {
      field: 'courseFee',
      headerName: 'Course Fee',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      flex: 1, // 20% of the width
      // renderCell: (params) => `₹ ${parseFloat(params.value).toFixed(2)}`
      renderCell: (params) => `₹ ${params.value}`,
    },
    {
      field: 'discount',
      headerName: 'Discount',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      flex: 1, // 10% of the width
      renderCell: (params) => `${params.value}%`,
    },
    {
      field: 'fee',
      headerName: 'Fee',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      flex: 1, // 10% of the width
      // renderCell: (params) => `₹ ${parseFloat(params.value).toFixed(2)}`,
      renderCell: (params) => `₹ ${params.value}`,
    },
    {
      field: 'cgst',
      headerName: 'CGST (9%)',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      flex: 1, // 10% of the width
      renderCell: (params) => `₹ ${params.value}`,
    },
    {
      field: 'sgst',
      headerName: 'SGST (9%)',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      flex: 1, // 10% of the width
      renderCell: (params) => `₹ ${params.value}`,
    },
    {
      field: 'total',
      headerName: 'Total',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      flex: 1, // 10% of the width
      renderCell: (params) => `₹ ${params.value}`,
    },
  ]

  const aedCol = [
    {
      field: 'description',
      headerName: 'Description',
      headerClassName: 'super-app-theme--header',
      flex: 3,
      renderCell: (params) => <Box sx={{ paddingLeft: 1 }}>{params.value}</Box>,
      renderHeader: (params) => (
        <Box sx={{ paddingLeft: 2 }}>{params.colDef.headerName}</Box> // Apply padding left to the header
      ),
    },
    {
      field: 'courseFee',
      headerName: 'Course Fee',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => `AED ${parseFloat(params.value).toFixed(2)}`,
    },
    {
      field: 'discount',
      headerName: 'Discount',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => `${params.value}%`,
    },
    {
      field: 'fee',
      headerName: 'Fee',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => `AED ${parseFloat(params.value).toFixed(2)}`,
    },

    {
      field: 'total',
      headerName: 'Total',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      flex: 1, // 10% of the width
      renderCell: (params) => `AED ${parseFloat(params.value).toFixed(2)}`,
    },
  ]

  let modifiedRow = invoiceTemplate?.courseDetails?.map((data, index) => ({
    ...data,
    id: index + 1,
  }))

  if (invoiceTemplate?.admissionFee == 1) {
    const admissionFeeDetails = invoiceTemplate?.isforiegnMoney
      ? {
          id: 0,
          description: 'Admission Fee',
          courseFee: 25,
          discount: 0,
          fee: 25,
          total: 25,
        }
      : {
          id: 0,
          description: 'Admission Fee',
          courseFee: 500,
          discount: 0,
          fee: 423.72,
          cgst: 38.14,
          sgst: 38.14,
          total: 500,
        }

    modifiedRow = [...modifiedRow, admissionFeeDetails].sort((a, b) => a.id - b.id)
  }

  return (
    <>
      <GlobalFullDialog
        open={open}
        setOpen={setOpen}
        title={'Invoice'}
        popupButton={
          <>
            <Button size="small" disabled={isVerified == 0} variant="outlined" onClick={handleClickOpen}>
              View
            </Button>
          </>
        }
        content={
          <Container sx={{ p: 1 }}>
            <GlobalWrapper
              // title={"Invoice Preview"}
              actions={
                <Box>
                  <Button variant="contained" onClick={downloadPdf}>
                    <Icon icon="ion:print-outline" height="25" width="25" />
                    Download Invoice
                  </Button>
                </Box>
              }>
              {!loading && Object.keys(invoiceTemplate).length > 0 ? (
                <Box className="print-content">
                  <Box
                    sx={{
                      p: 1,
                      width: '1000px',
                      //  height: "1450px",
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                    ref={componentRef}>
                    <Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box>
                          <img
                            height="45"
                            src="https://www.intervaledu.com/static/web/images/logo/logo-dark.png"
                            alt="header"
                          />
                        </Box>
                        <Box>
                          <Stack>
                            <Typography color="#008fc3" variant="h6">
                              INVOICE
                            </Typography>
                            <Typography variant="subtitle2" fontWeight={'bolder'}>
                              Invoice No : {invoiceTemplate?.invoiceId}
                            </Typography>
                            <Typography variant="subtitle2" fontWeight={'bolder'}>
                              Date : {dayjs(invoiceTemplate?.invoiceDate).format('DD-MM-YYYY')}
                            </Typography>
                          </Stack>
                        </Box>
                      </Box>

                      <Box>
                        <Typography variant="h6">Student Details</Typography>
                        <Typography variant="subtitle2">Name : {invoiceTemplate.studentName}</Typography>
                        <Typography variant="subtitle2">Class : {invoiceTemplate.class}</Typography>
                        <Typography variant="subtitle2">
                          Place : {invoiceTemplate.place ? invoiceTemplate.place : ''}
                        </Typography>
                        <Typography variant="subtitle2">OGA : {invoiceTemplate.ogaName}</Typography>
                      </Box>

                      <Box sx={{ my: 3 }}>
                        <Divider sx={{ borderBottomWidth: 2 }} />
                      </Box>

                      <Box
                        className="invoiceDatagrid"
                        sx={{
                          borderRadius: 1.2,

                          border: '1px solid #ddd', // Border around the whole table
                          '& .super-app-theme--header': {
                            backgroundColor: '#7e4d8b', // Header background color
                            color: 'white', // White text for the header
                            fontWeight: 'bold', // Make header text bold
                            textAlign: 'center', // Center-align header text
                            padding: '8px 0', // Add padding inside headers
                          },
                          '& .MuiDataGrid-cell': {
                            padding: '8px', // Add padding inside cells for better readability
                            borderBottom: '1px solid #ddd', // Add a bottom border between rows
                          },
                          '& .MuiDataGrid-row:nth-of-type(odd)': {
                            backgroundColor: 'white', // Odd rows
                          },
                          '& .MuiDataGrid-row:nth-of-type(even)': {
                            backgroundColor: '#f5f5f5', // Even rows (light gray)
                          },
                        }}>
                        <DataGrid
                          rows={modifiedRow}
                          columns={
                            invoiceTemplate?.isforiegnMoney
                              ? aedCol
                              : col.map((column) => ({
                                  ...column,
                                  headerAlign: column.headerName == 'Description' ? 'left' : 'center', // Center-align all headers
                                  align: column.headerName == 'Description' ? 'left' : 'center',
                                  // align: column.type === "number" ? "right" : "left", // Right-align numeric columns
                                }))
                          }
                          autoHeight={true}
                          disableColumnMenu
                          hideFooter
                          disableSelectionOnClick
                        />
                      </Box>

                      <Box sx={{ mt: 4, mb: 2 }}>
                        <Divider sx={{ borderBottomWidth: 2 }} />
                      </Box>

                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box>{/* Notes : */}</Box>
                        <Box sx={{ mr: 1 }}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}>
                            <Typography color="#008fc3" variant="subtitle2">
                              GRAND TOTAL :
                            </Typography>
                            <Typography sx={{ pl: 1 }} variant="body">
                              {' '}
                              {invoiceTemplate?.isforiegnMoney ? 'AED' : '₹'}{' '}
                              {modifiedRow.reduce((acc, data) => acc + parseFloat(data.total), 0).toFixed(2)}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}>
                            <Typography color="#008fc3" variant="subtitle2">
                              ADVANCE :
                            </Typography>
                            <Typography variant="body">
                              {invoiceTemplate?.isforiegnMoney ? 'AED' : '₹'}{' '}
                              {parseFloat(
                                invoiceTemplate?.isforiegnMoney
                                  ? invoiceTemplate?.advanceForeign
                                  : invoiceTemplate.advanceInr,
                              ).toFixed(2)}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}>
                            <Typography variant="subtitle1">BALANCE :</Typography>
                            <Typography variant="body">
                              {invoiceTemplate?.isforiegnMoney
                                ? `AED ${parseFloat(
                                    modifiedRow.reduce((acc, data) => acc + parseFloat(data.total), 0) -
                                      invoiceTemplate?.advanceForeign,
                                  ).toFixed(2)}`
                                : `₹ ${parseFloat(
                                    modifiedRow.reduce((acc, data) => acc + parseFloat(data.total), 0) -
                                      invoiceTemplate?.advanceInr,
                                  ).toFixed(2)}`}{' '}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>

                      <Box>
                        {/* <Box sx={{mb:1 }}>
                       <img
                         src="https://cdn.britannica.com/17/155017-050-9AC96FC8/Example-QR-code.jpg"
                         alt="qrcode"
                         height={100}
                         width={100}
                       />
                     </Box> */}
                        {/* <Box>
                       <Stack>
                         <Typography variant="caption">
                           GSTIN : 32ABFCS2058H1ZI
                         </Typography>
                         <Typography variant="caption">
                           SAC CODE : 9992
                         </Typography>
                         <Typography variant="caption">
                           SANCO INDIA EDU SOLUTIONS PRIVATE LIMITED
                         </Typography>
                       </Stack>
                     </Box> */}
                      </Box>
                    </Box>

                    <Box sx={{ mt: 5 }}>
                      {/* <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography variant="caption">
                     7th Floor, HiLITE Business Park, NH66, Thondayad Bypass,
                     Kozhikode 673014
                   </Typography>
                 </Box> */}
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography variant="caption">SANCO INDIA EDU SOLUTIONS PRIVATE LIMITED</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography variant="caption">GSTIN : 32ABFCS2058H1ZI</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <SimpleLoader />
              )}
            </GlobalWrapper>
          </Container>
        }
      />
    </>
  )
}

export default InvoicePrint
