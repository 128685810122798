import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getAllDepartments, getEmployeesUnderTheDepartment } from '../../../redux/features/studentPortalSlice'
import GlobalDialog from '../../mui/Dialogue/GlobalDialog'
import { UpdateButton } from '../../mui/Buttons/Buttons'
import { Autocomplete, Box, Stack, TextField } from '@mui/material'
import { useFormik, FormikProvider, Form } from 'formik'
import * as Yup from 'yup'
const HeadOrAssigneeAction = ({row, statusJson, dispatchParams}) => {
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { loading, departments, employees } = useSelector((store) => store.studentPortal)
    const { paginationData } = useSelector((state) => state.globalDataGrid)
    const [open, setOpen] = useState(false)
    const [status, setStatus] = useState({
      id: '',
      value: '',
    })
  
    const initialValues = {
      // admin: isAdmin ? true : false,
      status: row ? statusJson?.find((sts) => sts.value == row.status)?.id : '',
      adminRemarks: row ? row.adminRemarks : '',
      assigneeRemarks: row ? row.assigneeRemarks : '',
      departmentId: row ? row?.deptId : '',
      userId: row ? row?.userId : '',
    }
    const schema = Yup.object({
      // status: Yup.number().required('Status Required'),
      departmentId: Yup.number().required('Department Required'),
      userId: Yup.number().required('User Required'),
      // adminRemarks: Yup.string().required('Admin Remarks  Required'),
      // assigneeRemarks: Yup.string().required('Assignee Remarks  Required'),
    })
  
    const formik = useFormik({
      initialValues,
      enableReinitialize: true,
      validationSchema: schema,
      onSubmit: (values) => {
        console.log(values)
        const statusName = statusJson?.find((sts) => sts.id == values.status).value
        console.log(statusName)
        let updatedData = {}
        // dispatch(updatePostWorking(updatedData)).then((res) => {
        //   if (res.payload.status === 'error') {
        //     enqueueSnackbar(res.payload.message, {
        //       variant: 'error',
        //     })
        //   } else if (res.payload.status === 'success') {
        //     handleCancel()
        //     dispatch(savePaginationData({ ...paginationData, openAnchor: false }))
        //     if (isAdmin) {
        //       //ADMIN GET PAGE WITH DISPATCH PARAMS
        //     } else {
        //       //ASSIGNEE GET PAGE WITH PARAMS
        //     }
  
        //     enqueueSnackbar(res.payload.message, {
        //       variant: 'success',
        //     })
        //   }
        // })
      },
    })
  
    useEffect(() => {
      if (open) {
        dispatch(getAllDepartments())
      }
    }, [open])
  
    useEffect(() => {
      if (formik.values.departmentId) {
        dispatch(getEmployeesUnderTheDepartment(formik.values.departmentId))
      }
    }, [formik.values.departmentId])
  
    const handleCancel = () => {
      setOpen(false)
      formik.resetForm()
    }
    const handleDepartmentChange = (e, option) => {
      formik.setFieldValue('departmentId', option.departmentId)
    }
    const handleUserChange = (e, option) => {
      formik.setFieldValue('userId', option.userId)
    }
  return (
    <GlobalDialog
    maxWidth={'sm'}
    fullWidth
    title={'Action'}
    open={open}
    setOpen={setOpen}
    closeButton={true}
    closeCondition={handleCancel}
    popupButton={
      <>
        <UpdateButton
          //   disabled={row.status !== 'Pending'}
          size="small"
          action={() => setOpen(true)}>
          Assign
        </UpdateButton>
      </>
    }
    content={
      <>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <Stack gap={2}>
              {/* <GlobalSelectField
                disabled={loading}
                options={{
                  formik: formik,
                  label: 'Status',
                  name: 'status',
                  sx: {
                    width: '100%',
                  },
                  data: statusJson,

                  keys: {
                    id: 'id',
                    value: 'value',
                  },
                }}
              /> */}
              <Box>
                <Box sx={{ flex: 1 }}>
                  <Autocomplete
                    name="departments"
                    options={departments}
                    getOptionLabel={(option) => option.departmentName || ''}
                    value={departments?.find((dept) => dept?.departmentId == formik.values.departmentId) || null}
                    onChange={handleDepartmentChange}
                    renderInput={(params) => (
                      <TextField
                        disabled
                        fullWidth
                        autoComplete="off"
                        {...params}
                        label="Departments"
                        error={formik.touched.departmentId && Boolean(formik.errors.departmentId)}
                        helperText={formik.touched.departmentId && formik.errors.departmentId}
                      />
                    )}
                  />
                </Box>
              </Box>

              <Box>
                <Box sx={{ flex: 1 }}>
                  <Autocomplete
                    name="userId"
                    options={employees}
                    getOptionLabel={(option) => option.userName || ''}
                    value={employees?.find((dept) => dept?.userId == formik.values.userId) || null}
                    onChange={handleUserChange}
                    renderInput={(params) => (
                      <TextField
                        disabled
                        fullWidth
                        autoComplete="off"
                        {...params}
                        label="User"
                        error={formik.touched.userId && Boolean(formik.errors.userId)}
                        helperText={formik.touched.userId && formik.errors.userId}
                      />
                    )}
                  />
                </Box>
              </Box>

              <Box>
                <TextField
                  autoComplete="off"
                  fullWidth
                  id="adminRemarks"
                  label="Admin Remarks"
                  name="adminRemarks"
                  value={formik.values.adminRemarks}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.adminRemarks && Boolean(formik.errors.adminRemarks)}
                  helperText={formik.touched.adminRemarks && formik.errors.adminRemarks}
                />
              </Box>
              <Box>
                <TextField
                  autoComplete="off"
                  fullWidth
                  id="assigneeRemarks"
                  label="Assignee Remarks"
                  name="assigneeRemarks"
                  value={formik.values.assigneeRemarks}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.assigneeRemarks && Boolean(formik.errors.assigneeRemarks)}
                  helperText={formik.touched.assigneeRemarks && formik.errors.assigneeRemarks}
                />
              </Box>

              <Box>
                <UpdateButton disabled={loading} type="submit">
                  {loading ? 'Assigning...' : 'Assign'}
                </UpdateButton>
              </Box>
            </Stack>
          </Form>
        </FormikProvider>
      </>
    }
  />
  )
}

export default HeadOrAssigneeAction