import {
  Box,
  Checkbox,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";

const WishListitem = ({
  data,
  recent,
  setAvailableCartItems,
  availableCartItems,
  recentwishListItems,
  setTotalCourses,
  totalCourses,
}) => {
  // Handle checkbox changes and data updates
  const handleInputChange = (e, courseId, field) => {
    const { checked } = e.target;

    const updatedCourses = availableCartItems.map((item) => {
      if (item.id === courseId) {
        return { ...item, [field]: checked };
      }
      return item;
    });
    setAvailableCartItems(updatedCourses);
  };

  const handleRecentInputChange = (e, courseId, field) => {
    const { checked } = e.target;
    const updatedCourses = totalCourses.map((item) => {
      if (item.id === courseId) {
        return { ...item, [field]: checked };
      }
      return item;
    });

    if (!recent && data.length) {
      recentwishListItems(updatedCourses);
    } else if (recent && recentwishListItems.length) {
      setTotalCourses(updatedCourses);
    }
  };

  const handleRemoveCourse = (courseId) => {
    const updatedData = data.filter((item) => item.id !== courseId);
    setAvailableCartItems(updatedData);
  };

  // Animation settings
  const slideAnimation = {
    initial: { opacity: 0, x: -50 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 50 },
    transition: { duration: 0.3 },
  };

  return (
    <Paper sx={{ margin: 1, borderRadius: 4 }}>
      <Table>
        <TableBody>
          <AnimatePresence>
            {data?.map((item) => (
              <motion.tr
                key={item.id}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={slideAnimation.transition}
                variants={slideAnimation}
                component="tr" // Ensure proper table row
              >
                <TableCell variant="head" sx={{ padding: 1 }}>
                  <Stack direction="row" spacing={1}>
                    <Box>
                      <Checkbox
                        checked={item?.wishList == 1 ? true : false || false}
                        onChange={(e) =>
                          handleInputChange(e, item.id, "wishList")
                        }
                      />
                    </Box>
                    <Box pt={1}>{item.courseName}</Box>
                  </Stack>
                </TableCell>
              </motion.tr>
            ))}
          </AnimatePresence>
        </TableBody>
      </Table>
      {/* ---------------------------------------------------------------------- */}
      <Table>
        <TableBody>
          <AnimatePresence>
            {recentwishListItems?.map((item) => (
              <motion.tr
                key={item.id}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={slideAnimation.transition}
                variants={slideAnimation}
                component="tr" // Ensure proper table row
              >
                <TableCell variant="head" sx={{ padding: 1 }}>
                  <Stack direction="row" spacing={1}>
                    <Box>
                      <Checkbox
                        checked={item.wishList || false}
                        onChange={(e) =>
                          handleRecentInputChange(e, item.id, "wishList")
                        }
                      />
                    </Box>
                    <Box pt={1}>{item.courseName}</Box>
                  </Stack>
                </TableCell>
              </motion.tr>
            ))}
          </AnimatePresence>
        </TableBody>
      </Table>
    </Paper>
  );
};

export { WishListitem };
