import React, { useEffect, useState } from 'react'
import GlobalWrapper from '../../../../components/Wrapper/GlobalWrapper'
import { Box, Paper } from '@mui/material'
import DatePickerCustom from '../../../../components/DatePicker/DatePickerCustom'
import GlobalMultiSelect from '../../../../components/mui/GlobalMultiSelect'
import dayjs from 'dayjs'
import GlobalDatagrid from '../../../../components/mui/DataGrid/GlobalDatagrid'
import ActionDetails from '../../../../components/mui/GlobalAnchor/ActionDetails'
import GlobalAnchorList from '../../../../components/mui/GlobalAnchor/GlobalAnchorList'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { getAllGrievancesTickets } from '../../../../redux/features/studentPortalSlice'
import { ticketTableHeader } from '../../../../components/datagrid/studentPortal/ticketTableHeader'
import TicketAction from '../../../../components/datagrid/studentPortal/Admin/TicketAction'
import HeadOrAssigneeAction from '../../../../components/datagrid/studentPortal/HeadOrAssigneeAction'

const DATA = [
  {
    sl_no: 1,
    name: 'Subhin',
    department: 'tech',
    designation: 'mern stack developer',
    status: 'Pending',
    reason: 'very bad',
    adminRemarks: 'please fast',
    assigneeRemarks: '',
    deptId: 5,
    userId: 1012,
  },
  {
    sl_no: 2,
    name: 'suhail',
    department: 'tech',
    designation: 'mern stack developer',
    status: 'In Progress',
    reason: 'bad',
    adminRemarks: 'adm',
    assigneeRemarks: '',
  },
  {
    sl_no: 3,
    name: 'athul',
    department: 'tech',
    designation: 'mern stack developer',
    status: 'Approved',
    reason: 'not good',
    adminRemarks: 'main',
    assigneeRemarks: '',
  },
]

let statusJson = [
  { id: 1, value: 'Pending' },
  { id: 2, value: 'Assigned' },
  { id: 3, value: 'In Progress' },
  { id: 4, value: 'Resolved' },
  { id: 5, value: 'Rejected' },
]
const HeadOrAssigneeTickets = () => {
  const dispatch = useDispatch()
  const { paginationData } = useSelector((state) => state.globalDataGrid)
  const { gridSize, pageNum, rowData, openAnchor, search } = paginationData
  const { resData, loading } = useSelector((store) => store.studentPortal)
  const { data, meta } = resData
  const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'))
  const [status, setStatus] = useState([])
  const dispatchParams = {
    selectedDate,
    paginationData,
    status,
  }

  useEffect(() => {
    dispatch(getAllGrievancesTickets(selectedDate))
  }, [gridSize, pageNum, search, selectedDate, status])

  return (
    <GlobalWrapper>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Box sx={{ flex: 1 }}>
          <DatePickerCustom
            value={dayjs(selectedDate)}
            setValue={(date) => setSelectedDate(date.format('YYYY-MM-DD'))}
            viewDetails={['year', 'month', 'day']}
            error={false}
            fullWidth
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <GlobalMultiSelect
            label={'Status Filter'}
            fullWidth
            arrayState={status}
            arraySetState={setStatus}
            data={statusJson}
            keys={{ id: 'id', value: 'value' }}
          />
        </Box>
      </Box>

      <Box sx={{ mt: 1 }}>
        <Paper elevation={1}>
          <GlobalDatagrid
            tableHead={ticketTableHeader}
            rows={DATA || []}
            meta={meta?.totalRecords}
            rowUniqueId="sl_no"
            loading={loading}
            rowClick={true}
            actionComponent={
              <>
                <ActionDetails val={rowData?.name} />
                <GlobalAnchorList
                  label="Action"
                  component={
                    <HeadOrAssigneeAction paginationData={paginationData} row={rowData} dispatchParams={dispatchParams} />
                  }
                />
              </>
            }
          />
        </Paper>
      </Box>
    </GlobalWrapper>
  )
}

export default HeadOrAssigneeTickets
