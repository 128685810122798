import React, { useEffect, useState } from 'react'
import GlobalWrapper from '../../../../components/Wrapper/GlobalWrapper'
import FromAndToDatePicker from '../../../../components/mui/FromAndToDatePicker'
import GlobalMultiSelect from '../../../../components/mui/GlobalMultiSelect'
import { Box, Paper, Stack } from '@mui/material'
import { useSelector } from 'react-redux'
import useResponsive from '../../../../Hooks/useResponsive'
import dayjs from 'dayjs'
import GlobalDatagrid from '../../../../components/mui/DataGrid/GlobalDatagrid'
import { employeeTableHeader } from '../../../../components/datagrid/hrDashboard/employeeTableHeader'
import LeaveTypeComponents from './LeaveTypeComponents'
import { leaveTableHeader } from '../../../../components/datagrid/hrDashboard/leaveTableHeader'

const types = [
    { id: 1, value: 'Leave' },
    { id: 2, value: 'Present'},
  ]

  const statuses=[
    { id: 1, value: 'Pending' },
    { id: 2, value: 'Approved'},
    { id: 3, value: 'Rejected'},
  ]
const Leave = () => {
  const [fromDate, setFromDate] = useState(dayjs().format('YYYY-MM-DD'))
  const [toDate, setToDate] = useState("")
  const { departments } = useSelector((store) => store.postWorking)
  const { paginationData } = useSelector((state) => state.globalDataGrid)
  const { resData, loading, employeeStatuses, employeeTypes } = useSelector((store) => store.hrDashboard)
  const { data, meta } = resData
  const [direction, setDirection] = useState('row')
  const { gridSize, pageNum, rowData, openAnchor, search } = paginationData
  const [departmentNames, setDepartmentNames] = useState([])
  const [status,setStatus]=useState("")
  const [type,setType]=useState("")
  const mdDown = useResponsive('down', 'md')
  const smDown = useResponsive('down', 'sm')
  useEffect(() => {
    if (mdDown) {
      setDirection('column')
    } else {
      setDirection('row')
    }
  }, [mdDown])

  const dispatchParams = {
    fromDate,
    toDate,
    departmentNames,
    paginationData,
  }

  return (
    <GlobalWrapper title={'Leave'}>
      <Box sx={{ mb: 1 }}>
        <Stack gap={1} direction={direction}>
          <Box>
            <FromAndToDatePicker fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} />
          </Box>
          <Box>
            <GlobalMultiSelect
              label={'Type Filter'}
            //   sx={{ maxWidth: '600px' }}
            fullWidth={mdDown}
              arrayState={type}
              arraySetState={setType}
              data={types}
              keys={{ id: 'id', value: 'value' }}
            />
          </Box>
          <Box>
            <GlobalMultiSelect
              label={'Department Filter'}
              fullWidth={mdDown}
              arrayState={departmentNames}
              arraySetState={setDepartmentNames}
              data={departments}
              keys={{ id: 'departmentId', value: 'departmentName' }}
            />
          </Box>
          <Box>
            <GlobalMultiSelect
              label={'Status Filter'}
              fullWidth={mdDown}
              arrayState={status}
              arraySetState={setStatus}
              data={statuses}
              keys={{ id: 'id', value: 'value' }}
            />
          </Box>
        </Stack>
      </Box>

      <Box sx={{my:1}}>
      <LeaveTypeComponents/>
      </Box>

      <Box>
      <Paper>
            <GlobalDatagrid
              tableHead={leaveTableHeader}
              rows={data || []}
              meta={meta?.totalRecords}
              rowUniqueId="sl_no"
              loading={loading}
            />
          </Paper>
      </Box>
    </GlobalWrapper>
  )
}

export default Leave
