import ToolTip from "../../../../ToolTip/ToolTip";
import { memo } from "react";

// Memoized ToolTip for better performance
const MemoizedToolTip = memo(({ expand, content }) => (
  <ToolTip expand={expand} content={content} />
));

// Separate renderCell logic for readability and optimization
const renderCourseName = (params) => (
  <MemoizedToolTip
    expand={params.row.courseName}
    content={params.row.courseName}
  />
);

const renderFirstTermFee = (params) =>
  params.row.firstTermFee == null ? "Not Paid" : "Paid";

const renderJqaAssessmentReport = (params) => (
  <MemoizedToolTip
    expand={params.row.jqaAssessmentReport}
    content={params.row.jqaAssessmentReport}
  />
);

export const ogaAdmissionRegisterTableHeader = [
  { field: "sl_no", headerName: "Sl No", minWidth: 50, flex: 0.5 },
  { field: "admissionNo", headerName: "Admission No", minWidth: 110, flex: 1 },
  { field: "studentName", headerName: "Name", minWidth: 200, flex: 1 },
  { field: "contactNo", headerName: "Mobile", minWidth: 150, flex: 1 },
  { field: "className", headerName: "Class", minWidth: 80, flex: 0.75 },
  { field: "syllabus", headerName: "Syllabus", minWidth: 100, flex: 1 },
  { field: "medium_name", headerName: "Medium", minWidth: 100, flex: 1 },
  {
    field: "courseName",
    headerName: "Course Name",
    minWidth: 220,
    flex: 2,
    renderCell: renderCourseName,
  },
  {
    field: "courseEnrollmentDate",
    headerName: "Course Enrollment Date",
    minWidth: 180,
    flex: 1.5,
  },
  { field: "oga", headerName: "OGA", minWidth: 180, flex: 1.5 },
  { field: "cro", headerName: "CRO", minWidth: 180, flex: 1.5 },
  {
    field: "firstTermFee",
    headerName: "First Term Fee",
    minWidth: 180,
    flex: 1.5,
    renderCell: renderFirstTermFee,
  },
  {
    field: "jqaAssessmentReport",
    headerName: "JQA Report",
    minWidth: 200,
    flex: 2,
    renderCell: renderJqaAssessmentReport,
  },
];
