export const leaveTableHeader=[
    { field: 'sl_no', headerName: 'Serial No', width: 80 },
    { field: 'name', headerName: 'Name', width: 250 },
    { field: 'type', headerName: 'Type', width: 150 },
    { field: 'fromDate', headerName: 'From Date', width: 150 },
    { field: 'toDate', headerName: 'To Date', width: 150 },
    { field: 'noOfDays', headerName: 'No Of Days', width: 150 },
    { field: 'reason', headerName: 'Reason', width: 150 },
    { field: 'appliedOn', headerName: 'Applied On', width: 150 },
    { field: 'status', headerName: 'Status', width: 150 },
    { field: 'reportingHeadRemark', headerName: 'Reporting Head Remark', width: 150 },
    { field: 'lastUpdatedByReportingHead', headerName: 'Last Updated By Reporting Head', width: 150 },
]