import React, { useEffect, useState } from 'react'
import GlobalWrapper from '../../../components/Wrapper/GlobalWrapper'
import DatePickerCustom from '../../../components/DatePicker/DatePickerCustom'
import CustomGridToolbar from '../../../components/datagrid/GridToolBar'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import useResponsive from '../../../Hooks/useResponsive'
import { getEveningFoodList, getFoodList } from '../../../redux/features/foodSlice'
import { Box, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { hrFoodTableHeader } from '../../../components/datagrid/hrDashboard/hrFoodTableHeader'

const FoodDetails = ({ open, evening, lunch }) => {
  // const {loading}=useSelector((store)=>store.hrDashboard)
  const { loading, foodList } = useSelector((store) => store.food)
  const [direction, setDirection] = useState('row')
  const mdDown = useResponsive('down', 'md')
  const [gridSize, setGridSize] = useState(10)
  const dispatch = useDispatch('')
  const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'))
  useEffect(() => {
    if (mdDown) {
      setDirection('column')
    } else {
      setDirection('row')
    }
  }, [mdDown])

  useEffect(() => {
    if (open) {
      lunch ? dispatch(getFoodList({ date: selectedDate })) : dispatch(getEveningFoodList({ date: selectedDate }))
    }
  }, [selectedDate, open])

  return (
    <>
     <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
     <Box sx={{ mb: 1 }}>
        <DatePickerCustom
          value={dayjs(selectedDate)}
          setValue={(date) => setSelectedDate(date.format('YYYY-MM-DD'))}
          viewDetails={['year', 'month', 'day']}
        />
      </Box>
      <Box>
        <Typography sx={{ textJustify: 'center' }} variant="subtitle1">
          {evening?"Evening Food Count" : "Lunch Count"} : {foodList?.length}
        </Typography>
      </Box>
     </Box>
      <Box>
        <DataGrid
          components={{ Toolbar: CustomGridToolbar }}
          rows={foodList}
          columns={hrFoodTableHeader}
          loading={loading}
          getRowId={(row) => row.serialNumber}
          pageSize={gridSize}
          autoHeight
          rowsPerPageOptions={[5, 10, 25, 50]}
          onPageSizeChange={(newGridSize) => setGridSize(newGridSize)}
        />
      </Box>
    </>
  )
}

export default FoodDetails
