import React, { useEffect, useState } from "react";
import GlobalDialog from "../../../../components/mui/Dialogue/GlobalDialog";
import Box from "@mui/material/Box";
import GlobalWrapper from "../../../../components/Wrapper/GlobalWrapper";
// import { Document, Page, pdfjs } from "react-pdf";
import {
  Alert,
  Button,
  Card,
  CardMedia,
  Chip,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { CancelButton } from "../../../../components/mui/Buttons/Buttons";
import { Icon } from "@iconify/react";
import MotionContainer from "../../../../components/FramerMotion/MotionContainer";
import { compressImage } from "../../../../utils/constants/ImageCompress";
import Loader3D from "../../../../components/Loader/Loader3D";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import GlobalSelectField from "../../../../components/mui/GlobalSelectField";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import {
  getCartItems,
  getEmiCartItem,
  makeNextPayment,
} from "../../../../redux/features/cartSlice";
import { useLocation } from "react-router-dom";
import useResponsive from "../../../../Hooks/useResponsive";
import CircularLoader from "../../../../components/Loader/CircularLoader";
import { pdfjs, Document, Page } from "react-pdf";
// import pdfWorker from "pdfjs-dist/build/pdf.worker.min.mjs";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { savePaginationData } from "../../../../redux/features/globalDatagridSlice";

const MakeNextPayment = ({ paidItem, handleClose, emi, updateData }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");
  const dispatch = useDispatch();
  const location = useLocation();
  const [value, setValue] = React.useState(dayjs());
  const [compressesImg, setCompressesImg] = useState(null);

  const [loadingCart, setLoading] = useState(false);
  const { loading } = useSelector((state) => state.cart);
  const { paginationData } = useSelector((state) => state.globalDataGrid);
  const [open, setOpen] = useState(false);
  const [imageURL, setImageURL] = useState(null);
  const [image, setImage] = useState(null);
  const [fileType, setFileType] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [payingValidation, setPayingValidation] = useState(false);
  const Schema = Yup.object().shape({
    paymentMode: Yup.string().required("paymentMethod is required"),
    currencyType: Yup.string().required("currency is required"),
    transactionDate: Yup.string().required("transactionDate is required"),
    payingAmt: Yup.string().required("payingAmount is required"),
  });
  const SchemaPaymentMethod = Yup.object().shape({
    paymentMode: Yup.string().required("paymentMethod is required"),
    payingAmt: Yup.string().required("payingAmount is required"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      cartId: paidItem.cartId || "",
      payingAmt: paidItem?.balanceAmt || "",
      paymentProof: "",
      paymentMode: "",
      currencyType: "",
      transactionDate: "",
      remarks: "",
    },
    validationSchema: () =>
      formik.values?.paymentMode == 0 ? SchemaPaymentMethod : Schema,
    onSubmit: (values) => {
      if (!compressesImg && values?.paymentMode != 0) {
        enqueueSnackbar("Please upload payment screenshot..!", {
          variant: "error",
        });
      } else {
        dispatch(makeNextPayment(values)).then((res) => {
          if (
            res.payload.status === "failed" ||
            res.payload.status === "error"
          ) {
            enqueueSnackbar(res.payload.message, { variant: "error" });
          } else if (res.payload.status === "success") {
            enqueueSnackbar(res.payload.message, {
              variant: "success",
            });
            if (emi) {
              dispatch(getEmiCartItem(updateData));
              dispatch(
                savePaginationData({ ...paginationData, openAnchor: false })
              );
            } else {
              handleClose();
              dispatch(getCartItems(location?.state.leadId));
            }
            setOpen(false);
          }
        });
      }
    },
  });

  useEffect(() => {
    if (paidItem && formik.values.payingAmt) {
      if (parseInt(paidItem?.balanceAmt) >= parseInt(formik.values.payingAmt)) {
        setPayingValidation(false);
      } else {
        setPayingValidation(true);
      }
    } else {
      setPayingValidation(false);
    }
  }, [paidItem, formik.values.payingAmt]);
  useEffect(() => {
    formik.setFieldValue("paymentProof", compressesImg);
  }, [compressesImg]);
  useEffect(() => {
    if (compressesImg) {
      setLoading(false);
    }
  }, [compressesImg]);
  const paymentMethods = [
    { id: "0", name: "Generate Link" },
    { id: "1", name: "Cash In hand" },
    { id: "2", name: "Bank Transfer" },
    { id: "3", name: "Stripe" },
  ];

  const handleImageChange = (e) => {
    setImage(null);
    setCompressesImg(null);
    setLoading(true);
    const file = e.target.files[0];
    if (file) {
      setImage(file);
    }
  };
  useEffect(() => {
    if (image) {
      setFileType(true);
      const fileType = image.type;
      if (fileType === "application/pdf") {
        const pdfUrl = URL.createObjectURL(image);
        setImageURL(pdfUrl);
        setCompressesImg(image);
      } else {
        compressImage(image, 0.5).then((compressedFile) => {
          const compressedUrl = compressedFile;
          const imageURL = URL.createObjectURL(compressedFile);
          setImageURL(imageURL);
          setCompressesImg(compressedUrl);
          setFileType(false);
        });
      }
    }
  }, [image]);

  useEffect(() => {
    if (value) {
      formik.setFieldValue("transactionDate", dayjs(value).format());
    }
  }, [value]);

  useEffect(() => {
    if (!open) {
      setImage(null);
      formik.setFieldValue("paymentMode", "");
      setCompressesImg("");
    }
  }, [open]);

  return (
    <GlobalDialog
      title="Select Payment Method"
      open={open}
      setOpen={setOpen}
      fullWidth
      maxWidth={"sm"}
      closeButton={true}
      popupButton={
        <>
          {paidItem.paymentCompletionStatus == 1 ? null : (
            <Button
              onClick={() => setOpen(true)}
              variant="outlined"
              size="small"
            >
              Next Payment
            </Button>
          )}
        </>
      }
      content={
        <>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              {open ? (
                <GlobalWrapper>
                  <Box>
                    <MotionContainer delay={0.5}>
                      <Box
                        pb={2}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Chip
                          size="small"
                          color="primary"
                          variant="outlined"
                          label={
                            <Box>
                              <Typography variant="h6">
                                Pending Amount:-{paidItem?.balanceAmt}
                              </Typography>
                            </Box>
                          }
                        />
                      </Box>
                    </MotionContainer>
                    <Box pb={2}>
                      <MotionContainer delay={0.5}>
                        <TextField
                          name="payingAmt"
                          label="Paying amount"
                          type="number"
                          value={formik.values.payingAmt}
                          fullWidth
                          onChange={formik.handleChange}
                          error={
                            formik.touched.payingAmt &&
                            Boolean(formik.errors.payingAmt)
                          }
                          helperText={formik.errors.payingAmt}
                        />
                      </MotionContainer>
                    </Box>
                    {payingValidation ? (
                      <Box pb={2}>
                        <MotionContainer delay={0.5}>
                          {" "}
                          <Alert severity="warning">
                            The added amount is greater than the pending
                            amount...!
                          </Alert>
                        </MotionContainer>
                      </Box>
                    ) : null}

                    <MotionContainer delay={0.5}>
                      <Box>
                        <GlobalSelectField
                          options={{
                            name: "paymentMode",
                            formik,
                            sx: { width: "100%" },
                            label: "Payment Methods",
                            data: paymentMethods,
                            keys: {
                              id: "id",
                              value: "name",
                            },
                          }}
                        />
                      </Box>
                    </MotionContainer>
                    {formik.values.paymentMode == 0 ? null : (
                      <>
                        <Stack direction={"column"} spacing={2}>
                          <MotionContainer delay={0.5}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                pt: 3,
                              }}
                            >
                              <Card
                                sx={{
                                  textAlign: "center",
                                  padding: 2,
                                  width: "100%",
                                }}
                              >
                                <Typography variant="h6" gutterBottom>
                                  Upload Proof
                                </Typography>
                                {loadingCart ? (
                                  <Loader3D />
                                ) : (
                                  <>
                                    <input
                                      accept="image/jpeg, image/png, image/webp, application/pdf"
                                      style={{ display: "none" }}
                                      id="upload-image"
                                      type="file"
                                      onChange={handleImageChange}
                                    />
                                    <label htmlFor="upload-image">
                                      <Button
                                        variant="contained"
                                        component="span"
                                      >
                                        Choose File
                                      </Button>
                                    </label>
                                    {compressesImg && !fileType && (
                                      <CardMedia
                                        component="img"
                                        image={imageURL}
                                        alt="Uploaded file preview"
                                        sx={{
                                          marginTop: 2,
                                          width: "100%",
                                          objectFit: "cover",
                                        }}
                                      />
                                    )}
                                    <Box>{imageURL}</Box>
                                    {fileType ? (
                                      <div style={{ textAlign: "center" }}>
                                        <Document
                                          file={image}
                                          onLoadSuccess={onDocumentLoadSuccess}
                                          loading="Loading PDF..."
                                        >
                                          <Page pageNumber={pageNumber} />
                                        </Document>

                                        <p>
                                          Page {pageNumber} of {numPages}
                                        </p>
                                        <div>
                                          <button
                                            onClick={() =>
                                              setPageNumber((prev) =>
                                                Math.max(prev - 1, 1)
                                              )
                                            }
                                            disabled={pageNumber === 1}
                                          >
                                            Previous
                                          </button>
                                          <button
                                            onClick={() =>
                                              setPageNumber((prev) =>
                                                Math.min(prev + 1, numPages)
                                              )
                                            }
                                            disabled={pageNumber === numPages}
                                          >
                                            Next
                                          </button>
                                        </div>
                                      </div>
                                    ) : null}
                                  </>
                                )}
                              </Card>
                            </Box>
                          </MotionContainer>
                          <MotionContainer delay={0.7}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                sx={{ width: "100%" }}
                                label="Transaction Date"
                                value={value}
                                onChange={(newValue) => setValue(newValue)}
                              />
                            </LocalizationProvider>
                          </MotionContainer>
                          <MotionContainer delay={0.7}>
                            <GlobalSelectField
                              options={{
                                name: "currencyType",
                                formik,
                                sx: { width: "100%" },
                                label: "Currency",
                                data: [
                                  { id: "INR", name: "INR" },
                                  { id: "AED", name: "AED" },
                                ],
                                keys: {
                                  id: "id",
                                  value: "name",
                                },
                              }}
                            />
                          </MotionContainer>
                          <MotionContainer delay={1}>
                            <TextField
                              name="remarks"
                              label="Remarks"
                              onChange={formik.handleChange}
                              value={formik.values.remarks}
                              fullWidth
                              multiline
                              rows={3}
                            />
                          </MotionContainer>
                        </Stack>
                      </>
                    )}

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        padding: 2,
                      }}
                    >
                      <Stack direction={"row"} spacing={1}>
                        <CancelButton
                          size="small"
                          action={() => setOpen(false)}
                        >
                          Cancel
                        </CancelButton>
                        {formik.values.paymentMode == 0 ? (
                          <Button
                            type="submit"
                            size={smUp ? "medium" : "small"}
                            sx={{
                              color: "white",
                              background: `linear-gradient(to bottom, #0089d0 0%, #0089d0 10%, #a125c2 100%)`,
                              borderRadius: 3,
                            }}
                            disabled={
                              loading || payingValidation ? true : false
                            }
                          >
                            <Icon
                              icon="mdi:cart-outline"
                              height={22}
                              width={22}
                            />
                            {loading ? (
                              <Box sx={{ display: "flex" }}>
                                Please Wait...!
                                <CircularLoader />
                              </Box>
                            ) : (
                              "Generate Link"
                            )}
                          </Button>
                        ) : (
                          <Button
                            size={smUp ? "medium" : "small"}
                            type="submit"
                            sx={{
                              color: "white",
                              background: `linear-gradient(to bottom, #0089d0 0%, #0089d0 10%, #a125c2 100%)`,
                              borderRadius: 3,
                            }}
                            disabled={
                              loading || payingValidation ? true : false
                            }
                          >
                            <Icon
                              icon="mdi:cart-outline"
                              height={22}
                              width={22}
                            />
                            {loading ? (
                              <Box sx={{ display: "flex" }}>
                                Please Wait...!
                                <CircularLoader />
                              </Box>
                            ) : (
                              "Make Payment"
                            )}
                          </Button>
                        )}
                      </Stack>
                    </Box>
                  </Box>
                </GlobalWrapper>
              ) : null}
            </Form>
          </FormikProvider>
        </>
      }
    />
  );
};

export default MakeNextPayment;
