import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import apiRequest from '../../api/request'

const initialState = {
  loading: false,
  resData: {
    data: [],
    meta: {},
  },
  dashboardCounts: {},
  departments: [],
  employees: [],
}
export const getAllGrievancesDashboardCounts = createAsyncThunk(
  'studentPortalSlice/getAllGrievancesDashboardCounts',
  async () => {
    const res = await apiRequest({
      method: 'get',
      url: `analytics/basic-counts`,
    })
    return res
  },
)

export const getAllGrievancesTickets = createAsyncThunk('studentPortalSlice/getAllGrievancesTickets', async (date) => {
  const res = await apiRequest({
    method: 'get',
    url: `analytics/basic-counts/${date}`,
  })
  return res
})

export const getAllDepartments = createAsyncThunk('studentPortalSlice/getAllDepartments', async () => {
  const res = await apiRequest({
    method: 'get',
    url: `departments/`,
  })
  return res
})

export const getEmployeesUnderTheDepartment = createAsyncThunk(
  'studentPortalSlice/getEmployeesUnderTheDepartment',
  async (id) => {
    const res = await apiRequest({
      method: 'get',
      url: `general/empolyee-under-department/${id}?limit=no`,
    })
    return res
  },
)
const studentPortalSlice = createSlice({
  name: 'studentPortalSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //..................GETING ALL TICKETS ................
    builder.addCase(getAllGrievancesTickets.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      }
    })

    builder.addCase(getAllGrievancesTickets.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        resData: {
          ...state.resData,
          data: action.payload?.data,
          meta: action.payload?.meta,
        },
      }
    })
    //..................GETING ALL DASHBOARD COUNTS ................
    builder.addCase(getAllGrievancesDashboardCounts.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      }
    })

    builder.addCase(getAllGrievancesDashboardCounts.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        dashboardCounts: action.payload?.data,
      }
    })
    //...............................GET ALL DEPARTMENTS......................//
    builder.addCase(getAllDepartments.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      }
    })
    builder.addCase(getAllDepartments.fulfilled, (state, action) => {
      return {
        ...state,
        departments: action.payload?.data?.departments,
        loading: false,
      }
    })
    //...............................GET ALL EMPLOYEES UNDER DEPARTMENT......................//
    builder.addCase(getEmployeesUnderTheDepartment.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      }
    })
    builder.addCase(getEmployeesUnderTheDepartment.fulfilled, (state, action) => {
      return {
        ...state,
        employees: action.payload?.data,
        loading: false,
      }
    })
  },
})

export default studentPortalSlice.reducer
