import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { motion } from "framer-motion";

export default function CircularLoader() {
  return (
    <Box pt={0.5}>
      <motion.div
        initial={{ scale: 0.8 }}
        animate={{ scale: 1 }}
        transition={{
          duration: 0.6,
          repeat: Infinity,
          repeatType: "reverse",
          ease: "easeInOut",
        }}
      >
        <CircularProgress sx={{ color: "white" }} size="20px" />
      </motion.div>
    </Box>
  );
}
